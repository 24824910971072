import { addSnackbarMessage } from "../../Common/_plugin/error-response";
import api from "../_api";

const fetchSystemConfigs = (context) => {
  api
    .fetchSystemConfigs()
    .then((response) => {
      context.commit("SET_SYSTEM_CONFIGS", response.data);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};
const fetchSystemConfigsObject = async (context) => {
  try {
    const response = await api.fetchSystemConfigsObject();
    context.commit("SET_SYSTEM_CONFIGS_OBJECT", response.data);
    return;
  } catch (error) {
    console.log(error);
    addSnackbarMessage(error, "Problem retrieving data!", true);
  }
};
const fetchPublicSystemConfigsObject = (context) => {
  api
    .fetchPublicSystemConfigsObject()
    .then((response) => {
      context.commit("SET_PUBLIC_SYSTEM_CONFIGS_OBJECT", response.data);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

const createSystemConfig = (context, payload) => {
  api
    .addSystemConfig(payload.data)
    .then(() => {
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "System config created successfully!",
        },
        { root: true }
      );
      payload.callback && payload.callback();
      context.dispatch("fetchSystemConfigs");
      context.dispatch("fetchSystemConfigsObject");
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

const updateSystemConfig = (context, payload) => {
  api
    .updateSystemConfig(payload.data)
    .then(() => {
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "System config updated successfully!",
        },
        { root: true }
      );
      payload.callback && payload.callback();
      context.dispatch("fetchSystemConfigs");
      context.dispatch("fetchSystemConfigsObject");
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};
const deleteSystemConfig = (context, payload) => {
  api
    .deleteSystemConfig(payload.data)
    .then(() => {
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "System config updated successfully!",
        },
        { root: true }
      );
      payload.callback && payload.callback();
      context.dispatch("fetchSystemConfigs");
      context.dispatch("fetchSystemConfigsObject");
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

/**
 * @memberOf module:SystemConfig
 * @namespace store.actions
 * @description actions for system config module
 */
export default {
  fetchSystemConfigs,
  fetchSystemConfigsObject,
  fetchPublicSystemConfigsObject,
  createSystemConfig,
  updateSystemConfig,
  deleteSystemConfig,
};
