<template>
  <div class="container p-0 m-0">
    <div class="menubar nopadding" id="wrapper">
      <div class="profile-wrapper">
        <div class="profile-overlay">
          <div>
            <img class="rounded-circle profile" :src="avatar" alt="" />
            <h4 class="username">{{ loggedInUser && loggedInUser.name }}</h4>
          </div>
        </div>
      </div>
      <ul class="sidemenu">
        <li
          :class="{
            'submenu-active': $route.name === 'RecruiterApplicantData',
          }"
          class="submenu"
        >
          <router-link
            :to="{ name: 'RecruiterApplicantData' }"
            aria-expanded="true"
          >
            <i class="fa fa-user" aria-hidden="true"></i>
            Applications
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import userProfile from "../../../assets/images/avatar.png";

export default {
  data: () => {
    return {
      avatar: userProfile,
    };
  },
  computed: {
    ...mapGetters({
      loggedInUser: "$_recruiter/getLoggedInUser",
    }),
  },
};
</script>
<style scoped>
#wrapper {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  transition: all 0.4s ease 0s;
}
#wrapper::-webkit-scrollbar {
  width: 5px;
}
#wrapper::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}
#wrapper::-webkit-scrollbar-thumb {
  background: #a7a7a7;
  border-radius: 10px;
}
#wrapper.active {
  left: 0px;
}
.menubar {
  top: 65px;
  bottom: 0px;
  width: 250px;
  position: fixed;
  left: -250px;
  z-index: 999;
  background: #832e5b;
  /* Old browsers */
  background: -moz-linear-gradient(top, #832e5b 1%, #b65386 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #832e5b 1%, #b65386 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #832e5b 1%, #b65386 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.menubar li {
  list-style-type: none;
  border-bottom: 1px solid #b2628b;
  transition: 0.5s;
}
.profile {
  width: 100px;
  height: 100px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin-top: 30px;
  border: 4px solid #e7e7e7;
}
.profile-wrapper {
  background-image: url(../../../assets/images/user-bg.jpg);
  background-size: cover;
  height: 190px;
  background-repeat: no-repeat;
  position: relative;
}
.profile-overlay {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.username {
  color: white;
  margin: 10px 13px;
  text-align: center;
  padding: 4px;
}

li {
  display: block;
  padding: 0px 0px 3px 10px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  border-bottom: 1px solid transparent !important;
}
li > a:focus {
  background-color: #803059;
  color: white;
}
li > a {
  color: white;
}
.fa {
  margin-right: 5px;
}
.submenu a {
  line-height: 2 !important;
  font-size: 19px;
}
.sidemenu:hover {
  background-color: #803059;
  color: white;
  cursor: pointer;
}

.sidemenu {
  background-color: #903564;
  width: 250px;
  position: relative;
  border: none;
  box-shadow: none;
  border-radius: 0px;
  margin: 0;
  padding: 0;
}
.router-link-active.router-link-exact-active {
  background: #fff !important;
  color: #903564 !important;
}

.sidemenu .submenu.submenu-active {
  background: #fff;
  color: #903564;
}
</style>
