<template>
  <header>
    <div class="container-fluid py-0">
      <div
        class="position-relative d-flex align-items-center justify-content-between"
        v-if="loggedInUser"
      >
        <div class="d-flex align-items-center">
          <div class="sidebar" v-if="$route.meta.user === 'employee'">
            <button id="menu-toggle" class="btn btn-basic mr-2">
              <img
                alt="argusoft-menu"
                src="../../../assets/images/argus-menu.png"
              />
            </button>
          </div>
          <div class="logo logo-with-menu">
            <router-link :to="{ name: 'homepage' }"
              ><img src="../../../assets/images/newLogo.png" alt=""
            /></router-link>
          </div>
        </div>
        <div class="d-flex align-items-center" id="header_btn">
          <div class="position-relative">
            <button
              @click="
                notificationModelVisibility = !notificationModelVisibility
              "
              class="notification_icon btn btn-basic"
            >
              <img src="../../../assets/images/bell.png" alt="notification" />
              <span class="red-circle">{{
                userNotification && userNotification.length > 0
                  ? userNotification.length
                  : 0
              }}</span>
            </button>
            <!-- Notification List -->
            <div
              v-show="notificationModelVisibility"
              v-if="userNotification && userNotification.length !== 0"
              class="notification-list-base"
            >
              <base-list
                class="media"
                v-for="item in userNotification"
                :key="item.id"
                :slug="item.commentsDetails.applicant.slug"
                @click.native="
                  navigateToComment(
                    item.commentsDetails.applicant.slug,
                    item.commentId
                  )
                "
              >
                <template v-slot:img-element>
                  <div
                    class="pull-left"
                    v-if="item.commentsDetails.profile_pic"
                  >
                    <img
                      class="img-responsive"
                      :src="
                        !!item.commentsDetails.profile_pic
                          ? item.commentsDetails.profile_pic
                          : avatar
                      "
                      alt="image"
                    />
                  </div>
                </template>
                <template v-slot:text-element>
                  <p>
                    <strong>{{ item.commentsDetails.userName }}</strong>
                    has mentioned you in a comment.
                  </p>
                  <span class="sub-text">
                    {{ item.updatedAt | dateTimeFormat }}
                  </span>
                </template>
              </base-list>
            </div>
          </div>
          <img class="profile_icon" :src="profileImage" alt="" />
          <button
            class="btn btn-basic sign-out-btn p-1"
            @click="logout()"
            title="Log out"
          >
            <i class="fa fa-2x fa-sign-out" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import baseList from "../_components/base-list.vue";
import userProfile from "../../../assets/images/avatar.png";
/**
 * @memberof module:Common
 * @namespace header-section
 */
export default {
  components: { baseList },
  props: {
    recruiter: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      notificationModelVisibility: false,
      avatar: userProfile,
    };
  },
  computed: {
    ...mapGetters({
      userNotification: "$_applicants_data/fetchUserNotifications",
      loggedInUser: "user/loggedInUser",
      profilePic: "user/fetchProfilePic",
    }),
    profileImage() {
      try {
        if (this.profilePic) {
          return `data:image/jpeg;base64,${this.profilePic}`;
        } else {
          return this.avatar;
        }
      } catch (error) {
        console.error("Error processing profile image in sidebar:", error);
        return this.avatar; // Default avatar in case of error
      }
    },
  },
  methods: {
    logout() {
      axios
        .get("/logout")
        .then(() => {
          this.$cookie.delete("user");
          this.$cookie.delete("connect.sid");
          localStorage.clear();
          this.$store.dispatch("user/logoutUser");
          this.$router.push({ name: "home" });
        })
        .catch(() => {
          this.$cookie.delete("user");
          this.$cookie.delete("connect.sid");
          localStorage.clear();
          this.$store.dispatch("user/logoutUser");
          this.$router.push({ name: "home" });
        });
    },
    navigateToComment(id, commentId) {
      this.notificationModelVisibility = false;
      if (this.$route.fullPath === `/dashboard/applicants/${id}`) {
        document
          .getElementById(`id${commentId}`)
          .scrollIntoView({ block: "center", behavior: "smooth" });
        // make comment seen
        this.$store.dispatch(
          "$_applicants_data/setUserNotificationSeen",
          commentId
        );
      } else {
        this.$router.push({
          name: "applicantprofile",
          params: { slug: id, commentId: commentId },
        });
      }
    },
    // Periodically call user notificaion
    fetchUserNotificationPeriodically(userId) {
      this.timer = setInterval(() => {
        this.$store.dispatch(
          "$_applicants_data/fetchUserNotifications",
          userId
        );
      }, 5 * 60 * 1000);
    },
  },
  created() {
    if (this.loggedInUser) {
      this.$store.dispatch(
        "$_applicants_data/fetchUserNotifications",
        Number(this.loggedInUser.user_id)
      );
    }
  },
  mounted() {
    $("#menu-toggle").click((e) => {
      e.preventDefault();
      $("#wrapper").toggleClass("active");
      $("#maincontent").toggleClass("sidebar-margin");
    });
    $(".submenu").click((e) => {
      e.stopPropagation();
    });
    if (this.loggedInUser) {
      this.fetchUserNotificationPeriodically(Number(this.loggedInUser.user_id));
    }
  },
};
</script>

<style scoped>
.img-responsive {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-size: cover;
  margin-top: 2px;
}

.media img {
  max-width: 100px;
}

.sub-text {
  position: absolute;
  margin-top: -15px;
  text-align: right;
  color: "#666666";
  font-size: 14px;
}

.pull-left {
  margin-right: 20px;
}

.sign-out-btn {
  color: #8f1452;
  cursor: pointer;
  margin-left: 20px;
  vertical-align: middle;
}

.sign-out-btn:hover {
  color: #6d083b;
}

.btn__login {
  background: #8f1452;
  color: #fff !important;
  padding: 8px 25px;
  border: 1px solid #903564;
  font-weight: 500;
  font-size: 15px;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 10px;
}

.btn__login i {
  padding-right: 5px;
}

.btn__login:hover {
  background: #6d083b;
}

/*header-css*/
header {
  position: fixed;
  top: 0px;
  background: #fff;
  left: 0px;
  right: 0px;
  width: 100%;
  z-index: 500;
  height: 60px;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 6px -2px rgba(0, 0, 0, 0.2);
}

header .logo {
  width: 170px;
  overflow: hidden;
}

header .logo img {
  width: 90%;
}

header .logo-with-menu {
  width: 120px;
}

@media screen and (max-width: 400px) {
  .logo-with-menu {
    display: none;
  }
}

header .header_btn {
  position: absolute;
  right: 0px;
  /* top: 18px; */
}

header .profile_icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-size: cover;
}

header .notification_icon {
  margin-right: 20px;
  padding: 0px;
  position: relative;
  height: 40px;
  width: 40px;
  /* border-radius: 50%; */
  background-size: cover;
  cursor: pointer;
}

header .notification_icon img {
  height: 25px;
  width: 25px;
}

header .notification_icon .red-circle {
  font-weight: bolder;
  height: 18px;
  width: 18px;
  position: absolute;
  background-color: #9b3f6f;
  font-size: 10px;
  border-radius: 50%;
  color: #fff;
  top: -7px;
  left: 18px;
  text-align: center;
  line-height: 17px;
  cursor: pointer;
}

.notification-list-base {
  top: 3rem;
  overflow-y: auto;
  min-width: 470px;
  max-height: 450px;
  position: absolute;
  right: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 1px 1px 10px #00000029, -1px -1px 10px #00000029;
}

@media screen and (max-width: 800px) {
  .notification-list-base {
    right: -100px;
    min-width: 375px;
  }
}

@media screen and (max-width: 375px) {
  .notification-list-base {
    min-width: 270px;
  }
}

header .logout {
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  header .header_btn {
    top: 4px;
  }

  header .header_btn .btn__login {
    width: 180px;
    padding: 4px 15px;
    margin-bottom: 4px;
  }
}

.dropdown-menu {
  top: 50px;
}

.dropdown-menu li {
  border-bottom: 1px solid transparent !important;
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 3px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.dropdown-item:hover {
  background-color: #803059;
  color: white;
}

.dropdown-menu > li > a:hover {
  color: white;
}

/*gpw-logo-css*/
.gpw-logo {
  position: fixed;
  z-index: 9999;
  right: 10px;
  top: 60px;
  width: 86px;
}

@media (max-width: 599px) {
  .gpw-logo {
    top: 60px;
  }
  /* header .sidebar {
    display: none;
  } */
}

.gpw-logo img {
  width: 86px;
}

@media (max-width: 700px) {
  .custom-btn {
    margin-bottom: 2px;
  }
}

/*gpw-logo-css*/
</style>
