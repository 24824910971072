import {
  addSnackbarMessage,
  addSuccessSnackbarMessage,
} from "../../Common/_plugin/error-response";
import VueCookie from "vue-cookie";

import api from "../_api";
import router from "../../../router";
import config from "../../../config";
import store from "../../../store/index";

/**
 * @memberOf module:ApplicantMaster.store.actions
 * @param {Object} context store context
 * @description register applicant
 */
const applicantRegister = (context, data) => {
  api
    .applicantRegister(data)
    .then(() => {
      addSuccessSnackbarMessage(
        "A verification link has been sent to your email address. Please verify your email to login."
      );
      router.push("/login?as=applicant");
    })
    .catch((error) => {
      console.log("applicantRegister error", error);
      addSnackbarMessage(error, "Problem in registering the applicant");
    });
};

/**
 * @memberOf module:ApplicantMaster.store.actions
 * @param {Object} context store context
 * @description forgot password request
 */
const applicantForgotPasswordRequest = (context, data) => {
  return api
    .applicantForgotPasswordRequest(data)
    .then((response) => {
      addSuccessSnackbarMessage(response.data.message);
      return response;
    })
    .catch((error) => {
      console.log("applicantForgotPasswordRequest error", error);
      addSnackbarMessage(error, "Problem in sending forgot password request");
    });
};

/**
 * @memberOf module:ApplicantMaster.store.actions
 * @param {Object} context store context
 * @description reset password
 */
const applicantForgotPassword = (context, data) => {
  return api
    .applicantForgotPassword(data)
    .then((response) => {
      addSuccessSnackbarMessage(response.data.message);
      return response;
    })
    .catch((error) => {
      console.log("applicantForgotPassword error", error);
      addSnackbarMessage(error, "Problem in resetting password");
    });
};

/**
 * @memberOf module:ApplicantMaster.store.actions
 * @param {Object} context store context
 * @description register applicant
 */
const applicantLogin = (context, data) => {
  api
    .applicantLogin(data)
    .then((response) => {
      if (response.data.token) {
        context.commit("SET_LOGGED_IN_APPLICANT", response.data.user);
        VueCookie.set("APPLICANT_ACCESS_TOKEN", response.data.token);
        localStorage.setItem("APPLICANT_ACCESS_TOKEN", response.data.token);
        addSuccessSnackbarMessage("Logged in successfully");
        router.push("/applicant/dashboard");
      } else {
        addSnackbarMessage(response.data, response.data.message, true);
      }
    })
    .catch((error) => {
      console.log("applicantLogin error", error);

      addSnackbarMessage(error, "Problem in login !!");
    });
};

/**
 * @memberOf module:ApplicantMaster.store.actions
 * @param {Object} context store context
 * @description set loggedIn Applicant applicant
 */
const setLoggedInApplicant = (context, loggedInApplicant) => {
  context.commit("SET_LOGGED_IN_APPLICANT", loggedInApplicant);
};

/**
 * @memberOf module:ApplicantMaster.store.actions
 * @param {Object} context store context
 * @description logout applicant
 */
const logoutApplicant = (context) => {
  context.commit("LOGOUT_APPLICANT");
};

/**
 * @memberOf module:ApplicantMaster.store.actions
 * @param {Object} context store context
 * @description get all applications of an applicant
 */
const fetchApplicantApplications = (context) => {
  api
    .getApplicantApplications()
    .then((response) => {
      if (response.data) {
        context.commit("SET_APPLICANT_APPLICATIONS", response.data);

        let canApplyJobs = {};

        response.data.forEach((application) => {
          const createdAtDate = new Date(application.createdAt);
          const threeMonthsAgo = new Date();
          threeMonthsAgo.setMonth(
            threeMonthsAgo.getMonth() - config.COOL_OFF_PERIOD
          );

          const value = createdAtDate > threeMonthsAgo;
          const key = application.belongsToCampusRecruitement
            ? "belongsToCampusRecruitement"
            : application.jobId || "fresher";

          canApplyJobs[key] = value;
        });

        context.commit("SET_CAN_APPLY_JOBS", canApplyJobs);
      } else {
        addSnackbarMessage(response.data, response.data.message, true);
      }
    })
    .catch((error) => {
      console.log("getApplicantApplications error", error);
      addSnackbarMessage(error, "Problem in fetching applications !!");
    });
};

/**
 * @memberOf module:ApplicantMaster.store.actions
 * @param {Object} context store context
 * @description get applicant profile
 */
const fetchApplicantProfile = (context) => {
  api
    .getApplicantProfile()
    .then((response) => {
      if (response.data) {
        context.commit("SET_LOGGED_IN_APPLICANT", response.data);
      } else {
        addSnackbarMessage(response.data, response.data.message, true);
      }
    })
    .catch((error) => {
      console.log("fetchApplicantProfile error", error);
      addSnackbarMessage(error, "Problem in fetching profile !!");
    });
};

/**
 * @memberOf module:ApplicantMaster.store.actions
 * @param {Object} context store context
 * @description update applicant profile
 */
const updateApplicantProfile = (context, data) => {
  api
    .updateApplicantProfile(data)
    .then((response) => {
      if (response.data) {
        context.commit("SET_LOGGED_IN_APPLICANT", response.data);
        addSuccessSnackbarMessage("Data updated successfully");
      } else {
        addSnackbarMessage(response.data, response.data.message, true);
      }
    })
    .catch((error) => {
      console.log("fetchApplicantProfile error", error);
      addSnackbarMessage(error, "Problem in updating profile");
    });
};

/**
 * @memberOf module:ApplicantMaster.store.actions
 * @param {Object} context store context
 * @description get all link requests of an applicant
 */
const fetchApplicantLinkRequests = (context) => {
  api
    .getAllApplicantLinkRequests()
    .then((response) => {
      if (response.data) {
        context.commit("SET_APPLICANT_LINK_REQUESTS", response.data);
      } else {
        addSnackbarMessage(response.data, response.data.message, true);
      }
    })
    .catch((error) => {
      console.log("fetchApplicantLinkRequests error", error);
      addSnackbarMessage(error, "Problem in fetching all link requests !!");
    });
};

/**
 * @memberOf module:ApplicantMaster.store.actions
 * @param {Object} context store context
 * @description get application by slug
 */
const fetchApplicationBySlug = (context, slug) => {
  api
    .getApplicationBySlug(slug)
    .then((response) => {
      if (response.data) {
        context.commit("SET_APPLICANT_APPLICATION", response.data);
      } else {
        router.replace("/applicant/dashboard");
        addSnackbarMessage(response.data, response.data.message, true);
      }
    })
    .catch((error) => {
      console.log("fetchApplicationBySlug error", error);
      addSnackbarMessage(error, "Problem in fetching application !!");
      router.replace("/applicant/dashboard");
    });
};

/**
 * @memberOf module:ApplicantMaster.store.actions
 * @param {Object} context store context
 * @description post link request
 */
const postLinkRequest = (context, data) => {
  api
    .postLinkRequest(data)
    .then(() => {
      addSuccessSnackbarMessage(
        "Your application link request has been sent to the admin. Please wait for the admin to accept your request."
      );
      router.replace("/applicant/dashboard");
    })
    .catch((error) => {
      console.log("postLinkRequest error", error);
      addSnackbarMessage(error, "Problem in posting a link request");
    });
};

const uploadApplicantResume = (context, entity) => {
  store.dispatch("setLoading", {
    isLoading: true,
    loadingMessage: "Please wait while resume is uploaded",
  });
  api
    .uploadApplicantResume(entity)
    .then((res) => {
      store.dispatch("setLoading", {
        isLoading: false,
        loadingMessage: " ",
      });
      context.commit("SET_LOGGED_IN_APPLICANT", res.data);
      getApplicantResume(context);
      addSuccessSnackbarMessage("Resume added successfully");

      return res;
    })
    .catch((err) => {
      store.dispatch("setLoading", {
        isLoading: false,
        loadingMessage: " ",
      });
      addSnackbarMessage(err, "Problem Uploading resume", false);
    });
};

const uploadApplicantProfilePic = (context, entity) => {
  store.dispatch("setLoading", {
    isLoading: true,
    loadingMessage: "Uploading Profile Picture",
  });
  api
    .uploadApplicantProfilePic(entity)
    .then((res) => {
      store.dispatch("setLoading", {
        isLoading: false,
        loadingMessage: " ",
      });
      context.commit("SET_LOGGED_IN_APPLICANT", res.data);
      addSuccessSnackbarMessage("Profile picture updated successfully");
      getApplicantProfilePic(context);
      return res;
    })
    .catch((err) => {
      store.dispatch("setLoading", {
        isLoading: false,
        loadingMessage: " ",
      });
      addSnackbarMessage(err, "Problem Uploading profile pic", true);
    });
};

const deleteApplicantResume = (context) => {
  store.dispatch("setLoading", {
    isLoading: true,
    loadingMessage: "Deleting Resume",
  });
  api
    .deleteApplicantResume()
    .then((res) => {
      store.dispatch("setLoading", {
        isLoading: false,
        loadingMessage: " ",
      });
      context.commit("SET_LOGGED_IN_APPLICANT", res.data);
      context.commit("SET_APPLICANT_PDF_URL", null);
      addSuccessSnackbarMessage("Resume deleted successfully");
      return res;
    })
    .catch((err) => {
      store.dispatch("setLoading", {
        isLoading: false,
        loadingMessage: " ",
      });
      addSnackbarMessage(err, "Problem deleting resume", false);
    });
};

const deleteApplicantProfilePic = (context) => {
  store.dispatch("setLoading", {
    isLoading: true,
    loadingMessage: "Deleting Profile picture",
  });
  api
    .deleteApplicantProfilePic()
    .then((res) => {
      context.commit("SET_LOGGED_IN_APPLICANT", res.data);
      context.commit("SET_APPLICANT_PROFILE_PIC", null);
      store.dispatch("setLoading", {
        isLoading: false,
        loadingMessage: " ",
      });
      addSuccessSnackbarMessage("Profile picture deleted successfully");
      return res;
    })
    .catch((err) => {
      store.dispatch("setLoading", {
        isLoading: false,
        loadingMessage: " ",
      });
      addSnackbarMessage(err, "Problem Deleting profile pic", false);
    });
};

const getApplicantResume = (context) => {
  api
    .getApplicantResume()
    .then((res) => {
      const blob = new Blob([res.data], { type: "application/pdf" });
      const pdfUrl = window.URL.createObjectURL(blob);
      context.commit("SET_APPLICANT_PDF_URL", pdfUrl);
    })
    .catch((err) => {
      addSnackbarMessage(err, "Problem loading resume", false);
    });
};

/**
 * @memberOf module:ApplicantMaster.store.actions
 * @param {Object} context store context
 * @param {Object} payload Job Application data to be sent
 * @description sends data of Job Application to server
 */
const postUserdata = (context, payload) => {
  store.dispatch("setLoading", {
    isLoading: true,
    loadingMessage: "please wait form is being submitted",
  });
  api
    .postUserdata(payload)
    .then((response) => {
      store.dispatch("setLoading", {
        isLoading: false,
        loadingMessage: " ",
      });
      context.commit("APPLICANT_DATA_SUBMITTED", {
        status: "SUCCESS",
        uid: response.data.slug,
      });
    })
    .catch((error) => {
      store.dispatch("setLoading", {
        isLoading: false,
        loadingMessage: " ",
      });
      context.commit("APPLICANT_DATA_SUBMITTED", {
        status: "FAILURE",
        error_data: error,
        uid: null,
      });
    });
};
const postUserProject = (context, data) => {
  api
    .postUserProject(data)
    .then((response) => {
      if (response.data) {
        context.commit("SET_PROJECT_IN_APPLICANT", response.data);
        addSuccessSnackbarMessage("Project added successfully");
      } else {
        addSnackbarMessage(response.data, response.data.message, true);
      }
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem in adding project");
    });
};
const updateUserProject = (context, data) => {
  api
    .updateUserProject(data)
    .then((response) => {
      if (response.data) {
        context.commit("UPDATE_PROJECT_IN_APPLICANT", response.data);
        addSuccessSnackbarMessage("Project updated successfully");
      } else {
        addSnackbarMessage(response.data, response.data.message, true);
      }
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem in updating project");
    });
};
const deleteUserProject = (context, id) => {
  api
    .deleteUserProject(id)
    .then((response) => {
      if (response.data) {
        context.commit("DELETE_PROJECT_IN_APPLICANT", id);
        addSuccessSnackbarMessage("Project deleted successfully");
      } else {
        addSnackbarMessage(response.data, response.data.message, true);
      }
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem in deleting project");
    });
};
const addUserJob = (context, data) => {
  api
    .addUserJob(data)
    .then((response) => {
      if (response.data) {
        context.commit("SET_JOB_IN_APPLICANT", response.data);
        addSuccessSnackbarMessage("Job added successfully");
      } else {
        addSnackbarMessage(response.data, response.data.message, true);
      }
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem in adding job");
    });
};
const updateUserJob = (context, data) => {
  api
    .updateUserJob(data)
    .then((response) => {
      if (response.data) {
        context.commit("UPDATE_JOB_IN_APPLICANT", response.data);
        addSuccessSnackbarMessage("Job updated successfully");
      } else {
        addSnackbarMessage(response.data, response.data.message, true);
      }
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem in updating job");
    });
};
const deleteUserJob = (context, id) => {
  api
    .deleteUserJob(id)
    .then((response) => {
      if (response.data) {
        context.commit("DELETE_JOB_IN_APPLICANT", id);
        addSuccessSnackbarMessage("job deleted successfully");
      } else {
        addSnackbarMessage(response.data, response.data.message, true);
      }
    })
    .catch((error) => {
      console.log("fetchApplicantProfile error", error);
      addSnackbarMessage(error, "Problem in deleting job");
    });
};
const addUserCourse = (context, data) => {
  api
    .addUserCourse(data)
    .then((response) => {
      if (response.data) {
        context.commit("SET_COURSE_IN_APPLICANT", response.data);
        addSuccessSnackbarMessage("course added successfully");
      } else {
        addSnackbarMessage(response.data, response.data.message, true);
      }
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem in adding course");
    });
};
const updateUserCourse = (context, data) => {
  api
    .updateUserCourse(data)
    .then((response) => {
      if (response.data) {
        context.commit("UPDATE_COURSE_IN_APPLICANT", response.data);
        addSuccessSnackbarMessage("course updated successfully");
      } else {
        addSnackbarMessage(response.data, response.data.message, true);
      }
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem in updating course");
    });
};
const deleteUserCourse = (context, id) => {
  api
    .deleteUserCourse(id)
    .then((response) => {
      if (response.data) {
        context.commit("DELETE_COURSE_IN_APPLICANT", id);
        addSuccessSnackbarMessage("course deleted successfully");
      } else {
        addSnackbarMessage(response.data, response.data.message, true);
      }
    })
    .catch((error) => {
      console.log("fetchApplicantProfile error", error);
      addSnackbarMessage(error, "Problem in deleting course");
    });
};

// uploading applicant's 10th document
const uploadApplicantSscMarksheet = (context, marksheet) => {
  store.dispatch("setLoading", {
    isLoading: true,
    loadingMessage: "Uploading Marksheet",
  });
  api
    .uploadApplicantSscMarksheet(marksheet)
    .then((res) => {
      store.dispatch("setLoading", {
        isLoading: false,
        loadingMessage: " ",
      });
      context.commit("SET_LOGGED_IN_APPLICANT", res.data);
      getApplicantSscMarksheet(context);
      addSuccessSnackbarMessage(" 10th Marksheet added successfully");
    })
    .catch((err) => {
      store.dispatch("setLoading", {
        isLoading: false,
        loadingMessage: " ",
      });
      addSnackbarMessage(err, "Problem uploading marksheet", true);
    });
};

// fetching applicant's profile pic
const getApplicantProfilePic = (context) => {
  store.dispatch("setLoading", {
    isLoading: true,
    loadingMessage: "Fetching Profile ",
  });
  api
    .getApplicantProfilePic()
    .then((res) => {
      store.dispatch("setLoading", {
        isLoading: false,
        loadingMessage: " ",
      });
      const data = res.data?.base64;

      context.commit("SET_APPLICANT_PROFILE_PIC", data);
    })
    .catch((error) => {
      store.dispatch("setLoading", {
        isLoading: false,
        loadingMessage: " ",
      });
      addSnackbarMessage(error, "Problem fetching profile pic", false);
    });
};

// fetching applicant's 10th document
const getApplicantSscMarksheet = (context) => {
  api
    .getApplicantSscMarksheet()
    .then((res) => {
      const blob = new Blob([res.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      context.commit("SET_APPLICANT_10TH_MARKSHEET_LINK", url);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem loading 10th marksheet", true);
    });
};

// delete applicant's 10th document
const deleteApplicantSscMarksheet = (context) => {
  store.dispatch("setLoading", {
    isLoading: true,
    loadingMessage: "Deleting Marksheet",
  });
  api
    .deleteApplicantSscMarksheet()
    .then((res) => {
      store.dispatch("setLoading", {
        isLoading: false,
        loadingMessage: " ",
      });
      context.commit("SET_LOGGED_IN_APPLICANT", res.data);
      context.commit("SET_APPLICANT_10TH_MARKSHEET_LINK", null);
      addSuccessSnackbarMessage("10th Marksheet deleted successfully");
    })
    .catch((error) => {
      store.dispatch("setLoading", {
        isLoading: false,
        loadingMessage: " ",
      });
      addSnackbarMessage(error, "Could not delete 10th marksheet", true);
    });
};

// uploading applicant's 12th document
const uploadApplicantHsMarksheet = (context, marksheet) => {
  store.dispatch("setLoading", {
    isLoading: true,
    loadingMessage: "Uploading Marksheet",
  });
  api
    .uploadApplicantHsMarksheet(marksheet)
    .then((res) => {
      store.dispatch("setLoading", {
        isLoading: false,
        loadingMessage: " ",
      });
      context.commit("SET_LOGGED_IN_APPLICANT", res.data);
      getApplicantHsMarksheet(context);
      addSuccessSnackbarMessage(" 12th Marksheet added successfully");
    })
    .catch((error) => {
      store.dispatch("setLoading", {
        isLoading: false,
        loadingMessage: " ",
      });
      addSnackbarMessage(error, "Problem uploading marksheet!", true);
    });
};

// fetching applicant's 12th document
const getApplicantHsMarksheet = (context) => {
  api
    .getApplicantHsMarksheet()
    .then((res) => {
      const blob = new Blob([res.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      context.commit("SET_APPLICANT_12TH_MARKSHEET_LINK", url);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem loading 12th marksheet", true);
    });
};

// delete applicant's 12th document
const deleteApplicantHsMarksheet = (context) => {
  store.dispatch("setLoading", {
    isLoading: true,
    loadingMessage: "Deleting Markseet",
  });
  api
    .deleteApplicantHsMarksheet()
    .then((res) => {
      store.dispatch("setLoading", {
        isLoading: false,
        loadingMessage: " ",
      });
      context.commit("SET_LOGGED_IN_APPLICANT", res.data);
      context.commit("SET_APPLICANT_12TH_MARKSHEET_LINK", null);
      addSuccessSnackbarMessage(" 12th Marksheet deleted successfully");
    })
    .catch((error) => {
      store.dispatch("setLoading", {
        isLoading: false,
        loadingMessage: " ",
      });
      addSnackbarMessage(error, "Could not delete 12th marksheet", true);
    });
};

/**
 * @memberOf module:ApplicantMaster
 * @namespace store.actions
 * @description actions for ApplicantMaster module
 */
export default {
  applicantRegister,
  applicantLogin,
  setLoggedInApplicant,
  fetchApplicantApplications,
  fetchApplicantProfile,
  updateApplicantProfile,
  fetchApplicantLinkRequests,
  postLinkRequest,
  uploadApplicantResume,
  getApplicantResume,
  uploadApplicantProfilePic,
  deleteApplicantProfilePic,
  deleteApplicantResume,
  postUserdata,
  applicantForgotPasswordRequest,
  applicantForgotPassword,
  postUserProject,
  updateUserProject,
  deleteUserProject,
  addUserJob,
  updateUserJob,
  deleteUserJob,
  addUserCourse,
  updateUserCourse,
  deleteUserCourse,
  uploadApplicantSscMarksheet,
  getApplicantSscMarksheet,
  deleteApplicantSscMarksheet,
  uploadApplicantHsMarksheet,
  getApplicantHsMarksheet,
  deleteApplicantHsMarksheet,
  logoutApplicant,
  fetchApplicationBySlug,
  getApplicantProfilePic,
};
