<template>
  <div class="main-app">
    <sidebar-section></sidebar-section>
    <header-section></header-section>
    <div id="maincontent">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import HeaderSection from "../modules/Common/_components/header-section";
import SidebarSection from "../modules/Common/_components/sidebar-section";
import store from "../../src/modules/Applications/_store";
import reportStore from "../../src/modules/Report/_store";

export default {
  name: "Dashboard",
  components: {
    HeaderSection,
    SidebarSection,
  },
  created() {
    const STORE_KEY = "$_applicants_data";
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store);
    }

    const REPORT_STORE_KEY = "$_report";
    if (!(REPORT_STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(REPORT_STORE_KEY, reportStore);
    }
    localStorage.setItem("isLoggedIn", true);
    let lastURL = localStorage.getItem("lastURL");
    if (lastURL) {
      window.location = lastURL;
    }
  },
};
</script>

<style scoped>
#maincontent {
  transition: all 0.4s ease 0s;
  white-space: normal;
  flex-grow: 1;
}
</style>
