<template>
  <div class="content">
    <common-header :data="['home', 'applications']" type="applicant" />
    <div class="container-fluid">
      <div class="job_card_wrapper" v-if="applications.length > 0">
        <div class="job_card" v-for="item in applications" :key="item.id">
          <div class="float-right cst mt-2 ml-3">
            <router-link
              :to="{
                name: 'applicantApplicationBySlug',
                params: { slug: item.slug },
              }"
              v-b-tooltip.hover="'View Details'"
              tag="a"
              ><i class="fa fa-external-link" aria-hidden="true"></i>
            </router-link>
          </div>
          <div class="job_card_header">
            <img alt="Job" src="../../../assets/images/job_icon.jpg" />
            <h2>
              {{
                item.jobId ? item.position || "Off Campus" : "Fresher's Form"
              }}
            </h2>
          </div>

          <div>
            <div class="default">
              <strong class="text-uppercase">Application Id: </strong>
              {{ item.slug }}
            </div>
            <span><strong>Status: </strong> {{ item.application_status }}</span>
            <span
              ><p class="mb-0">
                <strong>Applied On:</strong>
                {{ item.createdAt | dateFormat }}
              </p></span
            >
          </div>
          <span v-if="item.college_name"
            ><strong>College: </strong> {{ item.college_name }}</span
          >

          <div v-if="item.designation_offered">
            <span
              ><strong>Designation Offered:</strong>
              {{ item.designation_offered }}</span
            >
            <span
              ><strong>Package Offered:</strong>
              {{ item.package_offered }}</span
            >
            <span
              ><strong>Expected Joining:</strong>
              {{ item.expected_joining }}</span
            >
          </div>
        </div>
      </div>
      <div v-else class="center-container">
        <h4>No Applications Found</h4>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  created() {
    this.$store.dispatch("$_applicant_master/fetchApplicantApplications");
  },
  data() {
    return {
      data: {
        email: "",
        password: "",
      },
    };
  },

  computed: {
    ...mapGetters({
      applications: "$_applicant_master/getApplicantApplications",
    }),
  },
};
</script>

<style scoped>
.center-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.job_card_wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.job_card {
  width: 350px;
  position: relative;
  margin: 10px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
  padding: 0px 15px;
  padding-bottom: 10px;
  font-size: 15px;
  line-height: 22px;
}
.job_card .job_card_header {
  margin: 30px 0px;
  text-align: center;
  margin-top: 40px;
}
.job_card .job_card_header h2 {
  font-size: 21px;
  font-weight: 700;
  margin: 15px 0px;
  color: #2e3941;
  line-height: 24px;
}
.job_card span {
  text-transform: uppercase;
  font-weight: 600;
  display: block;
  color: #75828a;
  margin-bottom: 5px;
  font-size: 14px;
}
.default {
  font-weight: 600;
  color: #75828a;
  margin-bottom: 5px;
  font-size: 14px;
}

.cst a {
  color: #903564;
  font-size: 18px;
}

/*job_card-css*/
/*Job_page_2-css*/
.job_card_lg {
  background: #fff;
  position: relative;
  min-height: 600px;
  padding: 20px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 991px) {
  .job_card_lg {
    margin: 15px 0px;
  }
}
.job_card_lg h2 {
  font-size: 32px;
  margin: 15px 0px;
  font-weight: 700;
  position: relative;
}
@media screen and (max-width: 991px) {
  .job_card_lg h2 {
    font-size: 24px;
  }
}
.job_card_lg h2:after {
  content: "";
  width: 90px;
  height: 5px;
  position: absolute;
  bottom: -8px;
  left: 0px;
  background: #8f1452;
}
.job_card_lg p {
  font-size: 15px;
  margin-top: 25px;
}
.job_card_lg .job_card_lg_footer {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.job_card_lg .job_card_lg_footer a {
  color: #903564;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}
.job_card_lg .job_card_lg_footer a i {
  padding-left: 5px;
}
.job_card_lg .job_card_lg_footer a:hover {
  color: #903564;
}
</style>
