var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('common-header',{attrs:{"data":['home', 'campusDrive', 'campusSchedules', 'singleSchedule'],"componentName":_vm.eventData?.campusData.recruitment_session_name +
      ' ~ ' +
      _vm.eventData?.EventDetails.round_title +
      (_vm.eventData?.EventDetails.round_name === 'Written Test'
        ? ' (WT)'
        : ' (I)'),"componentTooltip":`
            <div class='d-flex flex-column align-items-start'>
                <div class='my-1'>Recruitment: <strong>${_vm.eventData?.campusData.recruitment_session_name}</strong></div>
                <div class='my-1'>Event: <strong>${_vm.eventData?.EventDetails.round_title}</strong></div>
                <div class='my-1'>Type: <strong>${_vm.eventData?.EventDetails.round_name}</strong></div>
            </div>`}},[_c('div',{staticClass:"text-right"},[_c('div',{staticClass:"d-none d-md-block"},[_c('p',{staticClass:"font-size-small mb-0"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.eventData?.EventDetails.round_schedule))+" - "+_vm._s(_vm.formatDate(_vm.eventData?.EventDetails.round_schedule_end))+" ")]),_c('p',{staticClass:"font-size-small font-weight-bold mb-0"},[_vm._v(" Dependent Event - "+_vm._s(_vm.eventData?.dependentEventDetails ? _vm.eventData?.dependentEventDetails.round_title : "No Dependency")+" ")])]),_c('div',{staticClass:"d-block d-md-none"},[_c('p',{staticClass:"font-size-small mb-0"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.eventData?.EventDetails.round_schedule))+" - "+_vm._s(_vm.formatDate(_vm.eventData?.EventDetails.round_schedule_end))+" ")]),_c('p',{staticClass:"font-size-small font-weight-bold mb-0"},[_vm._v(" Dependent Event - "+_vm._s(_vm.eventData?.dependentEventDetails ? _vm.eventData?.dependentEventDetails.round_title : "No Dependency")+" ")])])])]),_c('div',{staticClass:"container-fluid"},[(_vm.hasAccess({ permissions: [this.BASE_PERMISSION.MENU] }))?_c('div',[_c('div',[_c('div',[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-12 col-md-4 pr-md-2 px-0 border-md-right"},[_c('event-applications-list',{ref:"applicantList",attrs:{"selectedStatus":_vm.selectedStatus}})],1),(_vm.eventData)?_c('div',{staticClass:"col-12 col-md-4 px-0 px-md-2 border-md-right d-flex flex-column mt-4 mt-md-0"},[_c('div',{staticClass:"w-100 d-flex justify-content-between align-items-center"},[_c('div',[_c('p',{staticClass:"m-0 p-0"},[_c('b',{staticClass:"mr-3"},[_vm._v("Slot range -")]),_vm._v(" "+_vm._s(_vm.extractTime(_vm.eventData.EventDetails.round_schedule))+" -  "+_vm._s(_vm.extractTime(_vm.eventData.EventDetails.round_schedule_end))+" ")])]),_c('div',{staticClass:"d-flex"},[(
                      _vm.selectedSlotId &&
                      _vm.hasAccess({
                        permissions: [this.SLOT_PERMISSION.DELETE],
                      })
                    )?_c('button',{staticClass:"btn btn-cp btn-sm mx-1",on:{"click":_vm.deleteSlot}},[_vm._v(" Delete ")]):_vm._e(),(
                      _vm.hasAccess({
                        permissions: [this.SLOT_PERMISSION.CREATE],
                      })
                    )?_c('button',{staticClass:"btn btn-sm btn-cp mx-1",on:{"click":_vm.createSlot}},[_vm._v(" Add ")]):_vm._e()])]),(_vm.hasAccess({ permissions: [this.SLOT_PERMISSION.READ] }))?[(_vm.eventData && _vm.slotsList)?_c('div',{staticClass:"table-responsive m-0",staticStyle:{"flex-grow":"1"}},[_c('table',{staticClass:"table tabel-condensed",attrs:{"aria-label":"References"}},[_c('thead',[_c('th',{staticClass:"col-1 px-0"},[_vm._v("#")]),_c('th',{staticClass:"col-2 text-nowrap"},[_vm._v("Date")]),_c('th',{staticClass:"col-3 px-0 text-nowrap"},[_vm._v("Slot time")]),_c('th',{staticClass:"col-2"},[_vm._v("Count")]),_c('th',{staticClass:"col-3 th-interview"},[_vm._v(" "+_vm._s(_vm.eventData.EventDetails.round_name == "Written Test" ? "Scheduler" : "Interviewer")+" ")])]),(_vm.eventData && _vm.slotsList)?_c('tbody',_vm._l((_vm.slotsList),function(slot,index){return _c('tr',{key:index,class:[
                          _vm.scheduleDateInvalid(slot.to_time)
                            ? 'slot-div-expired'
                            : _vm.selectedSlotId == slot.id
                            ? 'slot-div-active'
                            : 'slot-div-inactive',
                        ],attrs:{"role":"button"},on:{"click":function($event){return _vm.setSelectedSlotId(slot.id)}}},[_c('td',{staticClass:"col-1 px-0 py-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedSlotId),expression:"selectedSlotId"}],staticClass:"m-1",attrs:{"type":"radio","id":'slotDefaultCheck' + index},domProps:{"value":slot.id,"checked":_vm._q(_vm.selectedSlotId,slot.id)},on:{"change":function($event){_vm.selectedSlotId=slot.id}}})]),_c('td',{staticClass:"col-2 text-nowrap py-1"},[_vm._v(" "+_vm._s(_vm.extractDate(slot.from_time))+" ")]),_c('td',{staticClass:"col-3 px-0 text-nowrap py-1"},[_vm._v(" "+_vm._s(_vm.extractTime(slot.from_time) + " - " + _vm.extractTime(slot.to_time))+" ")]),_c('td',{staticClass:"col-2 py-1"},[_vm._v(" "+_vm._s(slot.applicants_count)+" ")]),_c('td',{staticClass:"col-3 th-interview py-1"},_vm._l((slot.interviewers),function(interviewer,index2){return _c('p',{key:index2,staticClass:"m-0 p-0"},[_vm._v(" "+_vm._s(interviewer)+" ")])}),0)])}),0):_vm._e()])]):_vm._e()]:_vm._e()],2):_vm._e(),(_vm.eventData)?_c('div',{staticClass:"col-12 col-md-4 px-0 mt-4 mt-md-0"},[_c('slot-page',{attrs:{"slotsList":_vm.slotsList,"fetchEventSlots":_vm.fetchEventSlots,"setDataSource":_vm.setDataSource,"selectedSlotId":_vm.selectedSlotId,"refreshGrid":_vm.refreshGrid}})],1):_vm._e()])])])]):_vm._e()]),(_vm.hasAccess({ permissions: [_vm.SLOT_PERMISSION.CREATE] }))?_c('slotModal',{attrs:{"fetchEventSlots":_vm.fetchEventSlots,"minTime":_vm.minTime,"maxTime":_vm.maxTime,"inPlaceAction":true}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }