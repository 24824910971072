<template>
  <div class="content">
    <common-header :data="['home', 'report', 'applicantReport']">
    </common-header>
    <div class="d-flex">
      <div class="col-4" style="margin-top: 3px">
        <label class="required modalfont">Select Jobs</label>
        <multiselect
          name="jobsSelected"
          :searchable="false"
          :multiple="true"
          :options="categoryOptions"
          :close-on-select="false"
          v-model="selectedJobs"
          v-validate="'required'"
          data-vv-as="Jobs"
          :class="{ 'is-invalid': errors.first('jobsSelected') }"
        ></multiselect>
        <span
          v-if="errors.first('jobsSelected')"
          class="valid-feedback alert-danger"
        >
          {{ errors.first("jobsSelected") }}
        </span>
      </div>
      <div class="col-md-3">
        <div>
          <label class="control-label">From Date</label>
          <date-picker
            name="fromDate"
            v-model="filterData.fromDate"
            id="fromDate"
            :disabled="filterType !== 'Custom'"
            :config="config"
            v-validate="'required'"
            data-vv-as="From Date"
          ></date-picker>
          <span
            v-if="errors.first('fromDate')"
            class="valid-feedback alert-danger"
          >
            {{ errors.first("fromDate") }}
          </span>
        </div>
      </div>
      <div class="col-md-3">
        <div>
          <label class="control-label">To Date</label>
          <date-picker
            id="toDate"
            name="toDate"
            :disabled="filterType !== 'Custom'"
            v-model="filterData.toDate"
            :config="config"
            v-validate="'required'"
            data-vv-as="To Date"
          ></date-picker>
          <span
            v-if="errors.first('toDate')"
            class="valid-feedback alert-danger"
          >
            {{ errors.first("toDate") }}
          </span>
          <span v-if="!isDateValid" class="valid-feedback alert-danger">
            From date should not be greater than To date
          </span>
        </div>
      </div>
      <div class="" style="margin-top: 36px">
        <button @click="generateStat" class="btn-cp btn">Generate</button>
      </div>
    </div>
    <div>
      <div class="form-group col-lg-12 col-md-12 col-sm-12">
        <div class="col-lg-12 col-md-12 col-sm-12 text-wrap d-flex mt-4">
          <strong class="m-r15 mb-2">Filters :</strong>
          <label for="lastWeek" class="radio-inline m-r10">
            <input
              type="radio"
              id="lastWeek"
              value="Last One Week"
              v-model="filterType"
            />
            Last One Week
          </label>
          <label for="lastMonth" class="radio-inline ml-2 m-r10">
            <input
              type="radio"
              id="lastMonth"
              value="Last One Month"
              v-model="filterType"
            />
            Last One Month
          </label>
          <label for="lastThreeMonth" class="radio-inline ml-2 m-r10">
            <input
              type="radio"
              id="lastThreeMonth"
              value="Last Three Months"
              v-model="filterType"
            />
            Last Three Months
          </label>
          <label for="lastSixMonths" class="radio-inline ml-2 m-r10">
            <input
              type="radio"
              id="lastSixMonths"
              value="Last Six Months"
              v-model="filterType"
            />
            Last Six Months
          </label>
          <label for="lastYear" class="radio-inline ml-2 m-r15">
            <input
              type="radio"
              id="lastYear"
              value="Last One Year"
              v-model="filterType"
            />
            Last One Year
          </label>
          <label for="custom" class="radio-inline ml-2 m-r10">
            <input
              type="radio"
              id="Custom"
              value="Custom"
              v-model="filterType"
            />
            Custom
          </label>
        </div>
      </div>
    </div>
    <div v-if="Object.keys(filteredData).length != 0" class="container-fluid">
      <div class="row mx-0">
        <div class="col-md-12 p-0">
          <div class="well">
            <div class="table-responsive">
              <table
                class="table table-condensed"
                aria-label="Applicant Details"
              >
                <thead>
                  <th colspan="10">
                    <h4 class="summary-header text-center p-2">
                      <strong>Overall Summary </strong>
                      <span
                        >({{ filterData.fromDate | dateFormat }} -
                        {{ filterData.toDate | dateFormat }})</span
                      >
                    </h4>
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td
                      @click="filterApplicationStatus('Received')"
                      class="col-md-2 cursorCls"
                      :class="{ selected: selectedStatus === 'Received' }"
                    >
                      <strong>Received</strong>
                    </td>
                    <td
                      @click="filterApplicationStatus('Received')"
                      class="col-md-2 cursorCls"
                      :class="{ selected: selectedStatus === 'Received' }"
                    >
                      {{ filteredData.stats.Received || 0 }}
                    </td>

                    <td
                      @click="filterApplicationStatus('Processed')"
                      class="col-md-2 cursorCls"
                      :class="{ selected: selectedStatus === 'Processed' }"
                    >
                      <strong>Processed</strong>
                    </td>
                    <td
                      class="col-md-2 cursorCls"
                      @click="filterApplicationStatus('Processed')"
                      :class="{ selected: selectedStatus === 'Processed' }"
                    >
                      {{ filteredData.stats.Processed || 0 }}
                    </td>
                    <td
                      @click="filterApplicationStatus('Failed')"
                      class="col-md-2 cursorCls"
                      :class="{ selected: selectedStatus === 'Failed' }"
                    >
                      <strong>Failed</strong>
                    </td>
                    <td
                      class="col-md-2 cursorCls"
                      @click="filterApplicationStatus('Failed')"
                      :class="{ selected: selectedStatus === 'Failed' }"
                    >
                      {{ filteredData.stats.Failed || 0 }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      :class="{ selected: selectedStatus === 'Differed' }"
                      class="col-md-2 cursorCls"
                      @click="filterApplicationStatus('Differed')"
                    >
                      <strong>Differed</strong>
                    </td>
                    <td
                      @click="filterApplicationStatus('Differed')"
                      class="col-md-2 cursorCls"
                      :class="{ selected: selectedStatus === 'Differed' }"
                    >
                      {{ filteredData.stats.Differed || 0 }}
                    </td>
                    <td
                      :class="{ selected: selectedStatus === 'Talent Pool' }"
                      class="col-md-2 cursorCls"
                      @click="filterApplicationStatus('Talent Pool')"
                    >
                      <strong>Talent Pool</strong>
                    </td>
                    <td
                      @click="filterApplicationStatus('Talent Pool')"
                      class="col-md-2 cursorCls"
                      :class="{ selected: selectedStatus === 'Talent Pool' }"
                    >
                      {{ filteredData.stats["Talent Pool"] || 0 }}
                    </td>
                    <td
                      :class="{ selected: selectedStatus === 'Held' }"
                      @click="filterApplicationStatus('Held')"
                      class="col-md-2 cursorCls"
                    >
                      <strong>Held</strong>
                    </td>
                    <td
                      @click="filterApplicationStatus('Held')"
                      class="col-md-2 cursorCls"
                      :class="{ selected: selectedStatus === 'Held' }"
                    >
                      {{ filteredData.stats.Held || 0 }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      @click="filterApplicationStatus('Passed')"
                      :class="{ selected: selectedStatus === 'Passed' }"
                      class="col-md-2 cursorCls"
                    >
                      <strong>Passed</strong>
                    </td>
                    <td
                      @click="filterApplicationStatus('Passed')"
                      class="col-md-2 cursorCls"
                      :class="{ selected: selectedStatus === 'Passed' }"
                    >
                      {{ filteredData.stats.Shortlisted || 0 }}
                    </td>
                    <td
                      @click="filterApplicationStatus('Shortlisted')"
                      :class="{ selected: selectedStatus === 'Shortlisted' }"
                      class="col-md-2 cursorCls"
                    >
                      <strong>Shortlisted</strong>
                    </td>
                    <td
                      @click="filterApplicationStatus('Shortlisted')"
                      class="col-md-2 cursorCls"
                      :class="{ selected: selectedStatus === 'Shortlisted' }"
                    >
                      {{ filteredData.stats.Shortlisted || 0 }}
                    </td>

                    <td
                      @click="filterApplicationStatus('Joined')"
                      :class="{ selected: selectedStatus === 'Joined' }"
                      class="col-md-2 cursorCls"
                    >
                      <strong>Joined</strong>
                    </td>
                    <td
                      @click="filterApplicationStatus('Joined')"
                      class="col-md-2 cursorCls"
                      :class="{ selected: selectedStatus === 'Joined' }"
                    >
                      {{ filteredData.stats.Joined || 0 }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row mx-0"
        v-if="roundData.rounds && Object.keys(roundData.rounds).length > 0"
      >
        <div class="col-md-12 p-0">
          <div class="well">
            <div class="table-responsive">
              <table
                class="table table-condensed"
                aria-label="Applicant Details"
              >
                <thead>
                  <th colspan="10">
                    <h4 class="summary-header text-center p-2">
                      <strong>Round Summary</strong>
                      <select v-model="selectedRound" class="cst-select">
                        <option
                          v-for="(round, key) in roundData.rounds"
                          :key="key"
                          :value="key"
                        >
                          Round {{ key }}
                        </option>
                      </select>
                      <strong style="float: right; margin-right: 10px"
                        >Total Rounds:
                        {{ Object.keys(roundData.rounds).length }}</strong
                      >
                    </h4>
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td
                      @click="roundTableData('total', 'Rtotal')"
                      class="col-md-2 cursorCls"
                      :class="{ selected: selectedStatus === 'Rtotal' }"
                    >
                      <strong>Total</strong>
                    </td>
                    <td
                      :class="{ selected: selectedStatus === 'Rtotal' }"
                      @click="roundTableData('total', 'Rtotal')"
                      class="col-md-2 cursorCls"
                    >
                      {{ roundData.rounds[selectedRound].total || "NA" }}
                    </td>
                    <td
                      :class="{ selected: selectedStatus === 'RPassed' }"
                      @click="roundTableData('Passed', 'RPassed')"
                      class="col-md-2 cursorCls"
                    >
                      <strong>Pass</strong>
                    </td>
                    <td
                      :class="{ selected: selectedStatus === 'RPassed' }"
                      @click="roundTableData('Passed', 'RPassed')"
                      class="col-md-2 cursorCls"
                    >
                      {{ roundData.rounds[selectedRound].Passed || "NA" }}
                    </td>
                    <td
                      :class="{ selected: selectedStatus === 'RFailed' }"
                      @click="roundTableData('Failed', 'RFailed')"
                      class="col-md-2 cursorCls"
                    >
                      <strong>Fail</strong>
                    </td>
                    <td
                      :class="{ selected: selectedStatus === 'RFailed' }"
                      @click="roundTableData('Failed', 'RFailed')"
                      class="col-md-2 cursorCls"
                    >
                      {{ roundData.rounds[selectedRound].Failed || "NA" }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      :class="{ selected: selectedStatus === 'RPending' }"
                      @click="roundTableData('Pending', 'RPending')"
                      class="col-md-2 cursorCls"
                    >
                      <strong>Pending</strong>
                    </td>
                    <td
                      :class="{ selected: selectedStatus === 'RPending' }"
                      @click="roundTableData('Pending', 'RPending')"
                      class="col-md-2 cursorCls"
                    >
                      {{ roundData.rounds[selectedRound].Pending || "NA" }}
                    </td>
                    <td
                      :class="{ selected: selectedStatus === 'RDiffered' }"
                      @click="roundTableData('Differed', 'RDiffered')"
                      class="col-md-2 cursorCls"
                    >
                      <strong>Differed</strong>
                    </td>
                    <td
                      :class="{ selected: selectedStatus === 'RDiffered' }"
                      @click="roundTableData('Differed', 'RDiffered')"
                      class="col-md-2 cursorCls"
                    >
                      {{ roundData.rounds[selectedRound].Differed || "NA" }}
                    </td>
                    <td
                      :class="{ selected: selectedStatus === 'RProcessed' }"
                      @click="roundTableData('Processed', 'RProcessed')"
                      class="col-md-2 cursorCls"
                    >
                      <strong>Processed</strong>
                    </td>
                    <td
                      :class="{ selected: selectedStatus === 'RProcessed' }"
                      @click="roundTableData('Processed', 'RProcessed')"
                      class="col-md-2 cursorCls"
                    >
                      {{ roundData.rounds[selectedRound].Processed || "NA" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <h3>No rounds to display</h3>
      </div>
    </div>
    <div class="container-fluid">
      <ag-grid-vue
        v-if="Object.keys(filteredData).length != 0"
        class="ag-theme-alpine ag-grid-style ag-grid-footer-style"
        style="height: 70vh"
        :enableBrowserTooltips="true"
        :gridOptions="gridOptions"
        @gridReady="onGridReady"
        :key="paginationSize"
        :rowData="rowData"
        :columnDefs="columnDefs"
      >
      </ag-grid-vue>
    </div>
  </div>
</template>

<script>
import datePicker from "vue-bootstrap-datetimepicker";
import CommonHeader from "../../../Common/_components/common-header.vue";
import Multiselect from "vue-multiselect";
import { getFilterColumns } from "../../../Common/_plugin/validation";
import { AgGridVue } from "ag-grid-vue";
import { mapGetters } from "vuex";
import { addSuccessSnackbarMessage } from "../../../Common/_plugin/error-response";
export default {
  components: {
    CommonHeader,
    Multiselect,
    datePicker,
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    "profile-link": {
      template: `<router-link v-if="params.data" target='_blank' :to="{ name: 'applicantprofile', params: { slug: params.data.slug } }"><i class="fa fa-user-circle-o" aria-hidden="true" style="color:#832e5b"></i></router-link>`,
    },
  },
  data() {
    return {
      selectedJobs: [],
      config: {
        format: "DD MMMM YYYY",
        maxDate: new Date(),
        showClear: true,
        showClose: true,
      },
      filterData: {
        fromDate: null,
        toDate: new Date(),
      },
      paginationSize: 100,
      selectedStatus: null,
      selectedRound: 1,
      rowData: null,
      columnnDefs: [],
      activeTabIndex: 0,
      gridOptions: null,
      currentRoundStatus: null,
      filterType: null,
    };
  },
  methods: {
    getJobs() {
      this.$store.dispatch("$_applicant_report/fetchCategoriesWithJobs");
    },
    roundTableData(value, selectedStatus) {
      if (this.currentRoundStatus === value) return;

      if (!this.roundData.rounds[this.selectedRound][value]) {
        addSuccessSnackbarMessage("No Data Available");
        return;
      }
      this.currentRoundStatus = value;
      this.selectedStatus = selectedStatus;
      this.activeTabIndex = 1;
      const selectedJobIds = [];
      this.selectedJobs.forEach((jobName) => {
        const categoryWithJob = this.categoriesWithJobs.find(
          (category) => category.category_name === jobName
        );
        if (categoryWithJob) {
          selectedJobIds.push(categoryWithJob.id);
        }
      });
      const data = {
        startDate: this.filterData.fromDate,
        endDate: this.filterData.toDate,
        jobIds: selectedJobIds,
        type: "round",
        rn: this.selectedRound,
        value: value,
      };
      this.$store.dispatch("$_applicant_report/getDetailedReport", data);
    },
    async generateStat() {
      await this.$validator.validateAll();
      if (this.$validator.errors.items.length > 0) {
        this.$store.dispatch("openSnackbar", {
          type: "danger",
          message: "Please enter valid values!",
        });
        return;
      }
      this.selectedStatus = "Received";
      this.selectedRound = "1";
      const selectedJobIds = [];
      this.selectedJobs.forEach((jobName) => {
        const categoryWithJob = this.categoriesWithJobs.find(
          (category) => category.category_name === jobName
        );
        if (categoryWithJob) {
          selectedJobIds.push(categoryWithJob.id);
        }
      });
      const data = {
        startDate: this.filterData.fromDate,
        endDate: this.filterData.toDate,
        jobIds: selectedJobIds,
      };
      this.$store.dispatch("$_applicant_report/getReportByJobs", data);
      this.$store.dispatch("$_applicant_report/getRoundData", data);
    },

    async filterApplicationStatus(value) {
      this.currentRoundStatus = null;
      this.selectedStatus = value;
      this.activeTabIndex = 0;

      if (value === "Received") {
        this.rowData = this.filteredData.applicant;
        return;
      }
      const filteredIds = new Set(this.filteredData.status[value]);
      const filteredData = this.filteredData.applicant.filter((item) =>
        filteredIds.has(item.id)
      );
      this.rowData = filteredData;
    },

    createColumnDefs() {
      const profile_link = {
        cellRenderer: "profile-link",
        width: 35,
        maxWidth: 35,
        suppressFilter: true,
        filter: false,
        valueGetter: "node.id",
        headerTooltip: "Profile Info",
        tooltipValueGetter: function (params) {
          if (params.data != undefined) {
            return "Profile of  " + params.data.first_name;
          }
          return "";
        },
        pinned: "left",
        display_only: true,
        cellStyle: {
          display: "flex",
          "justify-content": "center",
        },
      };
      const name = {
        headerName: "Name",
        field: "first_name",
        valueGetter: function mergeLastNameFirstName(params) {
          if (params.data != undefined) {
            return params.data?.first_name + " " + params.data?.last_name;
          }
        },
        sortable: true,
        minWidth: 150,
        suppressHeaderMenuButton: true,
        filterParams: {
          newRowsAction: "keep",
        },
      };
      const email = {
        headerName: "Email",
        field: "email",
        sortable: true,
        minWidth: 150,
        suppressHeaderMenuButton: true,
        filterParams: {
          newRowsAction: "keep",
        },
      };
      const application_status = {
        headerName: " Current Application Status",
        field: "application_status",
        sortable: true,
        minWidth: 150,
        suppressHeaderMenuButton: true,
        filterParams: {
          newRowsAction: "keep",
        },
      };
      const round_result = {
        headerName: "Round result",
        field: "round_result",
        sortable: true,
        minWidth: 150,
        suppressHeaderMenuButton: true,
        filterParams: {
          newRowsAction: "keep",
        },
      };
      if (this.activeTabIndex == 0) {
        this.columnDefs = [profile_link, name, email, application_status];
      } else {
        this.columnDefs = [
          profile_link,
          name,
          email,
          round_result,
          application_status,
        ];
      }
      if (this.gridOptions) {
        this.gridOptions.columnDefs = this.columnDefs;
      }
    },

    onGridReady(params) {
      this.gridOptions.api = params.api;
    },

    configGridOptions() {
      this.createColumnDefs();
      this.gridOptions = {
        rowData: null,
        columnDefs: getFilterColumns(this.columnDefs, 1),
        rowHeight: 30,
        suppressPropertyNamesCheck: true,
        enableCellTextSelection: true,
        enableServerSideFilter: true,
        enableColResize: true,
        rowSelection: "none",
        suppressRowClickSelection: true,
        defaultColDef: {
          flex: 1,
          resizable: true,
          floatingFilter: true,
          filterParams: {
            suppressAndOrCondition: true,
            buttons: ["reset"],
          },
          // set filtering on for all columns
          filter: true,
        },
        animateRows: true,
        maxConcurrentDatasourceRequests: 2,
        pagination: true,
        paginationPageSize: 100,
        paginationPageSizeSelector: false,
        context: {
          componentParent: this,
        },
      };
    },
  },
  watch: {
    filterType(value) {
      let days = 0;
      let curDate = new Date();
      switch (value) {
        case "Last One Week":
          days = 7;
          this.filterData.toDate = new Date(curDate);
          this.filterData.fromDate = new Date(
            curDate.getTime() - days * 24 * 60 * 60 * 1000
          );
          break;
        case "Last One Month":
          days = 30;
          this.filterData.toDate = curDate;
          this.filterData.fromDate = new Date(
            curDate.getTime() - days * 24 * 60 * 60 * 1000
          );
          break;
        case "Last Three Months":
          days = 90;
          this.filterData.toDate = curDate;
          this.filterData.fromDate = new Date(
            curDate.getTime() - days * 24 * 60 * 60 * 1000
          );
          break;
        case "Last Six Months":
          days = 180;
          this.filterData.toDate = curDate;
          this.filterData.fromDate = new Date(
            curDate.getTime() - days * 24 * 60 * 60 * 1000
          );
          break;
        case "Last One Year":
          days = 365;
          this.filterData.toDate = curDate;
          this.filterData.fromDate = new Date(
            curDate.getTime() - days * 24 * 60 * 60 * 1000
          );
          break;
        case "Custom":
          if (this.filterData.toDate == null) {
            this.filterData.toDate = null;
          } else {
            this.filterData.toDate = new Date(this.filterData.toDate);
          }
          this.filterData.fromDate = new Date(this.filterData.fromDate);
          break;
        case "":
          this.filterData.fromDate = null;
          this.filterData.toDate = null;
          break;
      }
      this.$store.dispatch("$_applicant_report/clearStoreData", []);
    },
    roundWiseData: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.rowData = newValue;
        }
      },
      immediate: true,
    },
    "filteredData.applicant": {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.rowData = newValue;
        }
      },
      immediate: true,
    },
    activeTabIndex: {
      immediate: true,
      handler() {
        this.createColumnDefs();
      },
    },
    selectedRound: {
      handler() {
        this.currentRoundStatus = null;
        this.selectedStatus = "Received";
        this.rowData = this.filteredData.applicant;
      },
    },
  },
  computed: {
    isDateValid() {
      if (this.filterData.fromDate && this.filterData.toDate) {
        return new Date(this.filterData.fromDate) >
          new Date(this.filterData.toDate)
          ? false
          : true;
      }
      return true;
    },
    ...mapGetters({
      categoriesWithJobs: "$_applicant_report/categoriesWithJobs",
      filteredData: "$_applicant_report/fetchFilteredData",
      roundData: "$_applicant_report/fetchRoundData",
      roundWiseData: "$_applicant_report/fetchDetailedReport",
    }),
    categoryOptions() {
      return this.categoriesWithJobs.map((category) => category.category_name);
    },
  },
  created() {
    this.configGridOptions();
    this.getJobs();
    this.filterType = "Last One Year";
  },
  beforeMount() {
    this.configGridOptions();
    this.$store.dispatch("$_applicant_report/clearStoreData");
  },
};
</script>

<style>
.cursorCls {
  cursor: pointer;
}
.selected {
  font-weight: 900;
  background-color: #ccc; /* Add your styling for selected status */
}
.cst-select {
  margin-left: 10px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
