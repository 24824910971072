import axios from "axios";

/**
 * @memberOf module:ApplicantsData.api
 * @param {Object} data
 * @returns {Promise}
 * @description Fetch applicants data based on page size
 */
const fetchApplicantsData = (queryParams, filterOptions) => {
  if (queryParams) {
    return axios.post(`applicants/data_list?${queryParams}`, filterOptions);
  }
  return axios.post(`applicants/data_list`, filterOptions);
};

/**
 * @memberOf module:ApplicantsData.api
 * @param {Object} data
 * @returns {Promise}
 * @description Fetch applicants data based on status
 */
const fetchApplicantsDataByStatus = (data) => {
  return axios.post("applicants/by/status", data);
};

/**
 * @memberOf module:ApplicantsData.api
 * @param {Object} data
 * @returns {Promise}
 * @description Fetch fresh applicants count based on employment status
 */
const fetchFreshApplicationCount = (employmentStatus) => {
  return axios.get(
    "applicants/count/fresh_application?employmentStatus=" + employmentStatus
  );
};

/**
 * @memberOf module:ApplicantsData.api
 * @returns {Promise}
 * @description Fetch application status and their count
 */
const fetchApplicationStatusCount = (data) => {
  if (!data) {
    return axios.get("applicants/count/status");
  } else {
    data.fromDate == null && (data.fromDate = undefined);
    if (typeof data.fromDate === "object") {
      data.fromDate = data.fromDate.toJSON();
      if (data.toDate == null) {
        data.toDate = new Date();
      } else {
        data.toDate = data.toDate.toJSON();
      }
    } else {
      if (data.toDate == null) {
        let newDate = new Date();
        data.toDate = dateFormatter(newDate);
      }
    }

    return axios.get(
      "applicants/count/status?fromDate=" +
        data.fromDate +
        "&toDate=" +
        data.toDate +
        "&includeAll=" +
        data.includeAll +
        "&employmentStatus=" +
        data.employmentStatus +
        "&duplicateType=" +
        data.duplicateType +
        "&campusType=" +
        data.campusType
    );
  }
};

/**
 * @memberOf module:ApplicantsData.api
 * @returns {Promise}
 * @description Fetch application position and their count
 */
const fetchApplicationPositionCount = (data) => {
  if (!data) {
    return axios.get("applicants/count/position");
  } else {
    data.fromDate == null && (data.fromDate = undefined);
    if (typeof data.fromDate === "object") {
      data.fromDate = data.fromDate.toJSON();
      if (data.toDate == null) {
        data.toDate = new Date();
      } else {
        data.toDate = data.toDate.toJSON();
      }
    } else {
      if (data.toDate == null) {
        let newDate = new Date();
        data.toDate = dateFormatter(newDate);
      }
    }

    return axios.get(
      "applicants/count/position?fromDate=" +
        data.fromDate +
        "&toDate=" +
        data.toDate +
        "&includeAll=" +
        data.includeAll +
        "&applicationStatus=" +
        data.applicationStatus +
        "&employmentStatus=" +
        data.employmentStatus +
        "&duplicateType=" +
        data.duplicateType +
        "&campusType=" +
        data.campusType
    );
  }
};

/**
 * @memberOf module:ApplicantsData.api
 * @returns {Promise}
 * @description Fetch application position and their count
 */
const fetchApplicationEmploymentStatusCount = (data) => {
  if (!data) {
    return axios.get("applicants/count/employment_status");
  } else {
    if (typeof data.fromDate === "object") {
      data.fromDate = data.fromDate.toJSON();
      if (data.toDate == null) {
        data.toDate = new Date();
      } else {
        data.toDate = data.toDate.toJSON();
      }
    } else {
      if (data.toDate == null) {
        let newDate = new Date();
        data.toDate = dateFormatter(newDate);
      }
    }

    return axios.get(
      "applicants/count/employment_status?fromDate=" +
        data.fromDate +
        "&toDate=" +
        data.toDate +
        "&includeAll=" +
        data.includeAll +
        "&applicationStatus=" +
        data.applicationStatus +
        "&position=" +
        data.position +
        "&employmentStatus=" +
        data.employmentStatus
    );
  }
};

const dateFormatter = (date) => {
  date = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
  date = JSON.stringify(date);
  date = date.slice(1, -1);
  return date;
};

/**
 * @memberOf module:ApplicantsData.api
 * @param {Array} roundData
 * @returns {Promise}
 * @description Add round for applicants
 */
const addRound = (roundData) => {
  const data = {
    data: roundData,
  };
  return axios.post("rounds/round_schedules", data);
};

/**
 * @memberOf module:ApplicantsData.api
 * @param {Array} updatedRoundData
 * @returns {Promise}
 * @description Pass round for applicants
 */
const passLastRound = (updatedRoundData) => {
  return update_applicant_data(updatedRoundData);
};

/**
 * @memberOf module:ApplicantsData.api
 * @param {Array} updatedRoundData
 * @returns {Promise}
 * @description Fail round for applicants
 */
const failLastRound = (updatedRoundData) => {
  return update_applicant_data(updatedRoundData);
};

/**
 * @memberOf module:ApplicantsData.api
 * @param {Array} updatedRoundData
 * @returns {Promise}
 * @description Revert round for applicants
 */
const revertLastRound = (updatedRoundData) => {
  return update_applicant_data(updatedRoundData);
};

/**
 * @memberOf module:ApplicantsData.api
 * @param {Array} updatedRoundData
 * @returns {Promise}
 * @description Cancel round for applicants
 */
const cancelLastRound = (updatedRoundData) => {
  return update_applicant_data(updatedRoundData);
};

const update_applicant_data = (updatedRoundData) => {
  let formData = new FormData();

  let userData = JSON.stringify(updatedRoundData);
  formData.append("data", userData);
  return axios.put("applicants/applicant", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * @memberOf module:ApplicantsData.api
 * @param {Array} updatedRoundData
 * @returns {Promise}
 * @description Shortlist applicant and change main status to shortlisted
 */
const shortlistApplicants = (updatedRoundData) => {
  return update_applicant_data(updatedRoundData);
};

/**
 * @memberOf module:ApplicantsData.api
 * @param {Array} updatedRoundData
 * @returns {Promise}
 * @description process applicant and change main status to processed
 */
const processApplicants = (updatedRoundData) => {
  return update_applicant_data(updatedRoundData);
};

/**
 * @memberOf module:ApplicantsData.api
 * @param {Array} updatedRoundData
 * @returns {Promise}
 * @description Differ applicant and change main status to differed
 */
const differApplicants = (updatedRoundData) => {
  return update_applicant_data(updatedRoundData);
};

/**
 * @memberOf module:ApplicantsData.api
 * @param {Array} updatedRoundData
 * @returns {Promise}
 * @description join applicant and change main status to joined
 */
const joinedApplicants = (updatedRoundData) => {
  return update_applicant_data(updatedRoundData);
};

/**
 * @memberOf module:ApplicantsData.api
 * @param {Array} updatedRoundData
 * @returns {Promise}
 * @description not appeared applicant and change main status to not appeared
 */
const notAppearedApplicants = (updatedRoundData) => {
  return update_applicant_data(updatedRoundData);
};

/**
 * @memberOf module:ApplicantsData.api
 * @param {Array} updatedRoundData
 * @returns {Promise}
 * @description Move applicant to Talent Pool and change main status to Talent Pool
 */
const moveApplicantsToTalentPool = (updatedRoundData) => {
  return update_applicant_data(updatedRoundData);
};

/**
 * @memberOf module:ApplicantsData.api
 * @param {Array} campusMailIDs
 * @returns {Promise}
 * @description Fetch Co-ordinator emails based on campus
 */
const fetchCampusEmails = (campusId) => {
  return axios.get("campus-drive/recruiters/by/recruitment", {
    params: { campusId },
  });
};

/**
 * @memberOf module:ApplicantsData.api
 * @param {Array} campusID
 * @returns {Promise}
 * @description Fetch registered aplicants count for a campus drive
 */
const getRegisteredApplicantStatusCount = (campusId) => {
  return axios.get("applicants/count/status?campusId=" + campusId);
};

/**
 * @memberOf module:ApplicantsData.api
 * @returns {Promise}
 * @description Fetch open job positions by job
 */
const fetchOpenJobPositions = () => {
  return axios.get("jobs/positions/open");
};

const receiveUserMails = (mailId) => {
  return axios.get("/applicants/mails?mail=" + mailId);
};

const fetchAttachment = (msgId, id) => {
  return axios.get("/mail/attachment?msgId=" + msgId + "&id=" + id);
};

/**
 * @memberof module:ApplicantsData.api
 * @returns {Promise}
 * @description Fetch logged in user specific notification
 */
const fetchUserNotifications = (userId) => {
  return axios.get(`users/notification/${userId}`, {
    meta: { abortRequests: false },
  });
};

/**
 * @memberof module:ApplicantsData.api
 * @returns {Promise}
 * @description set user specific notification seen
 */
const setUserNotificationSeen = (seenCommentId) => {
  return axios.put(`users/notification/${seenCommentId}`, {});
};

/**
 * @memberOf module:ApplicantsData.api
 * @returns {Promise}
 * @description Fetch multiple round schedules of all applicants from current date
 */
const fetchRoundSchedules = (queryParams) => {
  if (queryParams) {
    return axios.get("rounds/round_schedules", {
      params: queryParams,
    });
  } else {
    axios.get("rounds/round_schedules");
  }
};

const fetchAllFollowUps = (queryParams) => {
  if (queryParams.userId) {
    return axios.get(`applicants/followup?userId=${queryParams.userId}`);
  } else {
    return axios.get("applicants/followup");
  }
};

/**
 * @memberOf module:ApplicantsData.api
 * @returns {Promise}
 * @description Fetch multiple round schedules of self from current date
 */
const fetchMySchedule = (filters) => {
  if (filters) {
    return axios.get("rounds/my_schedule", {
      params: filters,
    });
  } else {
    axios.get("rounds/my_schedule");
  }
};

const fetchAllSchedule = (filters) => {
  return axios.get("rounds/my-schedules", {
    params: filters,
  });
};

const fetchAllLinkRequests = () => {
  return axios.get("/applicants/all-link-requests");
};

const updateLinkRequests = (entity) => {
  return axios.patch("/applicants/link-request", entity);
};

const fetchApplicantMasterData = () => {
  return axios.get("/applicant-master/all");
};

/**
 * @memberOf module:ApplicantsData
 * @namespace api
 * @description api for Applicants Data
 */
export default {
  fetchApplicantsData,
  fetchApplicationStatusCount,
  fetchApplicationPositionCount,
  fetchApplicationEmploymentStatusCount,
  fetchFreshApplicationCount,
  fetchOpenJobPositions,
  fetchApplicantsDataByStatus,
  addRound,
  passLastRound,
  failLastRound,
  revertLastRound,
  cancelLastRound,
  shortlistApplicants,
  differApplicants,
  processApplicants,
  joinedApplicants,
  moveApplicantsToTalentPool,
  fetchCampusEmails,
  getRegisteredApplicantStatusCount,
  receiveUserMails,
  fetchAttachment,
  fetchUserNotifications,
  setUserNotificationSeen,
  fetchRoundSchedules,
  fetchMySchedule,
  fetchAllFollowUps,
  notAppearedApplicants,
  fetchAllSchedule,
  fetchAllLinkRequests,
  updateLinkRequests,
  fetchApplicantMasterData,
};
