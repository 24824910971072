<template>
  <div class="commonPadding">
    <div class="row mx-0">
      <div class="col-12 col-sm-6 pr-sm-2 mb-3">
        <label class="required">College or University </label>
        <input
          type="text"
          name="institute_name"
          key="institute_name"
          placeholder="ABC college"
          v-validate="'required|min:3|max:100'"
          :disabled="!isEditMode"
          :class="{ invalid: errors.first('institute_name') }"
          class="form-control"
          v-model="educationData.institute_name"
          data-vv-as="Institue Name"
        />
        <span
          v-if="errors.first('institute_name')"
          class="valid-feedback alert-danger"
          >{{ errors.first("institute_name") }}</span
        >
      </div>
      <div class="col-12 col-sm-6 pr-sm-2 mb-3">
        <label class="required">Degree </label>
        <input
          :disabled="!isEditMode"
          type="text"
          name="degree"
          key="degree"
          placeholder="BTECH"
          v-validate="'required|min:2|max:15'"
          data-vv-as="Degree"
          :class="{
            invalid: errors.first('degree'),
          }"
          class="form-control"
          v-model="educationData.degree"
        />
        <span
          v-if="errors.first('degree')"
          class="valid-feedback alert-danger"
          >{{ errors.first("degree") }}</span
        >
      </div>
      <div class="col-12 col-sm-6 pr-sm-2 mb-3">
        <label class="required">Specialization / Branch </label>
        <input
          :disabled="!isEditMode"
          type="text"
          name="stream"
          key="stream"
          placeholder="CSE,IT,ME"
          data-vv-as="Branch"
          v-validate="'required|min:2|max:15'"
          :class="{
            invalid: errors.first('stream'),
          }"
          class="form-control"
          v-model="educationData.stream"
        />
        <span
          v-if="errors.first('stream')"
          class="valid-feedback alert-danger"
          >{{ errors.first("stream") }}</span
        >
      </div>
      <div class="col-6 col-sm-6 pr-sm-2 mb-3">
        <label class="required">Start Date </label>
        <input
          :disabled="!isEditMode"
          type="date"
          name="start_date"
          key="start_date"
          ref="start_date"
          v-validate="'required'"
          :class="{
            invalid: errors.first('start_date'),
          }"
          class="form-control"
          v-model="educationData.start_date"
          data-vv-as="Start Date"
        />
        <span
          v-if="errors.first('start_date')"
          class="valid-feedback alert-danger"
          >{{ errors.first("start_date") }}</span
        >
      </div>
      <div class="col-6 col-sm-6 pr-sm-2 mb-3">
        <label class="required"> Expected End Date </label>
        <input
          :disabled="!isEditMode"
          type="date"
          name="end_date"
          key="end_date"
          v-validate="'required|after:start_date'"
          :class="{
            invalid: errors.first('end_date'),
          }"
          class="form-control"
          v-model="educationData.end_date"
          data-vv-as="End Date"
        />
        <span
          v-if="errors.first('end_date')"
          class="valid-feedback alert-danger"
          >{{ errors.first("end_date") }}</span
        >
      </div>
      <div class="col-12 col-sm-6 pr-sm-2 mb-3">
        <label class="required">Overall CGPA </label>
        <input
          :disabled="!isEditMode"
          type=""
          name="grade_points"
          key="grade_points"
          data-vv-as="Overall CGPA"
          v-validate="'required|decimal:2|between:0,10'"
          :class="{
            invalid: errors.first('grade_points'),
          }"
          class="form-control"
          v-model="educationData.grade_points"
        />
        <span
          v-if="errors.first('grade_points')"
          class="valid-feedback alert-danger"
          >{{ errors.first("grade_points") }}</span
        >
      </div>
      <div class="col-6 col-sm-3 pr-sm-2 mb-3">
        <label class="required">SSC (%) </label>
        <input
          :disabled="!isEditMode"
          type=""
          name="ssc_percentage"
          key="ssc_percentage"
          data-vv-as="SSC Percentage"
          v-validate="'required|decimal:2|between:0,100'"
          :class="{
            invalid: errors.first('ssc_percentage'),
          }"
          class="form-control"
          v-model="educationData.ssc_percentage"
        />
        <span
          v-if="errors.first('ssc_percentage')"
          class="valid-feedback-sm alert-danger"
          >{{ errors.first("ssc_percentage") }}</span
        >
      </div>
      <div class="col-6 col-sm-3 pr-sm-2 mb-3">
        <label class="required">HSC (%) </label>
        <input
          type=""
          :disabled="!isEditMode"
          name="hsc_percentage"
          key="hsc_percentage"
          data-vv-as="HSC Percentage"
          v-validate="'required|decimal:2|between:0,100'"
          :class="{
            invalid: errors.first('hsc_percentage'),
          }"
          class="form-control"
          v-model="educationData.hsc_percentage"
        />
        <span
          v-if="errors.first('hsc_percentage')"
          class="valid-feedback-sm alert-danger"
          >{{ errors.first("hsc_percentage") }}</span
        >
      </div>
      <div class="col-6 col-sm-3 pr-sm-2 mb-3">
        <label class="required">Graduation (%) </label>
        <input
          type=""
          data-vv-as="Graduation Percentage"
          name="grad_percentage"
          key="grad_percentage"
          :disabled="!isEditMode"
          v-validate="'required|decimal:2|between:0,100'"
          :class="{
            invalid: errors.first('grad_percentage'),
          }"
          class="form-control"
          v-model="educationData.grad_percentage"
        />
        <span
          v-if="errors.first('grad_percentage')"
          class="valid-feedback-sm alert-danger"
          >{{ errors.first("grad_percentage") }}</span
        >
      </div>
      <div
        class="col-6 col-sm-3 pr-sm-2 mb-3"
        title="Aggregate of SSC/HSC/Graduation"
      >
        <label class="required">Aggregate (%) </label>
        <input
          type=""
          :disabled="!isEditMode"
          name="aggregate_percentage"
          key="aggregate_percentage"
          data-vv-as="Aggregate Grades"
          v-validate="'required|decimal:2|between:0,100'"
          :class="{
            invalid: errors.first('aggregate_percentage'),
          }"
          class="form-control"
          v-model="educationData.aggregate_percentage"
        />
        <span
          v-if="errors.first('aggregate_percentage')"
          class="valid-feedback-sm alert-danger"
          >{{ errors.first("aggregate_percentage") }}</span
        >
      </div>

      <div v-for="index in 4" :key="index" class="col-6 col-sm-3 pr-sm-2 mb-3">
        <label class="required">Semester {{ index }} SGPA </label>
        <input
          :name="'semester' + index"
          :key="'semester' + index"
          :disabled="!isEditMode"
          v-validate="'required|decimal:2|between:0,10'"
          :class="{ invalid: errors.first('semester' + index) }"
          class="form-control"
          v-model="educationData['semester' + index]"
          data-vv-as="SGPA"
        />
        <span
          v-if="errors.first('semester' + index)"
          class="valid-feedback-sm alert-danger"
        >
          {{ errors.first("semester" + index) }}
        </span>
      </div>
      <div
        v-for="index in 4"
        :key="index + 4"
        class="col-6 col-sm-3 pr-sm-2 mb-3"
      >
        <label>Semester {{ index + 4 }} SGPA </label>
        <input
          :name="'semester' + (index + 4)"
          :key="'semester' + (index + 4)"
          :disabled="!isEditMode"
          v-validate="'decimal:2|between:0,10'"
          :class="{ invalid: errors.first('semester' + (index + 4)) }"
          class="form-control"
          v-model="educationData['semester' + (index + 4)]"
          data-vv-as="SGPA"
        />
        <span
          v-if="errors.first('semester' + (index + 4))"
          class="valid-feedback-sm alert-danger"
        >
          {{ errors.first("semester" + (index + 4)) }}
        </span>
      </div>
      <div class="col text-center">
        <div class="col" v-if="isEditMode">
          <b-button @click="cancelEdit" class="btn btn-dark"> Cancel </b-button>
          <b-button @click="handleSubmit" class="btn btn-cp ml-2">
            Submit
          </b-button>
        </div>
        <div class="col" v-else>
          <button
            type="button"
            class="btn btn-cp ml-2"
            @click="isEditMode = true"
          >
            Edit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      initialEducationData: {},
      isEditMode: false,
      educationData: {
        institute_name: "",
        start_date: "",
        end_date: "",
        semester1: "",
        semester2: "",
        semester3: "",
        semester4: "",
        semester5: "",
        semester6: "",
        semester7: "",
        semester8: "",
        aggregate_percentage: "",
        hsc_percentage: "",
        grad_percentage: "",
        ssc_percentage: "",
        stream: "",
        degree: "",
        grade_points: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      applicantDataFromStore: "$_applicant_master/getLoggedInApplicant",
    }),
  },

  watch: {
    applicantDataFromStore(val) {
      this.educationData = JSON.parse(JSON.stringify(val.education_details));

      this.initialEducationData = JSON.parse(
        JSON.stringify(this.educationData)
      );
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    this.$store.dispatch("$_applicant_master/fetchApplicantProfile");
  },
  methods: {
    cancelEdit() {
      this.isEditMode = false;
      this.educationData = JSON.parse(
        JSON.stringify(this.initialEducationData)
      );
      this.$validator.reset();
    },
    handleSubmit() {
      this.$validator.validateAll().then(() => {
        if (this.$validator.errors.items.length == 0) {
          for (let key of Object.keys(this.educationData)) {
            if (this.educationData[key] !== this.initialEducationData[key]) {
              this.isEditMode = false;
              this.$store.dispatch(
                "$_applicant_master/updateApplicantProfile",
                {
                  education_details: this.educationData,
                }
              );
            }
          }

          this.isEditMode = false;
        } else {
          this.$store.dispatch("openSnackbar", {
            type: "danger",
            message: "Please enter valid values!",
          });
        }
      });
    },
  },
};
</script>
