<template>
  <div class="row mx-0 commonPadding">
    <div class="col-12 col-sm-6 pr-sm-2 mb-3">
      <label class="required">Experience (yrs) </label>
      <input
        type="text"
        name="experience"
        key="experience"
        :disabled="!isEditMode"
        placeholder="E.g. 3"
        v-validate="'required|min_value:1|max_value:30'"
        :class="{ invalid: errors.first('experience') }"
        class="form-control"
        data-vv-as="Experience"
        v-model="professionalData.experience"
      />
      <span
        v-if="errors.first('experience')"
        class="valid-feedback alert-danger"
        >{{ errors.first("experience") }}</span
      >
    </div>
    <div class="col-12 col-sm-6 pr-sm-2 mb-3">
      <label class="required">Relevant Experience (yrs) </label>
      <input
        type="text"
        name="relevant_experience"
        key="relevant_experience"
        placeholder="E.g. 3"
        v-validate="'required|min_value:1|max_value:30'"
        :disabled="!isEditMode"
        :class="{ invalid: errors.first('relevant_experience') }"
        class="form-control"
        data-vv-as="Relevant Experience"
        v-model="professionalData.relevant_experience"
      />
      <span
        v-if="errors.first('relevant_experience')"
        class="valid-feedback alert-danger"
        >{{ errors.first("relevant_experience") }}</span
      >
    </div>
    <div
      class="col-12 col-sm-6 pr-sm-2 mb-3"
      v-if="!checkEmploymentAccess('Other')"
    >
      <label class="required">Current Role </label>
      <input
        type="text"
        name="current_role"
        key="current_role"
        :disabled="!isEditMode"
        placeholder="E.g. Software Engineer"
        v-validate="'required|min:3|max:100'"
        :class="{ invalid: errors.first('current_role') }"
        class="form-control"
        data-vv-as="Current Role"
        v-model="professionalData.current_role"
      />
      <span
        v-if="errors.first('current_role')"
        class="valid-feedback alert-danger"
        >{{ errors.first("current_role") }}</span
      >
    </div>
    <div
      class="col-6 col-sm-6 pr-sm-2 mb-3"
      v-if="!checkEmploymentAccess('Other')"
    >
      <label class="required">Current CTC (LPA) </label>
      <input
        type="text"
        name="current_ctc"
        key="current_ctc"
        :disabled="!isEditMode"
        placeholder="E.g. 10"
        v-validate="'required|min_value:0|max_value:100'"
        :class="{ invalid: errors.first('current_ctc') }"
        class="form-control"
        data-vv-as="Current CTC"
        v-model="professionalData.current_ctc"
      />
      <span
        v-if="errors.first('current_ctc')"
        class="valid-feedback alert-danger"
        >{{ errors.first("current_ctc") }}</span
      >
    </div>
    <div
      class="col-12 col-sm-6 pr-sm-2 mb-3"
      v-if="checkEmploymentAccess('Other')"
    >
      <label class="required">Previous Role </label>
      <input
        type="text"
        name="previous_role"
        key="previous_role"
        :disabled="!isEditMode"
        placeholder="E.g. Software Engineer"
        v-validate="'required|min:3|max:100'"
        :class="{ invalid: errors.first('previous_role') }"
        class="form-control"
        data-vv-as="Previous Role"
        v-model="professionalData.previous_role"
      />
      <span
        v-if="errors.first('previous_role')"
        class="valid-feedback alert-danger"
        >{{ errors.first("previous_role") }}</span
      >
    </div>
    <div
      class="col-6 col-sm-6 pr-sm-2 mb-3"
      v-if="checkEmploymentAccess('Other')"
    >
      <label class="required">Previous CTC (LPA) </label>
      <input
        type="text"
        name="previous_ctc"
        key="previous_ctc"
        :disabled="!isEditMode"
        placeholder="E.g. 10"
        v-validate="'required|min_value:0|max_value:100'"
        :class="{ invalid: errors.first('previous_ctc') }"
        class="form-control"
        data-vv-as="Previous CTC"
        v-model="professionalData.previous_ctc"
      />
      <span
        v-if="errors.first('previous_ctc')"
        class="valid-feedback alert-danger"
        >{{ errors.first("previous_ctc") }}</span
      >
    </div>
    <div class="col-6 col-sm-6 pr-sm-2 mb-3">
      <label class="required">Expected CTC (LPA) </label>
      <input
        type="text"
        name="expected_ctc"
        key="expected_ctc"
        :disabled="!isEditMode"
        placeholder="E.g. 5"
        v-validate="'required|min_value:2|max_value:20'"
        data-vv-as="Expected CTC"
        :class="{
          invalid: errors.first('expected_ctc'),
        }"
        class="form-control"
        v-model="professionalData.expected_ctc"
      />
      <span
        v-if="errors.first('expected_ctc')"
        class="valid-feedback alert-danger"
        >{{ errors.first("expected_ctc") }}</span
      >
    </div>
    <div
      class="col-12 col-sm-6 pr-sm-2 mb-3"
      v-if="!checkEmploymentAccess('Other')"
    >
      <label class="required">Notice Period (days) </label>
      <input
        type="text"
        name="notice_period"
        key="notice_period"
        :disabled="!isEditMode"
        placeholder="E.g. 5"
        v-validate="'required|min_value:1|max_value:365'"
        data-vv-as="Notice Period"
        :class="{
          invalid: errors.first('notice_period'),
        }"
        class="form-control"
        v-model="professionalData.notice_period"
      />
      <span
        v-if="errors.first('notice_period')"
        class="valid-feedback alert-danger"
        >{{ errors.first("notice_period") }}</span
      >
    </div>
    <div class="w-100"></div>
    <div class="col text-center">
      <div class="col" v-if="isEditMode">
        <b-button @click="cancelEdit" class="btn btn-dark"> Cancel </b-button>
        <b-button @click="handleSubmit" class="btn btn-cp ml-2">
          Submit
        </b-button>
      </div>
      <div class="col" v-else>
        <button
          type="button"
          class="btn btn-cp ml-2"
          @click="isEditMode = true"
        >
          Edit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isEditMode: false,
      initialProfessionalData: {},
      professionalData: {
        employment_status: null,
        previous_ctc: null,
        current_ctc: null,
        expected_ctc: null,
        current_role: null,
        previous_role: null,
        notice_period: null,
        experience: null,
        relevant_experience: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      applicantDataFromStore: "$_applicant_master/getLoggedInApplicant",
    }),
  },

  watch: {
    applicantDataFromStore(val) {
      this.professionalData = {
        employment_status: val.employment_status,
        previous_ctc: val.previous_ctc,
        current_ctc: val.current_ctc,
        expected_ctc: val.expected_ctc,
        current_role: val.current_role,
        previous_role: val.previous_role,
        notice_period: val.notice_period,
        experience: val.experience,
        relevant_experience: val.relevant_experience,
      };

      this.initialProfessionalData = JSON.parse(
        JSON.stringify(this.professionalData)
      );
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    this.$store.dispatch("$_applicant_master/fetchApplicantProfile");
  },
  methods: {
    checkEmploymentAccess(status) {
      return this.professionalData.employment_status === status;
    },

    cancelEdit() {
      this.isEditMode = false;
      this.professionalData = JSON.parse(
        JSON.stringify(this.initialProfessionalData)
      );
      this.$validator.reset();
    },

    handleSubmit() {
      this.$validator.validateAll().then(() => {
        if (
          this.professionalData.employment_status == "Freelancer" ||
          this.professionalData.employment_status == "Working"
        ) {
          this.$validator.errors.remove("previous_CTC");
          this.$validator.errors.remove("previous_role");
        } else if (this.professionalData.employment_status == "Other") {
          this.$validator.errors.remove("current_CTC");
          this.$validator.errors.remove("notice_period");
          this.$validator.errors.remove("current_role");
        }
        if (this.$validator.errors.items.length == 0) {
          const changedData = {};
          for (const key in this.professionalData) {
            if (
              this.professionalData[key] !== this.initialProfessionalData[key]
            ) {
              changedData[key] = this.professionalData[key];
            }
          }

          if (Object.keys(changedData).length === 0) {
            this.isEditMode = false;
            return;
          }

          this.isEditMode = false;
          this.$store.dispatch(
            "$_applicant_master/updateApplicantProfile",
            changedData
          );
        } else {
          this.$store.dispatch("openSnackbar", {
            type: "danger",
            message: "Please enter valid values!",
          });
        }
      });
    },
  },
};
</script>
