import VueCookie from "vue-cookie";
import userApi from "../../User/_api/index";
import store from "../../../store/index";
import { PROJECT_NAME } from "../../../config";

export const setLoggedInUser = () => {
  const user = VueCookie.get("user");
  if (user) {
    const userData = JSON.parse(user.substring(2));
    store.dispatch("user/setLoggedInUser", userData);
  }
};

export const setPermissions = async () => {
  try {
    const permissions = await userApi.getPermissions();
    store.dispatch("user/setPermissions", permissions.data.permissions);
  } catch (error) {
    store.dispatch("user/setPermissions", []);
  }
};

export const hasAccess = ({ permissions, checkAll }) => {
  return hasPermission({ permissions, checkAll });
};

export const hasPermission = ({ permissions, checkAll = true }) => {
  const checkMethod = checkAll ? "every" : "some";
  const userPerms = store.getters["user/permissions"];

  if (
    !userPerms ||
    !Object.keys(userPerms).length ||
    !permissions ||
    !permissions.length
  )
    return false;

  const allPermissionTitle = PROJECT_NAME + "_ALL-PERMISSION";

  const hasAllPermissions = permissions[checkMethod]((permission) => {
    if (!permission) return false;
    const p_title = [PROJECT_NAME, ...permission.title].join("_");

    if (userPerms[allPermissionTitle]) return true;
    return userPerms[p_title];
  });

  return hasAllPermissions;
};
