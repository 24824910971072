<template>
  <div class="commonPadding">
    <!-- -------------------------------------- 10TH MARKSHEET ------------------------------------- -->
    <div v-if="applicantData.employment_status === 'Fresher'">
      <div class="addDetailsContainer">
        <div class="addDetailsHeader">
          <div class="addDetailsImage">
            <b-icon icon="file-earmark" class="h3" aria-hidden="true"></b-icon>
          </div>
          <div class="addDetailItem">
            <div class="addDetailItemTitle">Add 10th Details</div>
            <div class="addDetailItemSubtitle">Upload your 10th Marksheet</div>
          </div>
        </div>
        <button
          @click="openDialog('sscMarksheetUpload', 'ssc')"
          type="button"
          class="btn btn-cp ml-2"
        >
          {{ sscButtonTitle }}
        </button>
      </div>
      <div id="sscMarksheetUpload" class="col-sm-12 col-12 pr-sm-2 mb-3">
        <template v-if="!applicantData.sscmarksheet">
          <file-upload-input
            ref="sscInput"
            id="sscId"
            name="file"
            :containerClasses="'col-12 pr-sm-2 mt-2'"
            :file-size="{ size: 5120, unit: 'MB' }"
            :file-extensions="['pdf']"
            :required="true"
            @fileSelected="sscMarksheetChanged($event)"
          />
          <div v-if="isSscMarksheetChanged" class="text-center">
            <button
              class="btn btn-cp ml-2"
              type="button"
              @click="handleSscMarksheetUpload"
            >
              Save
            </button>
          </div>
        </template>
        <!-- to update, applicant needs to first remove the previous upload and then upload the new one. -->
        <template v-else>
          <div class="row d-flex justify-content-evenly">
            <div class="col-7">
              <h4 class="float-right">
                Your 10th Marksheet:
                {{
                  applicantData.first_name + " " + applicantData.last_name
                }}.pdf
              </h4>
            </div>
            <div class="col-5">
              <b-icon
                icon="eye"
                aria-hidden="true"
                class="h5 m-2"
                @click="viewMarksheet('ssc')"
              ></b-icon>
              <b-icon
                icon="download"
                aria-hidden="true"
                class="h5 m-2"
                @click="downloadSscMarksheet()"
              ></b-icon>
              <b-icon
                icon="trash"
                aria-hidden="true"
                class="h5 m-2"
                style="color: red"
                data-toggle="modal"
                data-target="#deleteModalSscMarksheet"
              ></b-icon>
              <delete-modal
                :modal-id="'deleteModalSscMarksheet'"
                item="Marksheet"
                :on-delete="deleteSscMarksheet"
              ></delete-modal>
            </div>
          </div>
        </template>
      </div>
      <hr />
    </div>

    <!-- -------------------------------------- 12TH MARKSHEET ------------------------------------- -->
    <div v-if="applicantData.employment_status === 'Fresher'">
      <div class="addDetailsContainer">
        <div class="addDetailsHeader">
          <div class="addDetailsImage">
            <b-icon icon="file-earmark" class="h3" aria-hidden="true"></b-icon>
          </div>
          <div class="addDetailItem">
            <div class="addDetailItemTitle">Add 12th Details</div>
            <div class="addDetailItemSubtitle">Upload your 12th Marksheet</div>
          </div>
        </div>
        <button
          @click="openDialog('hsMarksheetUpload', 'hs')"
          type="button"
          class="btn btn-cp ml-2"
        >
          {{ hsButtonTitle }}
        </button>
      </div>
      <div id="hsMarksheetUpload" class="col-sm-12 col-12 pr-sm-2 mb-3">
        <template v-if="!applicantData.hsmarksheet">
          <file-upload-input
            ref="hsInput"
            id="hsId"
            name="file"
            :containerClasses="'col-12 pr-sm-2 mt-2'"
            :file-size="{ size: 5120, unit: 'MB' }"
            :file-extensions="['pdf']"
            :required="true"
            @fileSelected="hsMarksheetChanged($event)"
          />
          <div v-if="isHsMarksheetChanged" class="text-center">
            <button
              class="btn btn-cp ml-2"
              type="button"
              @click="handleHsMarksheetUpload"
            >
              Save
            </button>
          </div>
        </template>
        <!-- to update, applicant needs to first remove the previous upload and then upload the new one. -->
        <template v-else>
          <div class="row d-flex justify-content-evenly">
            <div class="col-7">
              <h4 class="float-right">
                Your 12th Marksheet:
                {{
                  applicantData.first_name + " " + applicantData.last_name
                }}.pdf
              </h4>
            </div>
            <div class="col-5">
              <b-icon
                icon="eye"
                aria-hidden="true"
                class="h5 m-2"
                @click="viewMarksheet('hs')"
              ></b-icon>
              <b-icon
                icon="download"
                aria-hidden="true"
                class="h5 m-2"
                @click="downloadHsMarksheet()"
              ></b-icon>
              <b-icon
                icon="trash"
                aria-hidden="true"
                class="h5 m-2"
                style="color: red"
                data-toggle="modal"
                data-target="#deleteModalHsMarksheet"
              ></b-icon>
              <delete-modal
                :modal-id="'deleteModalHsMarksheet'"
                item="Marksheet"
                :on-delete="deleteHsMarksheet"
              ></delete-modal>
            </div>
          </div>
        </template>
      </div>
      <hr />
    </div>

    <!-- ------------------------------------------ RESUME --------------------------------------------->
    <div class="addDetailsContainer">
      <div class="addDetailsHeader">
        <div class="addDetailsImage">
          <b-icon icon="file-earmark" class="h3" aria-hidden="true"></b-icon>
        </div>
        <div class="addDetailItem">
          <div class="addDetailItemTitle">Add Your Resume</div>
          <div class="addDetailItemSubtitle">Upload your resume here</div>
        </div>
      </div>
      <button
        @click="openDialog('resumeUpload', 'resume')"
        type="button"
        class="btn btn-cp ml-2"
      >
        {{ resumeButtonTitle }}
      </button>
    </div>
    <div id="resumeUpload" class="col-sm-12 col-12 pr-sm-2 mb-3">
      <template v-if="!applicantData.resume">
        <file-upload-input
          ref="resumeInput"
          id="resumeId"
          name="file"
          :containerClasses="'col-12 pr-sm-2 mt-2'"
          :file-size="{ size: 5120, unit: 'MB' }"
          :file-extensions="['pdf']"
          :required="true"
          @fileSelected="resumeChanged($event)"
        />
        <div v-if="isResumeChanged" class="text-center">
          <button
            class="btn btn-cp ml-2"
            type="button"
            @click="handleResumeUpload"
          >
            Save
          </button>
        </div>
      </template>
      <!-- to update, applicant needs to first remove the previous upload and then upload the new one. -->
      <template v-else>
        <div class="row d-flex justify-content-evenly">
          <div class="col-7">
            <h4 class="float-right">
              Your Resume:
              {{ applicantData.first_name + " " + applicantData.last_name }}.pdf
            </h4>
          </div>
          <div class="col-5">
            <b-icon
              icon="eye"
              aria-hidden="true"
              class="h5 m-2"
              @click="viewMarksheet('resume')"
            ></b-icon>
            <b-icon
              icon="download"
              aria-hidden="true"
              class="h5 m-2"
              @click="downloadResume()"
            ></b-icon>
            <b-icon
              icon="trash"
              aria-hidden="true"
              class="h5 m-2"
              style="color: red"
              data-toggle="modal"
              data-target="#deleteModalResume"
            ></b-icon>
            <delete-modal
              :modal-id="'deleteModalResume'"
              item="Resume"
              :on-delete="deleteResume"
            ></delete-modal>
          </div>
        </div>
      </template>
    </div>
    <hr />
    <pdf-modal
      :key="controlKey"
      v-if="modalUrl"
      modalId="view-marksheet"
      :url.sync="modalUrl"
      :title="modalTitle"
      :axios="applicantAxios"
    ></pdf-modal>
  </div>
</template>
<script>
import FileUploadInput from "../../../Common/_components/file-upload-input.vue";
import DeleteModal from "../delete-modal.vue";
import { mapGetters } from "vuex";
import PdfModal from "../../../Common/_components/pdf-modal.vue";
import applicantAxios from "../../_utils/axios";
import config from "../../../../config";
export default {
  props: {
    applicantData: {
      type: Object,
      default: null,
      required: true,
    },
    isApplicantLoggedIn: {
      type: Boolean,
      required: true,
      default: true,
    },
    isApplicantProfile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      config,
      applicantAxios,
      isSscMarksheetChanged: false,
      isHsMarksheetChanged: false,
      isResumeChanged: false,
      sscButtonTitle: "",
      hsButtonTitle: "",
      resumeButtonTitle: "",
      modalUrl: "",
      modalTitle: null,
      controlKey: 0,
    };
  },
  computed: {
    ...mapGetters({
      sscLink: "$_applicant_master/getSscMarksheetLink",
      hsLink: "$_applicant_master/getHsMarksheetLink",
      resumeLink: "$_applicant_master/getResumeLink",
    }),
  },
  components: {
    "delete-modal": DeleteModal,
    "file-upload-input": FileUploadInput,
    "pdf-modal": PdfModal,
  },
  mounted() {
    window.scrollTo(0, 0);
    if (this.applicantData.employment_status === "Fresher") {
      document.getElementById("sscMarksheetUpload").style.display = "none";
      document.getElementById("hsMarksheetUpload").style.display = "none";
    }
    document.getElementById("resumeUpload").style.display = "none";
  },
  created() {
    this.handleSscButtonTitle("close");
    this.handleHsButtonTitle("close");
    this.handleResumeButtonTitle("close");
    if (this.applicantData.sscmarksheet)
      this.$store.dispatch("$_applicant_master/getApplicantSscMarksheet");
    if (this.applicantData.hsmarksheet)
      this.$store.dispatch("$_applicant_master/getApplicantHsMarksheet");
    if (this.applicantData.resume)
      this.$store.dispatch("$_applicant_master/getApplicantResume");
  },
  methods: {
    checkExtension(fileName, allowedExtensions) {
      const extension = fileName.split(".").pop().toLowerCase();
      return allowedExtensions.includes(extension);
    },

    handleSscButtonTitle(action) {
      if (action == "open")
        this.sscButtonTitle = this.applicantData.sscmarksheet
          ? "Close"
          : "Cancel";
      else
        this.sscButtonTitle = this.applicantData.sscmarksheet ? "View" : "Add";
    },

    handleHsButtonTitle(action) {
      if (action == "open")
        this.hsButtonTitle = this.applicantData.hsmarksheet
          ? "Close"
          : "Cancel";
      else this.hsButtonTitle = this.applicantData.hsmarksheet ? "View" : "Add";
    },

    handleResumeButtonTitle(action) {
      if (action == "open")
        this.resumeButtonTitle = this.applicantData.resume ? "Close" : "Cancel";
      else this.resumeButtonTitle = this.applicantData.resume ? "View" : "Add";
    },

    viewMarksheet(type) {
      this.controlKey += 1;
      if (type == "hs") {
        this.modalTitle = `${this.applicantData.first_name} ${this.applicantData.last_name}-12th`;
        this.modalUrl = `${this.config.ROOT_API}/applicant-master/documents/view/hs`;
      }
      if (type == "ssc") {
        this.modalTitle = `${this.applicantData.first_name} ${this.applicantData.last_name}-10th`;
        this.modalUrl = `${this.config.ROOT_API}/applicant-master/documents/view/ssc`;
      }

      if (type == "resume") {
        this.modalTitle = `${this.applicantData.first_name} ${this.applicantData.last_name}-Resume`;
        this.modalUrl = `${this.config.ROOT_API}/applicant-master/documents/view/resume`;
      }
      this.$nextTick(() => {
        $("#view-marksheet").modal("show");
      });
    },

    openDialog(id, type) {
      let obj = document.getElementById(id);
      if (obj.style.display == "none") {
        obj.style.display = "block";
        if (type == "ssc") this.handleSscButtonTitle("open");
        else if (type == "hs") this.handleHsButtonTitle("open");
        else if (type == "resume") this.handleResumeButtonTitle("open");
      } else {
        obj.style.display = "none";
        if (type == "ssc") this.handleSscButtonTitle("close");
        else if (type == "hs") this.handleHsButtonTitle("close");
        else if (type == "resume") this.handleResumeButtonTitle("close");
      }
    },

    sscMarksheetChanged() {
      this.isSscMarksheetChanged = true;
      const uploadedFile = this.$refs.sscInput.selectedFiles;
      if (uploadedFile && uploadedFile.length > 0) {
        this.isSscMarksheetChanged = this.checkExtension(uploadedFile[0].name, [
          "pdf",
        ]);
      } else {
        this.isSscMarksheetChanged = false;
      }
    },

    handleSscMarksheetUpload() {
      const body = {};
      const marksheet = this.$refs.sscInput.selectedFiles;

      if (marksheet && marksheet.length > 0) {
        body.name =
          this.applicantData.first_name + " " + this.applicantData.last_name;
        body.marksheet = marksheet[0];
        this.$store.dispatch(
          "$_applicant_master/uploadApplicantSscMarksheet",
          body
        );
        this.sscButtonTitle = "Close";
      }
    },

    downloadSscMarksheet() {
      this.$store.dispatch("$_applicant_master/getApplicantSscMarksheet");
      const downloadLink = document.createElement("a");
      downloadLink.href = this.sscLink;
      downloadLink.target = "_blank";
      downloadLink.download = `${this.applicantData.first_name} ${this.applicantData.last_name}-10th.pdf`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },

    deleteSscMarksheet() {
      this.isSscMarksheetChanged = false;
      this.$store.dispatch("$_applicant_master/deleteApplicantSscMarksheet");
    },

    hsMarksheetChanged() {
      this.isHsMarksheetChanged = true;
      const uploadedFile = this.$refs.hsInput.selectedFiles;
      if (uploadedFile && uploadedFile.length > 0) {
        this.isHsMarksheetChanged = this.checkExtension(uploadedFile[0].name, [
          "pdf",
        ]);
      } else this.isHsMarksheetChanged = false;
    },

    handleHsMarksheetUpload() {
      const body = {};
      const marksheet = this.$refs.hsInput.selectedFiles;

      if (marksheet && marksheet.length > 0) {
        body.name = `${this.applicantData.first_name} ${this.applicantData.last_name}`;
        body.marksheet = marksheet[0];
        this.$store.dispatch(
          "$_applicant_master/uploadApplicantHsMarksheet",
          body
        );
        this.hsButtonTitle = "Close";
      }
    },

    downloadHsMarksheet() {
      this.$store.dispatch("$_applicant_master/getApplicantHsMarksheet");
      const downloadLink = document.createElement("a");
      downloadLink.href = this.hsLink;
      downloadLink.target = "_blank";
      downloadLink.download = `${this.applicantData.first_name} ${this.applicantData.last_name}-12th.pdf`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },

    deleteHsMarksheet() {
      this.isHsMarksheetChanged = false;
      this.$store.dispatch("$_applicant_master/deleteApplicantHsMarksheet");
    },

    resumeChanged() {
      this.isResumeChanged = true;
      const uploadedFile = this.$refs.resumeInput.selectedFiles;
      if (uploadedFile && uploadedFile.length > 0) {
        this.isResumeChanged = this.checkExtension(uploadedFile[0].name, [
          "pdf",
        ]);
      } else {
        this.isResumeChanged = false;
      }
    },

    handleResumeUpload() {
      const body = {};
      const resume = this.$refs.resumeInput.selectedFiles;

      if (resume && resume.length > 0) {
        body.name = `${this.applicantData.first_name} ${this.applicantData.last_name}`;
        body.resume = resume[0];
        this.$store.dispatch("$_applicant_master/uploadApplicantResume", body);
        this.resumeButtonTitle = "Close";
      }
    },

    deleteResume() {
      this.isResumeChanged = false;
      this.$store.dispatch("$_applicant_master/deleteApplicantResume");
    },

    downloadResume() {
      this.$store.dispatch("$_applicant_master/getApplicantResume");
      const downloadLink = document.createElement("a");
      downloadLink.href = this.resumeLink;
      downloadLink.target = "_blank";
      downloadLink.download = `${this.applicantData.first_name} ${this.applicantData.last_name}-resume.pdf`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
  },
};
</script>

<style scoped>
@import "../tabStyles.css";

.b-icon.bi {
  cursor: pointer !important;
}
</style>
