<template>
  <div class="content">
    <common-header
      :data="['home', 'campusDrive', 'campusSchedules', 'singleSchedule']"
      :componentName="
        eventData?.campusData.recruitment_session_name +
        ' ~ ' +
        eventData?.EventDetails.round_title +
        (eventData?.EventDetails.round_name === 'Written Test'
          ? ' (WT)'
          : ' (I)')
      "
      :componentTooltip="`
              <div class='d-flex flex-column align-items-start'>
                  <div class='my-1'>Recruitment: <strong>${eventData?.campusData.recruitment_session_name}</strong></div>
                  <div class='my-1'>Event: <strong>${eventData?.EventDetails.round_title}</strong></div>
                  <div class='my-1'>Type: <strong>${eventData?.EventDetails.round_name}</strong></div>
              </div>`"
    >
      <div class="text-right">
        <div class="d-none d-md-block">
          <p class="font-size-small mb-0">
            {{ formatDate(eventData?.EventDetails.round_schedule) }}
            -
            {{ formatDate(eventData?.EventDetails.round_schedule_end) }}
          </p>
          <p class="font-size-small font-weight-bold mb-0">
            Dependent Event -
            {{
              eventData?.dependentEventDetails
                ? eventData?.dependentEventDetails.round_title
                : "No Dependency"
            }}
          </p>
        </div>
        <div class="d-block d-md-none">
          <p class="font-size-small mb-0">
            {{ formatDate(eventData?.EventDetails.round_schedule) }} -
            {{ formatDate(eventData?.EventDetails.round_schedule_end) }}
          </p>
          <p class="font-size-small font-weight-bold mb-0">
            Dependent Event -
            {{
              eventData?.dependentEventDetails
                ? eventData?.dependentEventDetails.round_title
                : "No Dependency"
            }}
          </p>
        </div>
      </div>
    </common-header>
    <div class="container-fluid">
      <div v-if="hasAccess({ permissions: [this.BASE_PERMISSION.MENU] })">
        <div>
          <div>
            <div class="row mx-0">
              <div class="col-12 col-md-4 pr-md-2 px-0 border-md-right">
                <event-applications-list
                  :selectedStatus="selectedStatus"
                  ref="applicantList"
                ></event-applications-list>
              </div>
              <div
                class="col-12 col-md-4 px-0 px-md-2 border-md-right d-flex flex-column mt-4 mt-md-0"
                v-if="eventData"
              >
                <div
                  class="w-100 d-flex justify-content-between align-items-center"
                >
                  <div>
                    <p class="m-0 p-0">
                      <b class="mr-3">Slot range -</b>
                      {{
                        extractTime(eventData.EventDetails.round_schedule)
                      }}&nbsp;-&nbsp;
                      {{
                        extractTime(eventData.EventDetails.round_schedule_end)
                      }}
                    </p>
                  </div>
                  <div class="d-flex">
                    <button
                      v-if="
                        selectedSlotId &&
                        hasAccess({
                          permissions: [this.SLOT_PERMISSION.DELETE],
                        })
                      "
                      @click="deleteSlot"
                      class="btn btn-cp btn-sm mx-1"
                    >
                      Delete
                    </button>
                    <button
                      v-if="
                        hasAccess({
                          permissions: [this.SLOT_PERMISSION.CREATE],
                        })
                      "
                      @click="createSlot"
                      class="btn btn-sm btn-cp mx-1"
                    >
                      Add
                    </button>
                  </div>
                </div>
                <template
                  v-if="hasAccess({ permissions: [this.SLOT_PERMISSION.READ] })"
                >
                  <div
                    style="flex-grow: 1"
                    v-if="eventData && slotsList"
                    class="table-responsive m-0"
                  >
                    <table
                      class="table tabel-condensed"
                      aria-label="References"
                    >
                      <thead>
                        <th class="col-1 px-0">#</th>
                        <th class="col-2 text-nowrap">Date</th>
                        <th class="col-3 px-0 text-nowrap">Slot time</th>
                        <th class="col-2">Count</th>
                        <th class="col-3 th-interview">
                          {{
                            eventData.EventDetails.round_name == "Written Test"
                              ? "Scheduler"
                              : "Interviewer"
                          }}
                        </th>
                      </thead>
                      <tbody v-if="eventData && slotsList">
                        <tr
                          @click="setSelectedSlotId(slot.id)"
                          role="button"
                          :class="[
                            scheduleDateInvalid(slot.to_time)
                              ? 'slot-div-expired'
                              : selectedSlotId == slot.id
                              ? 'slot-div-active'
                              : 'slot-div-inactive',
                          ]"
                          class=""
                          v-for="(slot, index) in slotsList"
                          :key="index"
                        >
                          <td class="col-1 px-0 py-1">
                            <input
                              class="m-1"
                              v-model="selectedSlotId"
                              :value="slot.id"
                              type="radio"
                              :id="'slotDefaultCheck' + index"
                            />
                          </td>
                          <td class="col-2 text-nowrap py-1">
                            {{ extractDate(slot.from_time) }}
                          </td>
                          <td class="col-3 px-0 text-nowrap py-1">
                            {{
                              extractTime(slot.from_time) +
                              " - " +
                              extractTime(slot.to_time)
                            }}
                          </td>
                          <td class="col-2 py-1">
                            {{ slot.applicants_count }}
                          </td>
                          <td class="col-3 th-interview py-1">
                            <p
                              v-for="(interviewer, index2) in slot.interviewers"
                              :key="index2"
                              class="m-0 p-0"
                            >
                              {{ interviewer }}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </template>
              </div>
              <div class="col-12 col-md-4 px-0 mt-4 mt-md-0" v-if="eventData">
                <slot-page
                  :slotsList="slotsList"
                  :fetchEventSlots="fetchEventSlots"
                  :setDataSource="setDataSource"
                  :selectedSlotId="selectedSlotId"
                  :refreshGrid="refreshGrid"
                ></slot-page>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <slotModal
      v-if="hasAccess({ permissions: [SLOT_PERMISSION.CREATE] })"
      :fetchEventSlots="fetchEventSlots"
      :minTime="minTime"
      :maxTime="maxTime"
      :inPlaceAction="true"
    />
  </div>
</template>

<script>
import dragFromCell from "./drag-from-cell.vue";
import api from "../_api/index";
import slotModal from "./create-slot-modal.vue";
import eventApplicationsList from "./event-applications-list.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import SlotPage from "../_components/slot-page.vue";
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";

export default {
  components: {
    slotModal,
    eventApplicationsList,
    // eslint-disable-next-line vue/no-unused-components
    dragFromCell,
    SlotPage,
  },
  data: function () {
    return {
      slotsList: [],
      selectedSlotId: null,
      config: {
        format: "DD MMMM YYYY HH:mm",
        useCurrent: false,
        showClear: true,
        showClose: true,
        sideBySide: true,
        icons: {
          time: "fa fa-address-book",
          date: "fa fa-calendar",
          up: "fa fa-arrow-up",
          down: "fa fa-arrow-down",
          previous: "fa fa-chevron-left",
          next: "fa fa-chevron-right",
          today: "fa fa-calendar-check",
          clear: "fa fa-trash-alt",
          close: "fa fa-times-circle",
        },
      },
      selectedStatus: null,
      slotId: this.$route.query.slot,
      dataFetched: false,
    };
  },
  watch: {
    eventData() {
      this.fetchEventSlots(true);
    },
    selectedSlotId(value) {
      if (
        this.$route.name !== "scheduleEventSlot" ||
        (this.$route.name === "scheduleEventSlot" &&
          (this.$route.params.eventId != this.eventData.EventDetails.id ||
            this.$route.query.slot != value))
      ) {
        this.$router.replace({
          name: "scheduleEventSlot",
          params: { eventId: this.eventData.EventDetails.id },
          query: { slot: value },
        });
      }
    },
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS["CAMPUS-DRIVE"].SCHEDULE;
    },
    SLOT_PERMISSION() {
      return this.PERMISSIONS["CAMPUS-DRIVE"].SLOT;
    },
    ...mapGetters({
      eventData: "$_campus_recruitment/getEventData",
    }),
    minTime() {
      if (this.eventData)
        return this.extractTime(this.eventData.EventDetails.round_schedule);
      return null;
    },

    maxTime() {
      if (this.eventData)
        return this.extractTime(this.eventData.EventDetails.round_schedule_end);
      return null;
    },

    canSlotDelete() {
      const selectedSlotData = this.slotsList.find((slot) => {
        return slot.id == this.selectedSlotId;
      });
      return !this.scheduleDateInvalid(selectedSlotData.from_time);
    },
  },

  created() {},

  mounted() {
    this.getEventData();
  },

  methods: {
    getEventData() {
      if (
        !this.hasAccess({
          permissions: [this.PERMISSIONS["CAMPUS-DRIVE"].SCHEDULE.MENU],
        })
      )
        return;
      this.$store.dispatch(
        "$_campus_recruitment/fetchEventData",
        this.$route.params.eventId
      );
    },
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    fetchEventSlots(checkSlotQuery) {
      if (
        !this.hasAccess({
          permissions: [this.PERMISSIONS["CAMPUS-DRIVE"].SLOT.READ],
        })
      )
        return;
      const filter = `?roundId=${this.eventData.EventDetails.id}`;
      api
        .getSlots(filter)
        .then((res) => {
          this.slotsList = res.data;
          if (this.slotId && checkSlotQuery) {
            const slotData = this.slotsList.find(
              (slot) => slot.id == this.slotId
            );
            if (slotData) this.selectedSlotId = slotData.id;
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    setSelectedSlotId(id) {
      this.selectedSlotId = id;
    },
    /**
     * @memberof module:CampusRecruitment.schedule-event-slot
     * @description check if event is valid
     */
    scheduleDateInvalid(date) {
      return moment(date).isBefore(new Date());
    },

    /**
     * @memberof module:CampusRecruitment.components.schedule-event-slot
     * @description refresh applicants list
     */
    setDataSource() {
      this.$refs.applicantList.setDataSource();
    },

    /**
     * @memberof module:CampusRecruitment.schedule-event-slot
     * @description format date
     */
    formatDate(date) {
      return moment(date).format("MMM Do YYYY");
    },

    /**
     * @memberof module:CampusRecruitment.schedule-event-slot
     * @description extract date to display
     */
    extractDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    /**
     * @memberof module:CampusRecruitment.schedule-event-slot
     * @description extract time to display
     */
    extractTime(date) {
      return moment(date).format("HH:mm");
    },

    /**
     * @memberof module:CampusRecruitment.schedule-event-slot
     * @description show create slot modal
     */
    createSlot() {
      $("#addSlot").modal("show");
    },

    /**
     * @memberof module:CampusRecruitment.schedule-event-slot
     * @description refresh applicant list table data
     */
    refreshGrid() {
      this.$refs.applicantList.refreshGrid();
    },

    /**
     * @memberof module:CampusRecruitment.components.schedule-event-slot
     * @description delete selected slot
     */
    deleteSlot() {
      api
        .deleteSlot({ data: { id: this.selectedSlotId } })
        .then(() => {
          this.selectedSlotId = null;
          this.fetchEventSlots();
          this.$refs.applicantList.refreshGrid();

          this.$store.dispatch(
            "openSnackbar",
            { type: "success", message: "Selected slot has been deleted!" },
            { root: true }
          );
        })
        .catch((err) => {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: err.response.data.message
                ? err.response.data.message
                : "Something went wrong",
            },
            { root: true }
          );
        });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
table thead th {
  border-top: 0px !important;
  border-bottom: 0px !important;
}

tbody {
  display: block;
  height: calc(100vh - 225px);
  overflow: auto;
}

thead,
tbody tr {
  display: table;
  width: 100%;
}

.th-interview {
  min-width: 120px;
}

.font-size-small {
  font-size: 12px;
}
</style>
