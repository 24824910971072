import api from "../_api";
import Routers from "../../../router/index.js";
import campusRecruitmentApi from "../../CampusRecruitment/_api/index";
import { addSnackbarMessage } from "../../Common/_plugin/error-response";

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {String} id
 * @description Fetch Applicant profile
 */
const fetchApplicantData = (context, slug) => {
  api
    .fetchApplicantData(slug)
    .then((response) => {
      const data = response.data;
      context.commit("FETCH_APPLICANT_DATA", data);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem retrieving Applicants Data!", true);
    });
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {String} id
 * @description Fetch Applicant profile pic
 */
const fetchApplicantPic = (context, slug) => {
  api
    .fetchApplicantPic(slug)
    .then((response) => {
      const data = response.data.pic || null;
      context.commit("FETCH_APPLICANT_PIC", data);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem retrieving Applicants Pic!", true);
    });
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @description Fetch Job Profiles
 */
const fetchJobProfiles = (context) => {
  api
    .getJobProfiles()
    .then((response) => {
      const data = response.data;
      context.commit("FETCH_JOB_PROFILES", data);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem retrieving Applicants Data!, true");
    });
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {String} applicant_id
 * @description Fetch Round Data
 */
const fetchRoundDetails = (context, slug) => {
  api
    .fetchRoundDetails(slug)
    .then((response) => {
      const data = response.data;
      context.commit("FETCH_ROUND_DETAILS", data);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem retrieving ROUND DETAILS Data!", true);
    });
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {Object} roundData
 * @description Add Round and dispatch to mail
 */
const addRoundAndMail = (context, data) => {
  let roundData = data.data;
  api
    .addRound(roundData)
    .then((response) => {
      context.commit("ADD_ROUND", response.data[0]);
      response.data[0].applicantIterationMaster =
        context.getters.fetchApplicantData;
      const finalData = {
        data: [response.data[0]],
      };
      roundData.createdAt = response.data[0].createdAt;
      roundData.updatedAt = response.data[0].updatedAt;
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Round added successfully",
        },
        {
          root: true,
        }
      );
      Routers.push({
        name: "mailbox",
        query: {
          key: "Schedule Round",
          redirect: data.redirect,
        },
      });
      setTimeout(() => {
        setApplicantsData(context, finalData);
      }, 300);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem adding round! Try again later", true);
    });
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {Object} data
 * @description Add campus round and dispatch to mail
 */
const addCampusRoundAndMail = (context, cData) => {
  let data = cData.data;
  api
    .addRound(data.roundData)
    .then((response) => {
      data.roundData = response.data;
      context.commit("ADD_ROUND", response.data[0]);
      let finalData = {
        applicantData: [context.getters.fetchApplicantData],
        roundData: response.data,
        toBeMailed: {
          coordinatorEmails: data.toBeMailed,
        },
        type: data.type,
      };

      Routers.push({
        name: "mailbox",
        query: {
          key: "Schedule Round",
          redirect: cData.redirect,
        },
      });
      setTimeout(() => {
        setCampusData(context, finalData);
      }, 300);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem adding round! Try again later", true);
    });
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {Object} roundData
 * @description Add round
 */
const addRound = (context, dataVal) => {
  api
    .addRound(dataVal.round)
    .then((response) => {
      const data = response.data[0];
      data.created_by = dataVal.user.name;
      data.updated_by = dataVal.user.name;
      context.commit("ADD_ROUND", data);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem adding round! Try again later", true);
    });
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {Object} roundData
 * @description Set edit round details in modal
 */
const setModalRoundData = (context, roundData) => {
  context.commit("SET_MODAL_ROUND_DATA", roundData);
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {Object} roundData
 * @description Edit round for applicant
 */
const editRound = (context, data) => {
  let roundData = data.data;
  context.commit("SET_MODAL_ROUND_DATA", null);
  api
    .editRound(roundData)
    .then(() => {
      roundData.applicantIterationMaster = context.getters.fetchApplicantData;
      const finalData = {
        data: [roundData],
      };
      context.commit("EDIT_ROUND", roundData);
      Routers.push({
        name: "mailbox",
        query: {
          key: "Reschedule",
          redirect: data.redirect,
        },
      });
      setTimeout(() => {
        setApplicantsData(context, finalData);
      }, 300);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem editing round! Try again later", true);
    });
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {Object} data
 * @description Edit campus round for applicant
 */
const editCampusRound = (context, cData) => {
  let data = cData.data;
  context.commit("SET_MODAL_ROUND_DATA", null);
  api.editRound(data.roundData).then(() => {
    context.commit("EDIT_ROUND", data.roundData);
    Routers.push({
      name: "mailbox",
      query: {
        key: "Reschedule",
        redirect: cData.redirect,
      },
    });
  });
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {Object} roundData
 * @description Revert round for applicant
 */
const revertRound = (context, roundData) => {
  api
    .editRound(roundData)
    .then(() => {
      context.commit("REVERT_ROUND", roundData);
    })
    .catch((error) => {
      context.dispatch(
        "openSnackbar",
        {
          type: "danger",
          message: "Problem reverting round! Try again later",
        },
        {
          root: true,
        }
      );
      addSnackbarMessage(error, "Problem reverting round! Try again later");
    });
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {Object} roundData
 * @description Pass round for applicant
 */
const passRound = (context, roundData) => {
  api
    .editRound(roundData)
    .then(() => {
      context.commit("PASS_ROUND", roundData);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem passing round! Try again later", true);
    });
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {Object} roundData
 * @description Fail round for applicant
 */
const failRound = (context, roundData) => {
  api
    .editRound(roundData)
    .then(() => {
      context.commit("FAIL_ROUND", roundData);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem failing round! Try again later", true);
    });
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {Object} roundData
 * @description Schedule review for applicant
 */
const acceptedReview = (context, roundData) => {
  api
    .editRound(roundData)
    .then(() => {
      context.commit("SCHEDULED_REVIEW", roundData);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem scheduling review! Try again later");
    });
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {Object} roundData
 * @description Differed Review for applicant
 */
const rejectedReview = (context, roundData) => {
  api
    .editRound(roundData)
    .then(() => {
      context.commit("REJECTED_REVIEW", roundData);
    })
    .catch((error) => {
      addSnackbarMessage(
        error,
        "Problem rejecting review! Try again later",
        true
      );
    });
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {Object} roundData
 * @description Cancel round for applicant
 */
const cancelRound = (context, data) => {
  let roundData = data.data;
  context.commit("SET_MODAL_ROUND_DATA", null);
  api.cancelRound(roundData).then(() => {
    roundData.applicantIterationMaster = context.getters.fetchApplicantData;
    const finalData = {
      data: [roundData],
    };
    context.commit("CANCEL_ROUND", roundData);
    Routers.push({
      name: "mailbox",
      query: {
        key: "Schedule Cancel",
        redirect: data.redirect,
      },
    });
    setTimeout(() => {
      setApplicantsData(context, finalData);
    }, 300);
  });
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {Object} data
 * @description Cancel campus round for applicant
 */
const cancelCampusRound = (context, cData) => {
  let data = cData.data;
  api
    .cancelRound(data.roundData)
    .then(() => {
      let finalData = {
        applicantData: [context.getters.fetchApplicantData],
        toBeMailed: {
          coordinatorEmails: data.toBeMailed,
        },
        type: data.type,
      };
      context.commit("CANCEL_ROUND", data.roundData);
      Routers.push({
        name: "mailbox",
        query: {
          key: "Schedule Cancel",
          redirect: cData.redirect,
        },
      });
      setTimeout(() => {
        setCampusData(context, finalData);
      }, 300);
    })
    .catch((error) => {
      addSnackbarMessage(
        error,
        "Problem cancelling round! Try again later",
        true
      );
    });
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {Object} applicationData
 * @description Edit main application status of applicant which includes Shortlisted or Differed
 */
const editApplicationStatus = (context, data) => {
  let applicationData = data.data;
  api
    .editApplicationStatus(applicationData)
    .then(() => {
      const finalData = {
        data: [applicationData],
      };
      Routers.push({
        name: "mailbox",
        query: {
          key: "Application Status",
          redirect: data.redirect,
        },
      });
      setTimeout(() => {
        setApplicantsData(context, finalData);
      }, 300);
    })
    .catch((error) => {
      addSnackbarMessage(
        error,
        "Problem performing action! Try again later",
        true
      );
    });
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {Object} data
 * @description Edit main application status of campus applicant which includes Shortlisted or Differed
 */
const editCampusApplicationStatus = (context, cData) => {
  let data = cData.data;
  api
    .editApplicationStatus(data.applicantData)
    .then(() => {
      let finalData = {
        applicantData: [data.applicantData],
        toBeMailed: {
          coordinatorEmails: data.toBeMailed,
        },
        type: data.type,
      };
      Routers.push({
        name: "mailbox",
        query: {
          key: "Application Status",
          redirect: cData.redirect,
        },
      });
      setTimeout(() => {
        setCampusData(context, finalData);
      }, 300);
    })
    .catch((error) => {
      addSnackbarMessage(
        error,
        "Problem performing action! Try again later",
        true
      );
    });
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {Object} data
 * @description Send mail of current round status
 */
const sendRoundMail = (context, cData) => {
  let data = cData.data;
  data.applicantIterationMaster = context.getters.fetchApplicantData;
  const finalData = {
    data: [data],
  };
  Routers.push({
    name: "mailbox",
    query: {
      key: "Schedule Round",
      redirect: cData.redirect,
    },
  });
  setTimeout(() => {
    setApplicantsData(context, finalData);
  }, 300);
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {Object} interviewReview
 * @description Add interview review of round
 */
const addInterviewReview = (context, interviewReview) => {
  let updatedReview = {
    data: {
      id: interviewReview.id,
      ids: interviewReview.ids,
      interviewer_remarks:
        typeof interviewReview.interviewer_remarks === "object"
          ? JSON.stringify(interviewReview.interviewer_remarks)
          : interviewReview.interviewer_remarks,
      interviewer_id: interviewReview.interviewer_id,
    },
  };
  if (interviewReview.formVersionId) {
    updatedReview.data.formVersionId = interviewReview.formVersionId;
  }
  if (typeof interviewReview.interviewer_remarks === "object") {
    interviewReview.interviewer_remarks = JSON.stringify(
      interviewReview.interviewer_remarks
    );
  }
  api.addInterviewReview(updatedReview).then(
    () => {
      if (!interviewReview.applicantView) {
        interviewReview.callback && interviewReview.callback(); // pass callback function
      } else {
        context.commit("ADD_INTERVIEW_REVIEW", interviewReview);
      }
    },
    (error) => {
      addSnackbarMessage(error, "Not Allowed to Add Review!", true);

      if (!interviewReview.applicantView) {
        interviewReview.callback && interviewReview.callback(); // pass callback function
      }
    }
  );
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {Object} reviewRoundReview
 * @description Add review of Review round
 */
const addReviewRoundReview = (context, reviewRoundReview) => {
  let updatedReview = {
    data: {
      id: reviewRoundReview.id,
      reviewer_remarks:
        typeof reviewRoundReview.reviewer_remarks === "object"
          ? JSON.stringify(reviewRoundReview.reviewer_remarks)
          : reviewRoundReview.reviewer_remarks,
      reviewer_id: reviewRoundReview.reviewer_id,
    },
  };
  if (reviewRoundReview.formVersionId) {
    updatedReview.data.formVersionId = reviewRoundReview.formVersionId;
  }
  if (typeof reviewRoundReview.reviewer_remarks === "object") {
    reviewRoundReview.reviewer_remarks = JSON.stringify(
      reviewRoundReview.reviewer_remarks
    );
  }
  api.addReviewRoundReview(updatedReview).then(
    () => {
      if (!reviewRoundReview.applicantView) {
        reviewRoundReview.callback && reviewRoundReview.callback(); // pass callback function
      } else {
        context.commit("ADD_REVIEW_ROUND_REVIEW", reviewRoundReview);
      }
    },
    () => {
      problemRetrievingDataErr(context);
      if (!reviewRoundReview.applicantView) {
        reviewRoundReview.callback && reviewRoundReview.callback(); // pass callback function
      }
    }
  );
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {Object} data
 * @description Send mail of current round status for campus
 */
const sendCampusRoundMail = (context, cData) => {
  let data = cData;
  let finalData = {
    applicantData: [data.applicantData],
    roundData: [data.roundData],
    toBeMailed: {
      coordinatorEmails: data.toBeMailed,
    },
    type: data.type,
  };
  Routers.push({
    name: "mailbox",
    query: {
      key: "Schedule Round",
      redirect: cData.redirect,
    },
  });
  setTimeout(() => {
    setCampusData(context, finalData);
  }, 300);
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @description Fetch users with their details
 */
const usersDetails = (context) => {
  api
    .usersDetails()
    .then((response) => {
      const data = response.data;
      context.commit("USERS_DETAILS", data);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem retrieving Interviewers Data!", true);
    });
};

const setIDInState = (context, id) => {
  context.commit("SET_ID_IN_STATE", id);
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @description problem adding round error
 */
const problemAddingRoundErr = (context) => {
  context.dispatch(
    "openSnackbar",
    {
      type: "danger",
      message: "Problem adding round! Try again later",
    },
    {
      root: true,
    }
  );
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @description problem editing round error
 */
const problemEditingRoundErr = () => {
  /* eslint-disable-next-line no-undef*/
  patch(
    "openSnackbar",
    {
      type: "danger",
      message: "Problem editing round! Try again later",
    },
    {
      root: true,
    }
  );
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @description problem cancelling round error
 */
const problemCancellingRoundErr = (context) => {
  context.dispatch(
    "openSnackbar",
    {
      type: "danger",
      message: "Problem cancelling round! Try again later",
    },
    {
      root: true,
    }
  );
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @description problem performing action error
 */
const problemPerformingActionErr = (context) => {
  context.dispatch(
    "openSnackbar",
    {
      type: "danger",
      message: "Problem performing action! Try again later",
    },
    {
      root: true,
    }
  );
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @description problem retrieving applicants data error
 */
const problemRetrievingDataErr = (context) => {
  context.dispatch(
    "openSnackbar",
    {
      type: "danger",
      message: "Problem retrieving Applicants Data!",
    },
    {
      root: true,
    }
  );
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {Object} data
 * @description set applicants data
 */
const setApplicantsData = (context, data) => {
  context.dispatch("$_mailbox/setApplicantsData", data, {
    root: true,
  });
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {Object} data
 * @description set campus data
 */
const setCampusData = (context, data) => {
  context.dispatch("$_mailbox/setCampusData", data, {
    root: true,
  });
};

/**
 * @param {Object} context
 * @param {Object} commentData
 * @description Add comment
 */
const addComment = (context, commentData) => {
  api
    .addComment(commentData)
    .then((response) => {
      context.commit("ADD_COMMENT", response.data);
      context.dispatch(
        "openSnackbar",
        { type: "success", message: "Comment successfully added!" },
        { root: true }
      );
      return response.data;
    })
    .then((res) => {
      context.dispatch(
        "$_applicants_data/fetchUserNotifications",
        res.created_by,
        { root: true }
      );
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem adding comment!", true);
    });
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {Object} commentData
 * @description Add comment
 */
const getCommentListByApplicantId = (context, applicantId) => {
  api
    .getCommentListByApplicantId(applicantId)
    .then((response) => {
      const data = response.data;
      context.commit("FETCH_COMMENTS_BY_APPLICANT_ID", data);
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem fetching comment!", true);
    });
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {Object} commentData
 * @description Delete Comment using commentid
 */
const deleteCommentById = (context, commentData) => {
  api
    .deleteCommentById(commentData)
    .then(() => {
      api
        .getCommentListByApplicantId(commentData.item.applicantId)
        .then((response) => {
          const data = response.data;
          context.commit("FETCH_COMMENTS_BY_APPLICANT_ID", data);
          context.dispatch(
            "openSnackbar",
            { type: "success", message: "Comment successfully deleted!" },
            { root: true }
          );
          context.dispatch(
            "$_applicants_data/fetchUserNotifications",
            commentData.user_id,
            { root: true }
          );
        })
        .catch((error) => {
          console.log(error);
          addSnackbarMessage(error, "Problem fetching comment!", true);
        });
    })
    .catch(() => {
      return { success: false };
    });
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {Object} commentData
 * @description Update Comment using commentid
 */
const updateComment = (context, commentData) => {
  api
    .updateComment(commentData.data)
    .then(() => {
      api
        .getCommentListByApplicantId(commentData.data.applicantId)
        .then((response) => {
          const data = response.data;
          context.commit("FETCH_COMMENTS_BY_APPLICANT_ID", data);
          context.dispatch(
            "openSnackbar",
            { type: "success", message: "Comment updated successfully!" },
            { root: true }
          );
          context.dispatch(
            "$_applicants_data/fetchUserNotifications",
            commentData.user_id,
            { root: true }
          );
        })
        .catch((error) => {
          console.log(error);
          addSnackbarMessage(error, "Problem fetching comment!", true);
        });
    })
    .catch(() => {
      return { success: false };
    });
};

/**
 * @memberof module:ApplicantProfile.store.actions
 * @param {Object} context
 * @description Fetch claimed by details
 */
const claimedByDetails = (context, claimedById) => {
  api
    .getClaimedByDetails(claimedById)
    .then((response) => {
      const data = response.data;
      context.commit("CLAIMED_BY_DETAILS", data);
    })
    .catch((error) => {
      context.commit("CLAIMED_BY_DETAILS", null);
      addSnackbarMessage(error, "Problem retrieving claimedBy Data!", true);
    });
};

/**
 * @memberof module:ApplicantProfile.store.actions
 * @param {Object} context
 * @description update cliam_by value of applicant
 */
const updateClaimedById = (context, data) => {
  api
    .updateClaimedById(data.id, data.action)
    .then(() => {
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message:
            data.action == "claim"
              ? "Applicant has been claimed!"
              : "applicant has been unclaimed!",
        },
        {
          root: true,
        }
      );
      context.dispatch("fetchApplicantData", data.slug);
    })
    .catch((error) => {
      context.dispatch("fetchApplicantData", data.slug);
      addSnackbarMessage(error, "Problem updating claimedBy Data!", true);
    });
};

/**
 * @memberOf module:ApplicantProfile.store.actions
 * @param {Object} context
 * @param {String} id
 * @description Fetch Applicant profile
 */
const fetchApplicantRoundScore = (context, round_id) => {
  api
    .fetchApplicantRoundScore(round_id)
    .then((response) => {
      const moodleData = response.data;
      const roundDetails = context.state.roundDetails.map((round) => {
        if (round.id == moodleData.round_id) {
          return {
            ...round,
            score: moodleData.grade.graderaw,
            outOf: moodleData.grade.grademax,
          };
        }
        return round;
      });
      context.commit("FETCH_ROUND_DETAILS", roundDetails);
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Score added to the round locally",
        },
        {
          root: true,
        }
      );
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem retrieving Data!");
    });
};

const getMoodleInstanceList = (context) => {
  campusRecruitmentApi
    .getAllMoodleInstances()
    .then((res) => {
      context.commit("SET_MOODLE_INSTANCE_LIST", res.data);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem fetching Data!", true);
    });
};

const problemAddingFollowUpErr = (context) => {
  context.dispatch(
    "openSnackbar",
    {
      type: "danger",
      message: "Problem adding FollowUp! Try again later",
    },
    {
      root: true,
    }
  );
};

const getFollowUpDetails = (context, applicantId) => {
  api
    .getFollowUp(applicantId)
    .then((response) => {
      const data = response.data;
      context.commit("FOLLOW_UP_DETAILS", data);
    })
    .catch((error) => {
      context.commit("FOLLOW_UP_DETAILS", null);
      addSnackbarMessage(error, "Problem retrieving followUp Data!", true);
    });
};

const updateFollowUp = async (context, dataVal) => {
  dataVal = { id: dataVal.id, active_status: 1 };
  return api
    .updateFollowUp(dataVal)
    .then((response) => {
      const data = response.data;
      context.commit("UPDATE_FOLLOWUP", data);
    })
    .catch((error) => {
      addSnackbarMessage(
        error,
        "Problem adding FollowUp! Try again later",
        true
      );
    });
};

const addFollowUp = (context, dataVal) => {
  api
    .addFollowUp(dataVal)
    .then(async (response) => {
      const data = response.data;
      context.state.followUpDetails.length > 0 &&
        (await updateFollowUp(context, context.state.followUpDetails[0]));
      context.commit("ADD_FOLLOWUP", data);
    })
    .catch((error) => {
      addSnackbarMessage(
        error,
        "Problem adding FollowUp! Try again later",
        true
      );
    });
};

const addFollowUpAndMail = (context, data) => {
  let followupData = data.data;
  api
    .addFollowUp(followupData)
    .then(async (response) => {
      context.state.followUpDetails.length > 0 &&
        (await updateFollowUp(context, context.state.followUpDetails[0]));
      context.commit("ADD_FOLLOWUP", response.data);

      const finalData = {
        data: [context.getters.fetchApplicantData],
      };
      Routers.push({
        name: "mailbox",
        query: {
          key: "MailTo",
          redirect: data.redirect,
        },
      });
      setTimeout(() => {
        setApplicantsData(context, finalData);
      }, 300);
    })
    .catch((error) => {
      addSnackbarMessage(
        error,
        "Problem adding FollowUp! Try again later",
        true
      );
    });
};

/**
 * @memberOf module:ApplicantProfile
 * @namespace store.actions
 * @description actions for Applicant profile
 */
export default {
  fetchApplicantData,
  fetchRoundDetails,
  addRoundAndMail,
  addRound,
  setModalRoundData,
  editRound,
  revertRound,
  passRound,
  failRound,
  acceptedReview,
  rejectedReview,
  cancelRound,
  editApplicationStatus,
  sendRoundMail,
  addInterviewReview,
  addReviewRoundReview,
  addCampusRoundAndMail,
  editCampusRound,
  cancelCampusRound,
  sendCampusRoundMail,
  usersDetails,
  editCampusApplicationStatus,
  setIDInState,
  problemAddingRoundErr,
  problemEditingRoundErr,
  problemCancellingRoundErr,
  problemPerformingActionErr,
  problemRetrievingDataErr,
  setApplicantsData,
  setCampusData,
  addComment,
  getCommentListByApplicantId,
  fetchJobProfiles,
  deleteCommentById,
  updateComment,
  claimedByDetails,
  updateClaimedById,
  fetchApplicantRoundScore,
  getMoodleInstanceList,
  addFollowUp,
  addFollowUpAndMail,
  problemAddingFollowUpErr,
  getFollowUpDetails,
  updateFollowUp,
  fetchApplicantPic,
};
