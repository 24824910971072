<template>
  <div id="tracker">
    <div class="row mx-0">
      <div class="filter-box d-flex flex-wrap">
        <select
          class="form-control my-1 mr-1 width-auto"
          style="width: auto"
          v-model="filterOptions.employmentStatus"
          @change="onChangeEmploymentStatus"
        >
          <option selected value="P">Professionals</option>
          <option selected value="G">Freshers</option>
        </select>
        <select
          class="form-control my-1 ml-1 width-auto"
          style="width: auto"
          v-model="filterOptions.duration"
          @change="onChangeDuration"
        >
          <option selected value="l7d">In last 7 days</option>
          <option selected value="l15d">In last 15 days</option>
          <option selected value="l1m">In last 1 month</option>
          <option selected value="l3m">In last 3 months</option>
          <option selected value="l6m">In last 6 months</option>
          <option selected value="l1y">In last 1 year</option>
        </select>
      </div>
      <div style="width: 100%">
        <ag-grid-vue
          class="ag-theme-alpine ag-grid-style ag-grid-footer-style"
          :enableBrowserTooltips="true"
          :gridOptions="gridOptions"
          :rowData="rowData"
        >
        </ag-grid-vue>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { AgGridVue } from "ag-grid-vue";
import config from "../../../config";
import moment from "moment";
import { getFilterColumns } from "../../Common/_plugin/validation";

export default {
  data() {
    return {
      config,
      gridOptions: null,
      columnDefs: null,
      filterOptions: {
        employmentStatus: "P",
        fromDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
        toDate: moment().add(1, "days").format("YYYY-MM-DD"),
        duration: "l1m",
      },
    };
  },
  components: {
    "ag-grid-vue": AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    "profile-link": {
      template: `<router-link v-if="params.data" target='_blank' :to="{ name: 'applicantprofile', params: { slug: params.data.slug } }"><i class="fa fa-user-circle-o" aria-hidden="true" style="color:#832e5b"></i></router-link>`,
    },
  },
  methods: {
    createColumnDefs() {
      this.columnDefs = [
        {
          cellRenderer: "profile-link",
          width: 45,
          minWidth: 45,
          maxWidth: 45,
          suppressFilter: true,
          filter: false,
          valueGetter: "node.id",
          headerTooltip: "Profile Info",
          tooltipValueGetter: function (params) {
            if (params.data != undefined) {
              return "Profile of " + params.data.first_name;
            }
            return "";
          },
          pinned: "left",
          display_only: true,
        },
        {
          headerName: "Name",
          field: "first_name",
          valueGetter: function mergeLastNameFirstName(params) {
            if (params.data != undefined) {
              return params.data.first_name + " " + params.data.last_name;
            }
          },
          sortable: true,
          width: 200,
          minWidth: 200,
          pinned: "left",
          suppressHeaderMenuButton: true,
          filterParams: {
            newRowsAction: "keep",
          },
        },
        {
          headerName: "Joining Date",
          field: "expected_joining",
          sortable: true,
          width: 150,
          minWidth: 150,
          pinned: "left",
          suppressHeaderMenuButton: true,
          cellRenderer: (params) => {
            if (params.data.expected_joining)
              return moment(params.data.expected_joining).format("DD/MM/YYYY");
            else return "NA";
          },
          filter: "agDateColumnFilter",
        },
        {
          headerName: "Position",
          field: "position",
          sortable: true,
          width: 140,
          minWidth: 140,
          suppressHeaderMenuButton: true,
          pinned: "left",
        },
        {
          headerName: "Designation",
          field: "designation_offered",
          sortable: true,
          width: 140,
          minWidth: 140,
          suppressHeaderMenuButton: true,
        },
        {
          headerName: "Email",
          field: "email",
          sortable: true,
          width: 200,
          minWidth: 200,
          suppressHeaderMenuButton: true,
        },
        {
          headerName: "Mobile",
          field: "mobile_no",
          sortable: true,
          width: 100,
          minWidth: 100,
          suppressHeaderMenuButton: true,
        },
      ];
    },
    onChangeEmploymentStatus() {
      this.$store.dispatch(
        "$_applicants_data/fetchJoinedApplicants",
        this.filterOptions
      );
    },
    onChangeDuration() {
      switch (this.filterOptions.duration) {
        case "l7d":
          this.filterOptions.fromDate = moment()
            .subtract(7, "days")
            .format("YYYY-MM-DD");
          break;
        case "l15d":
          this.filterOptions.fromDate = moment()
            .subtract(15, "days")
            .format("YYYY-MM-DD");
          break;
        case "l3m":
          this.filterOptions.fromDate = moment()
            .subtract(3, "months")
            .format("YYYY-MM-DD");
          break;
        case "l6m":
          this.filterOptions.fromDate = moment()
            .subtract(6, "months")
            .format("YYYY-MM-DD");
          break;
        case "l1y":
          this.filterOptions.fromDate = moment()
            .subtract(12, "months")
            .format("YYYY-MM-DD");
          break;
        default:
          this.filterOptions.fromDate = moment()
            .subtract(1, "months")
            .format("YYYY-MM-DD");
          break;
      }

      this.$store.dispatch(
        "$_applicants_data/fetchJoinedApplicants",
        this.filterOptions
      );
    },
  },
  beforeMount() {
    this.createColumnDefs();
    this.gridOptions = {
      columnDefs: getFilterColumns(this.columnDefs, 1),
      suppressPropertyNamesCheck: true,
      enableServerSideFilter: true,
      enableCellTextSelection: true,
      enableColResize: true,
      defaultColDef: {
        floatingFilter: true,
        resizable: true,
        filterParams: {
          maxNumConditions: 1,
          buttons: ["reset"],
        },
        // set filtering on for all columns
        filter: true,
      },
      pagination: true,
      paginationPageSize: 10,
      paginationPageSizeSelector: false,

      context: {
        componentParent: this,
      },
    };
  },
  computed: {
    ...mapGetters({
      rowData: "$_applicants_data/fetchJoinedApplicantsData",
    }),
  },
  mounted() {
    this.$store.dispatch(
      "$_applicants_data/fetchJoinedApplicants",
      this.filterOptions
    );
  },
};
</script>
<style scoped>
.ag-grid-style {
  min-height: 500px;
  height: calc(100vh - 235px);
}
</style>
