<template>
  <div class="modal fade" id="editReviewModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title">
            {{ this.isEdit ? "Edit Review" : "Create Review" }}
          </h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body ws-normal">
          <form class="content-form" id="editReviewForm">
            <div class="form-group position-relative">
              <label class="control-label"
                >Review Name<span class="text-danger"> *</span></label
              >
              <input
                type="text"
                name="reviewName"
                class="form-control"
                v-validate="'required|regex:^[^-\s][a-zA-Z ]*$'"
                data-vv-as="Review Name"
                data-vv-validate-on="blur|input"
                v-model="data.review_name"
                maxlength="30"
                @blur="$v.data.review_name.$touch()"
              />
              <span
                v-if="errors.first('reviewName')"
                class="valid-feedback alert-danger"
                >{{ errors.first("reviewName") }}</span
              >
            </div>

            <div class="form-group position-relative">
              <label class="control-label"
                >Review Description <span class="text-danger"> *</span></label
              >
              <common-rich-text-box
                modalId="edit-review-summernote"
                :width="'100%'"
                @onKeyUp="onKeyUp"
                @onBlur="onBlur"
                @onFocus="onFocus"
              />
              <span
                class="valid-feedback alert-danger"
                v-if="isReviewDescTouched && isReviewDescValid"
              >
                Review Description is Required!
              </span>
            </div>

            <div class="form-group">
              <label class="control-label"
                >Status<span class="text-danger"> *</span></label
              >
              <select class="form-control" v-model="data.status">
                <option v-for="(status, index) in statusTypes" :key="index">
                  {{ status }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label class="control-label"
                >Category<span class="text-danger"> *</span></label
              >
              <select class="form-control" v-model="data.reviewCategoryId">
                <option
                  v-for="reviewCategory in reviewCategoriesWithReviews"
                  :key="reviewCategory.id"
                  :value="reviewCategory.id"
                  :selected="data.reviewCategoryId == reviewCategory.id"
                >
                  {{ reviewCategory.review_category_name }}
                </option>
              </select>
            </div>
          </form>
        </div>
        <div class="modal-footer text-center">
          <button
            :disabled="$v.data.$invalid"
            type="button"
            class="btn btn-cp"
            @click="updateReview"
            data-dismiss="modal"
          >
            {{ this.isEdit ? "Update" : "Create" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { review_status_type } from "../../Common/commonData";
import { required } from "vuelidate/lib/validators";
import CommonRichTextBox from "../../Common/_components/common-rich-text-box.vue";

export default {
  components: {
    CommonRichTextBox,
  },
  mounted() {
    this.statusTypes = review_status_type;
    $("#editReviewModal").on("show.bs.modal", () => {
      this.isEditReviewDone = false;
      this.statusTypes = review_status_type;
    });
    $("#editReviewModal").on("hidden.bs.modal", () => {
      this.isEditReviewDone = true;
      this.$store.dispatch("$_manage_reviews/editReviewModal", {});
      $("#edit-review-summernote").summernote("reset");
    });
  },
  data() {
    return {
      postData: {},
      data: {
        id: null,
        review_name: "",
        review_description: "",
        reviewCategoryId: null,
        status: "",
      },
      isEditReviewDone: false,
      isReviewDescTouched: false,
      isEdit: false,
      statusTypes: "",
      previousCatId: "",
    };
  },

  validations() {
    const data = {
      review_name: {
        required,
        reviewNameValidator: this.reviewNameValidator,
      },
    };
    return {
      data,
    };
  },
  computed: {
    ...mapGetters({
      reviewData: "$_manage_reviews/editReviewModal",
      reviewCategoriesWithReviews:
        "$_manage_reviews/reviewCategoriesWithReviews",
    }),

    isReviewDescValid() {
      return this.data.review_description !== "";
    },
  },

  watch: {
    reviewData(value) {
      if (value.reviewInfo) {
        this.isEdit = value.isEdit;
        this.data = this.getClone(value.reviewInfo);
        this.previousCatId = this.data.reviewCategoryId;
        $("#edit-review-summernote").summernote(
          "insertText",
          this.data.review_description
        );
      }
    },
  },
  methods: {
    onKeyUp(value) {
      this.data.review_description = value;
    },
    onBlur(value) {
      this.data.review_description = value;
      this.isReviewDescTouched = true;
    },

    getClone(value) {
      const to = {};
      if (Object.keys(value).length !== 0) {
        to.review_name = value.review_name;
        to.review_description = value.review_description;
        to.reviewCategoryId = value.reviewCategoryId;
        to.status = value.status;
        if (this.isEdit === true) {
          to.id = value.id;
        }
      }
      return to;
    },

    updateReview() {
      if (this.isEdit == true) {
        this.data.review_description = $(
          $("#edit-review-summernote").summernote("code")
        ).text();
        this.$store.dispatch("$_manage_reviews/editReview", {
          prevCatId: this.previousCatId,
          objectInfo: this.getClone(this.data),
        });
      } else {
        this.data.review_description = $(
          $("#edit-review-summernote").summernote("code")
        ).text();
        this.$store.dispatch(
          "$_manage_reviews/addReview",
          this.getClone(this.data)
        );
      }
    },

    reviewNameValidator(value) {
      return /^[^-\s][a-zA-Z ]*$/.test(value);
    },
  },
  beforeDestroy() {
    $("#editReviewModal").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>

<style scoped>
.ws-normal {
  white-space: normal;
}
</style>
