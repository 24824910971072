<template>
  <div>
    <applicant-form
      :userData="userData"
      :states="states"
      :employment_status="employment_status"
      :isJobIdValid="isJobIdValid"
      :isForEditData="isForEditData"
      :postUserData="postUserData"
    >
    </applicant-form>
    <common-modal
      :id="commonModalData.id"
      :modal-title="commonModalData.title"
      :modal-body="commonModalData.body"
      :show-cancel="commonModalData.showCancel"
      :modal-color="commonModalData.color"
      :modal-size-class="commonModalData.sizeClass"
      @actionPerformed="emitOnCurrent"
    />
  </div>
</template>

<script>
import ApplicantForm from "../../Common/_components/applicant-form";
import CommonModal from "../../Common/_components/modal";
import { mapGetters } from "vuex";
import { states, employment_status } from "../../Common/commonData";

/**
 * @memberof module:Home
 * @namespace components.application_form
 */
export default {
  /**
   * @memberof module:Home.components.application_form
   * @returns {Object} data object
   * @description data - returns data for current vue component
   */
  data() {
    return {
      jobId: this.$route.query.jobId,
      isJobIdValid: false, //props
      required_experience_min: null,
      required_experience_max: null,
      appliedJob: [], //prop
      states: [], //prop
      userData: {
        first_name: null,
        last_name: null,
        email: null,
        dob: null,
        gender: "Male",
        position: null,
        mobile_no: null,
        alternate_mobile_no: null,
        address_line_1: null,
        address_line_2: null,
        current_address_line_1: null,
        current_address_line_2: null,
        current_state: null,
        city: null,
        state: null,
        current_city: null,
        resume: null,
        project_links: [],
        previous_ctc: null,
        current_ctc: null,
        expected_ctc: null,
        current_role: null,
        previous_role: null,
        notice_period: null,
        experience: null,
        relevant_experience: null,
        reason_for_leaving: null,
        skills: [],
        employment_status: "Working",
        projectDetails: [],
        courseDetails: [],
        jobDetails: [],
        education_details: {
          institute_name: "",
          start_date: "",
          end_date: "",
          semester1: "",
          semester2: "",
          semester3: "",
          semester4: "",
          semester5: "",
          semester6: "",
          semester7: "",
          semester8: "",
          aggregate_percentage: "",
          hsc_percentage: "",
          grad_percentage: "",
          ssc_percentage: "",
          stream: "",
          degree: "",
          grade_points: "",
        },
        socialProfiles: {
          linkedin: "",
          github: "",
        },
        feedback: null,
        profile_picture: null,
        references: {
          ref1: {
            name: null,
            designation: null,
            contact_no: null,
          },
          ref2: {
            name: null,
            designation: null,
            contact_no: null,
          },
        },
      },
      employment_status: [], //props
      statusId: "",
      isForEditData: false,
    };
  },
  components: {
    ApplicantForm,
    CommonModal,
  },
  computed: {
    ...mapGetters({
      formSubmitStatus: "$_home/formSubmitStatus",
      jobDetails: "$_home/getJobById",
    }),
  },
  watch: {
    /**
     * @memberof module:Home.components.application_form
     * @param {Object} value new value
     * @description watch - handles response when application form is submitted
     */
    formSubmitStatus(value) {
      if (value.status === "SUCCESS") {
        const handler = () => {
          this.statusId = value;
          this.$router.push({ name: "home" });
        };
        this.openDialog(
          "commonModal",
          "Application form successfully submitted",
          "<h4>Kindly note down your Application ID for further communication!</h4><h5>Your Application ID is: <label style='border: 1px solid #ccc; border-radius:4px; height: 30px; padding: 5px 12px; line-height: 1.42857143'>" +
            value.uid +
            "</label></h5>",
          false
        )
          .then(handler)
          .catch(handler);
      } else if (value.status === "FAILURE") {
        this.$store.dispatch("openSnackbar", {
          type: "danger",
          message:
            value.error_data.response && value.error_data.response.data.message
              ? value.error_data.response.data.message
              : "There is problem uploading your data. Please, try again later.",
        });
      }
    },

    /**
     * @memberof module:Home.components.application_form
     * @param {Object} value new value
     * @description watch - calls when getting data of Current Openings from server
     */
    jobDetails() {
      this.setJobData();
    },
  },
  methods: {
    /**
     * @memberof module:Home.components.application_form
     * @description method - handles application form data when one submits
     */
    postUserData(applicantData) {
      if (applicantData.alternate_mobile_no === "")
        applicantData.alternate_mobile_no = null;

      if (this.$route.query.isFresher) {
        applicantData.employment_status = "Fresher";
      }
      this.$store.dispatch("$_home/postUserdata", applicantData);
    },
    setJobData() {
      if (this.jobDetails && this.jobDetails.status === "active") {
        this.appliedJob = this.jobDetails;
        this.isJobIdValid = true;
        this.required_experience_min = this.appliedJob.required_experience_min;
        this.required_experience_max = this.appliedJob.required_experience_max;
        this.userData.position = this.appliedJob.job_name;
        this.userData.jobId = this.appliedJob.id;
        this.appliedJob.required_skills.forEach((value) => {
          this.userData.skills.push(value);
        });
      } else {
        this.jobData = null; //inactive job
        this.$store.dispatch("openSnackbar", {
          type: "danger",
          message: "This job is not available now!",
        });
        this.$router.push({ name: "jobOpenings" });
      }
      if (!this.jobDetails) {
        this.$store.dispatch("openSnackbar", {
          type: "danger",
          message: "No such job exits!",
        });
        this.$router.push({ name: "jobOpenings" });
      }
    },
  },

  /**
   * @memberof module:Home.components.application_form
   * @description lifecycle method - dispatch request of fetching data job categories and jobs
   */
  created() {
    if (this.jobId) {
      this.$store.dispatch("$_home/fetchJobBySlug", this.jobId);
    }
    this.states = states;
    this.employment_status = employment_status;
  },

  /**
   * @memberof module:Home.components.application_form
   * @description lifecycle method - initializes css class and route validation
   */
  mounted() {
    if (this.$route.query.isFresher === "true") {
      this.userData.employment_status = "Fresher";
    } else if (!this.jobId || this.jobId == undefined) {
      this.$store.dispatch("openSnackbar", {
        type: "danger",
        message: "No such job available!",
      });
      this.$router.push({ name: "home" });
    }
  },
};
</script>

<style scoped>
.row {
  margin-left: 0px;
}

.m-b15 {
  margin-bottom: 15px;
}

.m-r15 {
  margin-right: 15px;
}

.v-recaptcha {
  margin-left: 17px;
  margin-bottom: 15px;
}

.file div {
  /* height: 100px;
  width: 100px; */
  border: 1px dotted grey;
}
</style>
