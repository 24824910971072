<template>
  <div
    class="modal fade"
    id="addSlot"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel">Create Slot</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" v-if="eventData">
          <form class="content-form">
            <div v-if="usersDetails">
              <div class="form-group">
                <label class="col-sm-3 control-label" htmlFor="roundType"
                  >{{
                    round.round_name == "Written Test"
                      ? "Schedulers"
                      : "Interviewers"
                  }}<span class="text-danger">*</span></label
                >
                <div class="col-sm-9">
                  <select
                    multiple
                    id="interviewerpickerSlot"
                    v-model="round.interviewers_id"
                    class="selectpicker"
                    data-live-search="true"
                    data-width="100%"
                  >
                    <option
                      v-for="(interviewer, index) in usersDetails"
                      :key="index"
                      :value="interviewer.user_id"
                    >
                      {{ interviewer.first_name }} {{ interviewer.last_name }}
                    </option>
                  </select>
                  <p
                    v-if="
                      !$v.round.interviewers_id.required &&
                      $v.round.interviewers_id.$error
                    "
                    class="alert alert-danger"
                  >
                    This field is required
                  </p>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" htmlFor="date">Date</label>
              <div class="col-sm-9">
                <date-picker
                  autocomplete="off"
                  v-model="round.date"
                  id="datePicker"
                  :config="minMaxConfig"
                  :minDate="
                    new Date(
                      'Mon Nov 07 2022 10:00:00 GMT+0530 (India Standard Time)'
                    )
                  "
                  @dp-hide="datePickerRequired"
                ></date-picker>
                <p v-if="datePickerFlag" class="alert alert-danger mt-2 p-1">
                  This field is required
                </p>
              </div>
            </div>
            <div class="form-group">
              <div class="col-sm-9 d-flex">
                <div class="w-100 pr-2">
                  <label class="ml-1 control-label" htmlFor="startTime"
                    >Start time (min-{{ minTime }})</label
                  >
                  <date-picker
                    autocomplete="off"
                    v-model="round.start_time"
                    :config="newConfigTime"
                    @dp-hide="timePickerStartRequired"
                  ></date-picker>
                  <h6
                    v-if="timePickerStartFlag"
                    class="alert alert-danger mt-2 p-1"
                  >
                    Required
                  </h6>
                </div>
                <div class="w-100 pl-2">
                  <label class="ml-1 control-label" htmlFor="endTime"
                    >End time (max-{{ maxTime }})</label
                  >
                  <date-picker
                    autocomplete="off"
                    v-model="round.end_time"
                    :config="newConfigTime"
                    @dp-hide="timePickerEndRequired"
                  ></date-picker>
                  <h6
                    v-if="timePickerEndFlag"
                    class="alert alert-danger mt-2 p-1"
                  >
                    Required
                  </h6>
                </div>
              </div>
              <div class="col-sm-9">
                <h6
                  v-if="timePickerGreaterFlag"
                  class="alert alert-danger mt-2 px-1 py-1"
                >
                  End time should be greater than start time
                </h6>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-9 control-label" htmlFor="slotsM"
                >create multiple slots</label
              >
              <div class="col-sm-9 input-group-sm">
                <input
                  v-model="round.slots_count"
                  class="form-control"
                  type="number"
                />
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-dark" data-dismiss="modal">
            <strong>Cancel</strong>
          </button>
          <button
            :disabled="
              !round.start_time ||
              !round.end_time ||
              !round.date ||
              round.end_time <= round.start_time ||
              round.interviewers_id.length === 0
            "
            type="button"
            class="btn btn-cp ml-2"
            @click="generateSlot"
          >
            <i class="fa fa-calendar fa-lg" aria-hidden="true"></i>
            Create Slot
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "../_api/index";
import datePicker from "vue-bootstrap-datetimepicker";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
/**
 * @memberOf module:ApplicantProfile
 * @namespace components.add_round_modal
 * @description Add round modal component
 */
export default {
  props: ["minDate", "maxDate", "minTime", "maxTime", "fetchEventSlots"],
  data() {
    return {
      round: {
        date: null,
        to_time: null,
        slots_count: 1,
        start_time: null,
        end_time: null,
        round_name: "",
        isMailed: false,
        round_result: null,
        interviewers_id: [],
      },
      interviewers: [101, 102, 103],
      reviewers: [101, 102, 103],
      config: {
        format: "DD MMMM YYYY",
        useCurrent: false,
        showClear: false,
        showClose: false,
        sideBySide: false,
      },
      configTime: {
        format: "HH:mm",
        useCurrent: false,
        showClear: false,
        showClose: false,
        sideBySide: false,
      },
      datePickerFlag: false,
      timePickerGreaterFlag: false,
      timePickerEndFlag: false,
      timePickerStartFlag: false,
      datePickerEndTimeFlag: false,
      isCoordinator: false,
      isStudent: false,
      interviewerChecker: false,
      isInterviewersTouched: false,
    };
  },
  components: {
    datePicker,
  },
  computed: {
    /**
     * @memberof module:CampusRecruitment.components.create-slot-modal
     * @description generate time picker range validation
     */
    newConfigTime() {
      if (!this.eventData) {
        return this.configTime;
      } else {
        const startDate = this.eventData.EventDetails.round_schedule;
        const endDate = this.eventData.EventDetails.round_schedule_end;
        const startDateHour = moment(startDate).hour();
        const startDateMinute = moment(startDate).minute();
        const endDateHour = moment(endDate).hour();
        const endDateMinute = moment(endDate).minute();
        const currentMin = moment();
        const currentMax = moment();
        currentMin
          .set({ h: startDateHour, m: startDateMinute })
          .subtract(1, "minutes");
        currentMax.set({ h: endDateHour, m: endDateMinute });
        const newConfig = {
          ...this.configTime,
          minDate: new Date(currentMin.format()),
          maxDate: new Date(currentMax.format()),
        };
        return newConfig;
      }
    },

    /**
     * @memberof module:CampusRecruitment.components.create-slot-modal
     * @description generate date picker range
     */
    minMaxConfig() {
      if (!this.eventData) {
        return this.config;
      } else {
        let passingDate = "";
        const startDate = this.eventData.EventDetails.round_schedule;
        moment(startDate).diff(moment(), "days");
        if (!moment(startDate).isBefore(new Date(), "day")) {
          passingDate = startDate;
        } else {
          passingDate = new Date().toISOString();
        }
        const newConfig = {
          ...this.config,
          enabledDates: this.getDatesBetween(
            passingDate,
            this.eventData.EventDetails.round_schedule_end
          ),
        };
        return newConfig;
      }
    },
    ...mapGetters({
      eventData: "$_campus_recruitment/getEventData",
      campusMails: "$_applicants_data/fetchCampusMails",
      usersDetails: "$_applicant_profile/fetchUsersDetails",
      id: "$_applicant_profile/fetchID",
    }),
  },
  watch: {
    "eventData.EventDetails"(value) {
      if (value) {
        this.round.round_name = value.round_name;
      }
    },

    /**
     * @memberof module:CampusRecruitment.components.create-slot-modal
     * @param {String} value
     * @description Marks if the round is interview round
     */
    "round.round_name"(value) {
      if (value === "Interview") {
        this.interviewerChecker = true;
      }
    },
    "round.start_time"() {},
    "round.end_time"(value) {
      if (value) {
        if (value > this.round.start_time) this.timePickerGreaterFlag = false;
        else this.timePickerGreaterFlag = true;
      }
    },
  },
  validations() {
    const round = {
      round_schedule: {
        required,
      },
    };
    round.interviewers_id = {
      required,
    };

    return {
      round,
    };
  },
  methods: {
    /**
     * @memberof module:CampusRecruitment.components.create-slot-modal
     * @description generate Dates between given two dates
     */
    getDatesBetween(startDate, endDate) {
      startDate = new Date(startDate);
      endDate = new Date(endDate);

      let currentDate = new Date(startDate.getTime());
      const dates = [];
      while (currentDate <= endDate) {
        dates.push(new Date(currentDate));
        const newDate = new Date(currentDate);
        newDate.setDate(newDate.getDate() + 1);
        currentDate = newDate;
      }
      return dates;
    },

    /**
     * @memberof module:CampusRecruitment.components.create-slot-modal
     * @description create slot for event
     */
    generateSlot() {
      api
        .createSlot({
          from_time: new Date(
            new Date(this.round.date + " " + this.round.start_time)
          ),
          to_time: new Date(
            new Date(this.round.date + " " + this.round.end_time)
          ),
          roundId: this.eventData.EventDetails.id,
          interviewerId: this.round.interviewers_id,
          slots_count:
            this.round.slots_count && this.round.slots_count != 0
              ? this.round.slots_count
              : 1,
        })
        .then(() => {
          this.fetchEventSlots();
          $("#addSlot").modal("hide");
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "success",
              message: "Slots has been created!",
            },
            {
              root: true,
            }
          );
        })
        .catch((err) => {
          console.log("err", err);
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: err.response.data.message
                ? err.response.data.message
                : "Something went wrong",
            },
            {
              root: true,
            }
          );
        });
    },

    /**
     * @memberof module:CampusRecruitment.components.create-slot-modal
     * @description Required validation for date picker
     */
    datePickerRequired() {
      if (!this.round.date) {
        this.datePickerFlag = true;
      } else {
        this.datePickerFlag = false;
      }
    },

    /**
     * @memberof module:CampusRecruitment.components.create-slot-modal
     * @description Required validation for date picker
     */
    datePickerToRequired() {
      if (!this.round.to_time) {
        this.datePickerTimeFlag = true;
      } else {
        this.datePickerTimeFlag = false;
      }
    },

    /**
     * @memberof module:CampusRecruitment.components.create-slot-modal
     * @description Required validation for date picker
     */
    timePickerStartRequired() {
      if (!this.round.start_time) {
        this.timePickerStartFlag = true;
      } else {
        this.timePickerStartFlag = false;
      }
    },

    /**
     * @memberof module:CampusRecruitment.components.create-slot-modal
     * @description Required validation for date picker
     */
    timePickerEndRequired() {
      if (!this.round.end_time) {
        this.timePickerEndFlag = true;
      } else {
        this.timePickerEndFlag = false;
      }
    },
    getUsers() {
      this.$store.dispatch("$_applicant_profile/usersDetails");
    },
  },
  mounted() {
    $("#addSlot").on("hidden.bs.modal", () => {
      this.round.date = null;
      this.round.slots_count = 1;
      this.round.interviewers_id = [];
      this.round.start_time = null;
      this.round.end_time = null;
      this.datePickerFlag = false;
      this.timePickerGreaterFlag = false;
      this.timePickerEndFlag = false;
      this.timePickerStartFlag = false;
      this.datePickerEndTimeFlag = false;
      this.interviewerChecker = false;
      this.isInterviewersTouched = false;
    });

    $("#interviewerpickerSlot").on("hidden.bs.select", () => {
      this.isInterviewersTouched = true;
    });

    $("#addSlot").on("show.bs.modal", () => {
      $("#interviewerpickerSlot")
        .selectpicker("destroy")
        .attr(
          "title",
          this.round.round_name == "Written Test"
            ? "Select Schedulers"
            : "Select interviewers"
        )
        .selectpicker("render");
    });
  },

  created() {
    this.getUsers();
  },

  updated() {
    if (this.interviewerChecker) {
      let interviewpicker = document.getElementById("interviewerpickerSlot");
      if (interviewpicker != null) {
        $("#interviewerpickerSlot")
          .selectpicker("destroy")
          .attr(
            "title",
            this.round.round_name == "Written Test"
              ? "Select Schedulers"
              : "Select Interviewers"
          )
          .selectpicker("render");
      }
      this.interviewerChecker = false;
    }
  },
  beforeDestroy() {
    $("#addSlot").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>

<style>
.bootstrap-datetimepicker-widget table td:not(.disabled),
.bootstrap-datetimepicker-widget table td:not(.disabled):hover {
  color: #007bff !important;
  margin: 10px;
}
</style>
