import { ARRAY_FILTER_PARAMS } from "../../Common/_utils/filter-params-constants";

export const COMMON_DATA_COL_DEFS = [
  {
    cellRenderer: "profile-link",
    width: 30,
    minWidth: 30,
    maxWidth: 30,
    suppressFilter: true,
    filter: false,
    valueGetter: "node.id",
    headerTooltip: "Profile Info",
    tooltipValueGetter: function (params) {
      if (params.data != undefined) {
        return "Profile of " + params.data.first_name;
      }
      return "";
    },
    pinned: "left",
    display_only: true,
    cellStyle: { textAlign: "center", padding: 0 },
  },
  {
    cellRenderer: "slot-applicant-action",
    headerName: "Actions",
    filter: false,
    width: 160,
    minWidth: 160,
    maxWidth: 160,
    suppressHeaderMenuButton: true,
    pinned: "left",
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    display_only: true,
  },
  {
    headerName: "Event Status",
    sortable: true,
    width: 150,
    minWidth: 150,
    suppressHeaderMenuButton: true,
    pinned: "left",
    valueGetter: function getRoundStatus(params) {
      if (params.data?.roundData?.length > 0) {
        const status =
          params.data.roundData[0].round_result == "Pending"
            ? "Scheduled"
            : params.data.roundData[0].round_result;
        return params.data.roundData[0].round_name + " " + status;
      } else {
        return "";
      }
    },
    display_only: true,
  },
  {
    headerName: "Name",
    field: "first_name",
    valueGetter: function mergeLastNameFirstName(params) {
      if (params.data != undefined) {
        return `${params.data.first_name} ${params.data.last_name}`;
      }
    },
    sortable: true,
    width: 200,
    minWidth: 200,
    // pinned: "left",
    suppressHeaderMenuButton: true,
    filterParams: {
      newRowsAction: "keep",
    },
  },
  {
    headerName: "Email",
    field: "email",
    sortable: true,
    width: 200,
    minWidth: 200,
    suppressHeaderMenuButton: true,
  },
  {
    headerName: "Mobile",
    field: "mobile_no",
    sortable: true,
    width: 100,
    minWidth: 100,
    suppressHeaderMenuButton: true,
    display_only: true,
  },
  {
    headerName: "Application Status",
    field: "application_status",
    sortable: true,
    filter: true,
    width: 150,
    minWidth: 150,
    suppressHeaderMenuButton: true,
    display_only: true,
  },
  {
    headerName: "Application Date",
    field: "createdAt",
    width: 200,
    minWidth: 200,
    sortable: true,
    valueGetter: function dateFormat(params) {
      if (params.data != undefined) {
        let dt = new Date(params.data.createdAt);
        return dt.toLocaleDateString().toString();
      }
    },
    // pinned: "left",
    filter: "agDateColumnFilter",
    suppressHeaderMenuButton: true,
    display_only: true,
  },

  {
    headerName: "Applicant",
    field: "status",
    sortable: true,
    width: 100,
    minWidth: 100,
    suppressHeaderMenuButton: true,
    display_only: true,
  },
  {
    headerName: "Emp. Status",
    field: "employment_status",
    sortable: true,
    width: 100,
    minWidth: 100,
    suppressHeaderMenuButton: true,
    display_only: true,
  },
  {
    headerName: "Skills",
    field: "skills",
    sortable: true,
    width: 170,
    minWidth: 170,
    suppressHeaderMenuButton: true,
    filterParams: {
      filterOptions: ARRAY_FILTER_PARAMS,
    },
    display_only: true,
  },
  {
    headerName: "DOB",
    field: "dob",
    sortable: true,
    width: 150,
    minWidth: 150,
    suppressHeaderMenuButton: true,
    valueGetter: function dateFormat(params) {
      if (params.data != undefined) {
        let dt = new Date(params.data.dob);
        return dt.toLocaleDateString().toString();
      }
    },
    filter: "agDateColumnFilter",
    display_only: true,
  },
  {
    headerName: "Gender",
    field: "gender",
    sortable: true,
    width: 100,
    minWidth: 100,
    suppressHeaderMenuButton: true,
    display_only: true,
  },
  {
    headerName: "College Name",
    field: "college_name",
    sortable: true,
    width: 200,
    minWidth: 200,
    display_only: true,
  },
  {
    headerName: "Username",
    sortable: true,
    width: 150,
    minWidth: 150,
    suppressHeaderMenuButton: true,
    valueGetter: function getRoundStatus(params) {
      if (params.data?.roundData?.length > 0) {
        const moodleData =
          params.data.roundData[0].hasMoodle?.moodle_user_master;
        if (moodleData) return moodleData.moodle_username;
      }
      return "";
    },
  },
  {
    headerName: "Passsword",
    sortable: true,
    width: 150,
    minWidth: 150,
    suppressHeaderMenuButton: true,
    valueGetter: function getRoundStatus(params) {
      if (params.data?.roundData?.length > 0) {
        const moodleData =
          params.data.roundData[0].hasMoodle?.moodle_user_master;
        if (moodleData) return moodleData.moodle_password;
      }
      return "";
    },
  },
];
