<template>
  <router-view></router-view>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  created() {
    this.$store.dispatch("$_applicant_master/fetchApplicantApplications");
  },
  data() {
    return {
      data: {
        email: "",
        password: "",
      },
    };
  },

  computed: {
    ...mapGetters({
      applications: "$_applicant_master/getApplicantApplications",
    }),
  },
};
</script>
