const SET_SYSTEM_CONFIGS = (state, data) => {
  state.systemConfigs = data;
};

const SET_PUBLIC_SYSTEM_CONFIGS_OBJECT = (state, data) => {
  state.publicSystemConfigsObject = data;
};

const SET_SYSTEM_CONFIGS_OBJECT = (state, data) => {
  state.systemConfigsObject = data;
};

export default {
  SET_SYSTEM_CONFIGS,
  SET_SYSTEM_CONFIGS_OBJECT,
  SET_PUBLIC_SYSTEM_CONFIGS_OBJECT,
};
