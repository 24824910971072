<template>
  <div class="row mx-0">
    <div class="col-12 mb-4 px-0">
      <div class="form-group position-relative">
        <label :for="id" class="control-label" :class="labelClasses"
          >{{ label }}
          <span class="text-danger" v-if="isRequired"> *</span></label
        >
        <input :id="id" class="form-control" data-role="tagsinput" />
        <p
          class="valid-feedback alert-danger"
          v-if="isRequired && isTagsInputTouched && !isTagsInputValid"
        >
          A valid value is required for this field.
        </p>
        <p
          class="alert-sm alert-danger"
          v-else-if="isTagsInputTouched && !isTagsInputValid"
        >
          Please enter a valid value for this field.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @memberof module:Common
 * @namespace tags_input_v2
 */
export default {
  props: {
    id: [String],
    label: {
      type: String,
      required: true,
    },
    labelClasses: {
      type: String,
      required: false,
    },
    setValues: {
      type: Array,
      required: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    finished: {
      type: Boolean,
      required: false,
    },
    validationType: {
      type: String,
      required: false,
    },
  },

  /**
   * @memberof module:Common.tags_input_v2
   * @returns {Object} data object
   * @description data - returns data for current vue component
   */
  data() {
    return {
      values: [],
      oldSetValues: [],
      isTagsInputTouched: false,
    };
  },

  /**
   * @memberof module:Common.tags_input_v2
   * @description lifecycle method - initializes bs-tagsinput
   */
  mounted() {
    const tagsInputId = "#" + this.id;
    $(tagsInputId).tagsinput({});
    $(tagsInputId)
      .tagsinput("input")
      .blur(() => {
        this.isTagsInputTouched = true;
        this.updateValues();
      });

    this.setValuesModified();

    $(".bootstrap-tagsinput").addClass("bs-tagsinput");
  },
  computed: {
    /**
     * @memberof module:Common.tags_input_v2
     * @description computed - required validation for tags input
     */
    isTagsInputValid: function () {
      if (this.validationType) {
        if (!this.isRequired && this.values?.length == 0) return true;
        switch (this.validationType) {
          case "email":
            return (
              this.values &&
              this.values.length > 0 &&
              this.values.every((value) => this.isValidEmail(value))
            );
          case "number":
            return (
              this.values &&
              this.values.length > 0 &&
              this.values.every((value) => this.isValidNumber(value))
            );
          case "string":
            return (
              this.values &&
              this.values.length > 0 &&
              this.values.every((value) => this.isValidString(value))
            );
          default:
            return false;
        }
      } else {
        return this.values !== undefined && this.values.length > 0;
      }
    },
  },
  watch: {
    /**
     * @memberof module:Common.tags_input_v2
     * @param {Array} value values to be set in tags input
     * @description watch - sets value passed from parent component and removes them before other update data comes
     */
    setValues: function (value) {
      if (this.finished === false && this.values !== value) {
        const tagsInputId = "#" + this.id;
        $(tagsInputId).tagsinput("removeAll");
      }
      this.setValuesModified();
    },
  },
  methods: {
    /**
     * @memberof module:Common.tags_input_v2
     * @returns {Array} filtered Array containing of newly added values only
     * @description method - filters array by removing already added values
     */
    getValuesToBeAdded() {
      if (this.oldSetValues.length === 0) return this.setValues;

      return this.setValues.filter(
        (value) => !this.oldSetValues.includes(value)
      );
    },

    /**
     * @memberof module:Common.tags_input_v2
     * @description method - adds the values to tags input
     */
    setValuesModified() {
      const tagsInputId = "#" + this.id;

      if (this.setValues !== undefined && this.setValues.length) {
        const valuesToBeAdded = this.getValuesToBeAdded();
        valuesToBeAdded.forEach((val) => {
          $(tagsInputId).tagsinput("add", val);
        });
        this.updateValues();
        this.oldSetValues = this.setValues;
      }
    },

    /**
     * @memberof module:Common.tags_input_v2
     * @description method - emits valuesUpdated event when values are changed
     */
    updateValues() {
      const tagsInputId = "#" + this.id;

      this.values = $(tagsInputId).tagsinput("items");
      this.$emit("valuesUpdated", {
        values: this.values,
        isValid: this.isTagsInputValid,
      });
    },
    isValidEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    isValidNumber(value) {
      return !isNaN(value) && typeof value === "number";
    },
    isValidString(value) {
      return typeof value === "string";
    },
  },
};
</script>

<style>
.bs-tagsinput {
  min-width: 100% !important;
  white-space: normal !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
}
.valid-feedback {
  top: 20px !important;
}
.invalid {
  border: red;
}
</style>
