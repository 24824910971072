import { ARRAY_FILTER_PARAMS } from "../../Common/_utils/filter-params-constants";
export const COMMON_DATA_COL_DEFS = [
  {
    cellRenderer: "profile-link",
    width: 35,
    minWidth: 35,
    maxWidth: 35,
    suppressFilter: true,
    filter: false,
    valueGetter: "node.id",
    headerTooltip: "Profile Info",
    tooltipValueGetter: function (params) {
      if (params.data != undefined) {
        return "Profile of " + params.data.first_name;
      }
      return "";
    },
    pinned: "left",
    cellStyle: { textAlign: "center", padding: 0 },
    display_only: true,
  },
  {
    cellRenderer: "edit-profile-data",
    width: 35,
    minWidth: 35,
    maxWidth: 35,
    suppressFilter: true,
    filter: false,
    valueGetter: "node.id",
    headerTooltip: "Edit Apllicant Data",
    pinned: "left",
    display_only: true,
    cellStyle: { textAlign: "center", padding: 0 },
  },
  {
    cellRenderer: "resolve-link",
    width: 35,
    minWidth: 35,
    maxWidth: 35,
    suppressFilter: true,
    filter: false,
    headerTooltip: "Profile Info",
    valueGetter: "node.id",
    tooltipValueGetter: function (params) {
      if (params.data != undefined) {
        if (params.data.hasDuplicate.length != 0) {
          return "Resolve Duplicate for " + params.data.first_name;
        }
      }
    },
    pinned: "left",
    display_only: true,
    cellStyle: { textAlign: "center", padding: 0 },
  },
  {
    headerName: "Name",
    field: "first_name",
    valueGetter: function mergeLastNameFirstName(params) {
      if (params.data != undefined) {
        return params.data.first_name + " " + params.data.last_name;
      }
    },
    sortable: true,
    width: 200,
    minWidth: 200,
    checkboxSelection: true,
    pinned: "left",
    suppressHeaderMenuButton: true,
    filterParams: {
      newRowsAction: "keep",
    },
  },
  {
    headerName: "Application Date",
    field: "createdAt",
    width: 200,
    minWidth: 200,
    sortable: true,
    valueGetter: function dateFormat(params) {
      if (params.data != undefined) {
        let dt = new Date(params.data.createdAt);
        return dt.toLocaleDateString().toString();
      }
    },
    pinned: "left",
    filter: "agDateColumnFilter",
    suppressHeaderMenuButton: true,
  },
  {
    headerName: "Notice Period",
    field: "notice_period",
    sortable: true,
    width: 100,
    filter: "agNumberColumnFilter",
    pinned: "left",
    minWidth: 100,
    suppressHeaderMenuButton: true,
  },
  {
    headerName: "Claimed By",
    field: "claimed_by",
    width: 150,
    pinned: "left",
    minWidth: 150,
    suppressHeaderMenuButton: true,
    valueGetter: function dateFormat(params) {
      if (params.data) {
        return params.data.claimed_by_displayName;
      }
    },
  },
  {
    headerName: "Experience",
    field: "experience",
    sortable: true,
    width: 100,
    filter: "agNumberColumnFilter",
    minWidth: 100,
    suppressHeaderMenuButton: true,
  },
  {
    headerName: "Application Status",
    field: "application_status",
    sortable: true,
    width: 150,
    minWidth: 150,
    suppressHeaderMenuButton: true,
  },
  {
    headerName: "Position",
    field: "position",
    sortable: true,
    width: 200,
    minWidth: 200,
    suppressHeaderMenuButton: true,
  },
  {
    headerName: "Email",
    field: "email",
    sortable: true,
    width: 200,
    minWidth: 200,
    suppressHeaderMenuButton: true,
  },
  {
    headerName: "Mobile",
    field: "mobile_no",
    sortable: true,
    width: 150,
    minWidth: 150,
    suppressHeaderMenuButton: true,
  },
  {
    headerName: "Applicant",
    field: "status",
    sortable: true,
    width: 100,
    minWidth: 100,
    suppressHeaderMenuButton: true,
  },
  {
    headerName: "Emp. Status",
    field: "employment_status",
    sortable: true,
    width: 100,
    minWidth: 100,
    suppressHeaderMenuButton: true,
  },
  {
    headerName: "Skills",
    field: "skills",
    sortable: true,
    width: 170,
    minWidth: 170,
    suppressHeaderMenuButton: true,
    filterParams: {
      filterOptions: ARRAY_FILTER_PARAMS,
    },
  },
  {
    headerName: "DOB",
    field: "dob",
    sortable: true,
    width: 150,
    minWidth: 150,
    suppressHeaderMenuButton: true,
    valueGetter: function dateFormat(params) {
      if (params.data != undefined) {
        let dt = new Date(params.data.dob);
        return dt.toLocaleDateString().toString();
      }
    },
    filter: "agDateColumnFilter",
  },
  {
    headerName: "Gender",
    field: "gender",
    sortable: true,
    width: 100,
    minWidth: 100,
    suppressHeaderMenuButton: true,
  },
  {
    headerName: "Alt.Mobile",
    field: "alternate_mobile_no",
    sortable: true,
    width: 100,
    minWidth: 100,
    suppressHeaderMenuButton: true,
  },
  {
    field: "current_city",
    sortable: true,
    width: 110,
    minWidth: 110,
    suppressHeaderMenuButton: true,
  },
  {
    headerName: "Home Town",
    sortable: true,
    field: "city",
    width: 110,
    minWidth: 110,
    suppressHeaderMenuButton: true,
  },
  {
    headerName: "Expected Joining",
    field: "expected_joining",
    sortable: true,
    width: 150,
    minWidth: 150,
    suppressHeaderMenuButton: true,
    valueGetter: function dateFormat(params) {
      if (params.data !== undefined && params.data.expected_joining) {
        let dt = new Date(params.data.expected_joining);
        return dt.toLocaleDateString().toString();
      } else return "NA";
    },
    filter: "agDateColumnFilter",
  },
  {
    headerName: "College Name",
    field: "college_name",
    sortable: true,
    minWidth: 200,
  },
];
