<template>
  <div class="commonPadding">
    <div>
      <div class="col-sm-12 col-12 pr-sm-2 mb-3">
        <label>Profile Pic </label>
        <div class="my-2 text-center">
          <img
            v-if="personalData.profile_pic !== null"
            :src="'data:image/jpeg;base64,' + profilePic"
            alt="Profile Picture"
            class="mx-auto d-block rounded-circle"
            style="width: 200px; height: 200px; object-fit: cover"
          />
          <img
            v-if="personalData.profile_pic === null && !imagePreview"
            :src="avatar"
            class="mx-auto d-block"
            alt="Profile Picture"
          />
          <img
            v-if="
              imagePreview &&
              isProfilePicSelected &&
              personalData.profile_pic === null
            "
            :src="imagePreview"
            style="width: 200px; height: 200px; object-fit: cover"
            class="mx-auto d-block rounded-circle"
            alt="Profile Picture"
          />
        </div>
        <div v-if="personalData.profile_pic !== null" class="my-2 text-center">
          <button
            type="button"
            class="btn btn-cp ml-2 mx-2"
            @click="deleteImage"
          >
            Remove Image
          </button>
        </div>
        <div class="text-center" v-if="personalData.profile_pic === null">
          <file-upload-input
            ref="profilePicInput"
            :id="profilePicId"
            name="file"
            :containerClasses="'col-12 pr-sm-2 mt-2'"
            :file-size="{ size: 5120, unit: 'MB' }"
            :file-extensions="['png', 'jpg', 'jpeg']"
            @fileSelected="profilePicChanged($event)"
          />
          <button
            v-if="
              imagePreview &&
              isProfilePicSelected &&
              personalData.profile_pic === null
            "
            class="btn btn-cp ml-2"
            type="button"
            @click="handleProfilePicUpload"
          >
            Save
          </button>
        </div>
      </div>
      <hr />
      <div class="row mx-0">
        <div class="col-sm-6 col-12 pr-sm-2 mb-3">
          <label class="required">First Name </label>
          <input
            type="text"
            name="first_name"
            key="first_name"
            placeholder="Enter First Name"
            v-validate="'required|alpha_spaces|min:2|max:50'"
            :disabled="!isEditMode"
            :class="{ invalid: errors.first('first_name') }"
            class="form-control"
            v-model="personalData.first_name"
          />
          <span
            v-if="errors.first('first_name')"
            class="valid-feedback alert-danger"
            >{{ errors.first("first_name") }}</span
          >
        </div>
        <div class="col-sm-6 col-12 pr-sm-2 mb-3">
          <label class="required">Last Name </label>
          <input
            type="text"
            name="last_name"
            key="last_name"
            placeholder="Enter Last Name"
            v-validate="'required|alpha_spaces|min:2|max:50'"
            :disabled="!isEditMode"
            :class="{ invalid: errors.first('last_name') }"
            class="form-control"
            v-model="personalData.last_name"
          />
          <span
            v-if="errors.first('last_name')"
            class="valid-feedback alert-danger"
            >{{ errors.first("last_name") }}</span
          >
        </div>
      </div>
      <div class="row mx-0">
        <div class="col-sm-6 col-12 pr-sm-2 mb-3">
          <label class="required">Email </label>
          <input
            type="text"
            name="email"
            key="email"
            placeholder="Enter Email"
            v-validate="'required|email'"
            disabled
            :class="{ invalid: errors.first('email') }"
            class="form-control"
            :value="personalData.email"
          />
          <span
            v-if="errors.first('email')"
            class="valid-feedback alert-danger"
            >{{ errors.first("email") }}</span
          >
        </div>
        <div class="col-sm-6 col-12 pr-sm-2 mb-3">
          <label class="required">Secondary Email </label>
          <input
            type="text"
            name="secondary_email"
            key="secondary_email"
            placeholder="Enter Secondary Email"
            v-validate="'required|email'"
            :class="{ invalid: errors.first('secondary_email') }"
            class="form-control"
            v-model="personalData.secondary_email"
            :disabled="!isEditMode"
          />
          <span
            v-if="errors.first('secondary_email')"
            class="valid-feedback alert-danger"
            >{{ errors.first("secondary_email") }}</span
          >
        </div>
        <div class="col-sm-6 col-12 pr-sm-2 mb-3">
          <label class="required">Mobile Number </label>
          <input
            type="text"
            name="mobile_number"
            key="mobile_number"
            placeholder="Enter Mobile Number"
            v-validate="'required|numeric|length:10'"
            :disabled="!isEditMode"
            :class="{ invalid: errors.first('mobile_number') }"
            class="form-control"
            v-model="personalData.mobile_no"
          />
          <span
            v-if="errors.first('mobile_number')"
            class="valid-feedback alert-danger"
            >{{ errors.first("mobile_number") }}</span
          >
        </div>
        <div class="col-sm-6 col-12 pr-sm-2 mb-3">
          <label>Alternative Mobile Number </label>
          <input
            type="text"
            name="alternative_mobile_number"
            key="alternative_mobile_number"
            placeholder="Enter Alternative Mobile Number"
            v-validate="
              `numeric|length:10|sameAlternateNo:${personalData.mobile_no}`
            "
            :disabled="!isEditMode"
            :class="{
              invalid: errors.first('alternative_mobile_number'),
            }"
            class="form-control"
            v-model="personalData.alternate_mobile_no"
          />
          <span
            v-if="errors.first('alternative_mobile_number')"
            class="valid-feedback alert-danger"
            >{{ errors.first("alternative_mobile_number") }}</span
          >
        </div>
      </div>
      <div class="row mx-0">
        <div class="col-12 col-sm-6 pr-sm-2 mb-3">
          <label class="required"> Date of Birth </label>
          <input
            :disabled="!isEditMode"
            type="date"
            name="date_of_birth"
            key="date_of_birth"
            data-vv-as="DOB"
            v-validate="
              `required|beforeDate:${new Date(
                new Date().getFullYear() - 18,
                new Date().getMonth(),
                new Date().getDate()
              )}`
            "
            :class="{
              'invalid-border': errors.first('date_of_birth'),
            }"
            class="form-control"
            v-model="personalData.dob"
          />
          <span
            v-if="errors.first('date_of_birth')"
            class="valid-feedback alert-danger"
            style="top: 20px"
            >{{ errors.first("date_of_birth") }}</span
          >
        </div>

        <div class="col-sm-6 col pr-sm-2 mb-3">
          <label class="d-block required">Gender </label>
          <div class="form-check-inline">
            <label class="form-check-label">
              <input
                type="radio"
                class="form-check-input"
                value="Male"
                v-model="personalData.gender"
                :disabled="!isEditMode"
                v-validate="'required'"
                name="gender"
                key="gender"
              />
              Male
            </label>
          </div>
          <div class="form-check-inline">
            <label class="form-check-label">
              <input
                type="radio"
                class="form-check-input"
                value="Female"
                v-model="personalData.gender"
                :disabled="!isEditMode"
                name="gender"
              />
              Female
            </label>
          </div>
          <div class="form-check-inline">
            <label class="form-check-label">
              <input
                type="radio"
                class="form-check-input"
                value="Other"
                v-model="personalData.gender"
                :disabled="!isEditMode"
                name="gender"
              />
              Other
            </label>
          </div>
          <span
            v-if="errors.first('gender')"
            class="valid-feedback no-lable alert-danger"
            >{{ errors.first("gender") }}</span
          >
        </div>
      </div>
      <label>Permanent Address </label>
      <div class="row mx-0">
        <div class="col-12 col-sm-6 pr-sm-2 mb-3">
          <label class="required">Address Line 1 </label>
          <input
            type="text"
            name="address_line_1"
            key="address_line_1"
            placeholder="Address Line 1"
            v-validate="'required|max:500'"
            :disabled="!isEditMode"
            :class="{ invalid: errors.first('address_line_1') }"
            class="form-control"
            v-model="personalData.address_line_1"
          />
          <span
            v-if="errors.first('address_line_1')"
            class="valid-feedback alert-danger"
            >{{ errors.first("address_line_1") }}</span
          >
        </div>
        <div class="col-sm-6 col-12 pr-sm-2 mb-3">
          <label>Address Line 2</label>
          <input
            type="text"
            name="address_line_2"
            key="address_line_2"
            placeholder="Address Line 2"
            v-validate="'max:500'"
            :disabled="!isEditMode"
            :class="{ invalid: errors.first('address_line_2') }"
            class="form-control"
            v-model="personalData.address_line_2"
          />
          <span
            v-if="errors.first('address_line_2')"
            class="valid-feedback alert-danger"
            >{{ errors.first("address_line_2") }}</span
          >
        </div>
        <div class="col-sm-6 col-12 pr-sm-2 mb-3">
          <label class="required">City </label>
          <input
            type="text"
            name="city"
            key="city"
            placeholder="City"
            v-validate="'required|max:50'"
            :disabled="!isEditMode"
            :class="{ invalid: errors.first('city') }"
            class="form-control"
            v-model="personalData.city"
          />
          <span
            v-if="errors.first('city')"
            class="valid-feedback alert-danger"
            >{{ errors.first("city") }}</span
          >
        </div>
        <div class="col-sm-6 col-12 pr-sm-2 mb-3">
          <label class="required">State </label>
          <input
            type="text"
            v-if="!isEditMode"
            disabled
            placeholder="State"
            :class="{ invalid: errors.first('state') }"
            class="form-control"
            :value="personalData.state"
          />
          <div v-else :class="{ 'invalid-border': errors.first('state') }">
            <select
              id="statePicker"
              name="state"
              key="state"
              class="form-control selectPicker"
              v-validate="'required'"
              v-model="personalData.state"
              data-live-search="true"
              title="Select state"
            >
              <option v-for="(state, index) in states" :key="index">
                {{ state }}
              </option>
            </select>
            <span
              v-if="errors.first('state')"
              class="valid-feedback alert-danger"
              >{{ errors.first("state") }}</span
            >
          </div>
        </div>
      </div>
      <label
        >Current Address
        <label
          style="margin-left: 30px"
          class="form-check-label"
          v-if="isEditMode"
        >
          <input
            style="margin-top: 6px"
            type="checkbox"
            class="form-check-input"
            v-model="asAbove"
          />As Above</label
        ></label
      >
      <div class="row mx-0">
        <div class="col-sm-6 col-12 pr-sm-2 mb-3">
          <label class="required">Address Line 1 </label>
          <input
            type="text"
            name="current_address_line_1"
            key="current_address_line_1"
            placeholder="Address Line 1"
            v-validate="'required|max:500'"
            :disabled="!isEditMode || asAbove"
            :class="{
              invalid: errors.first('current_address_line_1'),
            }"
            class="form-control"
            v-model="personalData.current_address_line_1"
          />
          <span
            v-if="errors.first('current_address_line_1')"
            class="valid-feedback alert-danger"
            >{{ errors.first("current_address_line_1") }}</span
          >
        </div>
        <div class="col-sm-6 col-12 pr-sm-2 mb-3">
          <label>Address Line 2 </label>
          <input
            type="text"
            name="current_address_line_2"
            key="current_address_line_2"
            placeholder="Address Line 2"
            v-validate="'max:500'"
            :disabled="!isEditMode || asAbove"
            :class="{
              invalid: errors.first('current_address_line_2'),
            }"
            class="form-control"
            v-model="personalData.current_address_line_2"
          />
          <span
            v-if="errors.first('current_address_line_2')"
            class="valid-feedback alert-danger"
            >{{ errors.first("current_address_line_2") }}</span
          >
        </div>
        <div class="col-sm-6 col-12 pr-sm-2 mb-3">
          <label class="required">City </label>
          <input
            type="text"
            name="current_city"
            key="current_city"
            placeholder="City"
            v-validate="'required|max:50'"
            :disabled="!isEditMode || asAbove"
            :class="{ invalid: errors.first('current_city') }"
            class="form-control"
            v-model="personalData.current_city"
          />
          <span
            v-if="errors.first('current_city')"
            class="valid-feedback alert-danger"
            >{{ errors.first("current_city") }}</span
          >
        </div>
        <div class="col-sm-6 col-12 pr-sm-2 mb-3">
          <label class="required">State </label>
          <input
            type="text"
            v-if="!isEditMode"
            disabled
            placeholder="State"
            :class="{ invalid: errors.first('current_state') }"
            class="form-control"
            :value="personalData.current_state"
          />
          <div
            v-else
            :class="{
              'invalid-border': errors.first('current_state'),
            }"
          >
            <select
              id="currentStatePicker"
              name="current_state"
              key="current_state"
              class="form-control selectPicker"
              v-validate="'required'"
              :disabled="!isEditMode || asAbove"
              v-model="personalData.current_state"
              data-live-search="true"
              title="Select state"
            >
              <option v-for="(state, index) in states" :key="index">
                {{ state }}
              </option>
            </select>
            <span
              v-if="errors.first('current_state')"
              class="valid-feedback alert-danger"
              >{{ errors.first("current_state") }}</span
            >
          </div>
        </div>
      </div>
      <div class="col text-center">
        <div class="col" v-if="isEditMode">
          <b-button @click="cancelEdit" class="btn btn-dark"> Cancel </b-button>
          <b-button @click="handleSubmit" class="btn btn-cp ml-2">
            Submit
          </b-button>
        </div>
        <div class="col" v-else>
          <button
            type="button"
            class="btn btn-cp ml-2"
            @click="isEditMode = true"
          >
            Edit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileUploadInput from "../../../Common/_components/file-upload-input";
import { states } from "../../../Common/commonData";
import { mapGetters } from "vuex";
import { Validator } from "vee-validate";
import {
  isSameAlternateNo,
  beforeDate,
} from "../../../Common/_plugin/validation";
import userProfile from "../../../../assets/images/avatar.png";

Validator.extend("sameAlternateNo", isSameAlternateNo);
Validator.extend("beforeDate", beforeDate);

export default {
  data() {
    return {
      isEditMode: false,
      avatar: userProfile,
      initialPersonalData: {},
      personalData: {
        profile_pic: null,
        first_name: null,
        last_name: null,
        email: null,
        secondary_email: null,
        dob: null,
        gender: "Male",
        position: null,
        mobile_no: null,
        alternate_mobile_no: null,
        address_line_1: null,
        address_line_2: null,
        current_address_line_1: null,
        current_address_line_2: null,
        current_state: null,
        city: null,
        state: null,
        current_city: null,
      },
      selectedFiles: [],
      profilePicId: "applicantProfilePic",
      asAbove: false,
      isCurrentStateSelected: false,
      isStateSelected: false,
      imagePreview: null,
      isProfilePicSelected: false,
    };
  },
  computed: {
    states() {
      return states;
    },
    ...mapGetters({
      applicantDataFromStore: "$_applicant_master/getLoggedInApplicant",
      profilePic: "$_applicant_master/getProfilePic",
    }),
    "$refs.profilePicInput.selectedFiles": {
      handler(newFiles) {
        this.isProfilePicSelected = newFiles && newFiles.length > 0;
      },
      immediate: true,
    },
  },
  components: {
    FileUploadInput,
  },
  watch: {
    personalData: {
      deep: true,
      handler(value) {
        if (this.asAbove) {
          if (value.address_line_1 !== value.current_address_line_1) {
            value.current_address_line_1 = value.address_line_1;
          }
          if (value.address_line_2 !== value.current_address_line_2) {
            value.current_address_line_2 = value.address_line_2;
          }
          if (value.city !== value.current_city) {
            value.current_city = value.city;
          }
          if (value.state !== value.current_state) {
            value.current_state = value.state;
            $("#currentStatePicker").selectpicker("val", value.current_state);
          }
        }
      },
    },
    applicantDataFromStore(val) {
      this.personalData = {
        profile_pic: val.profile_pic,
        first_name: val.first_name,
        last_name: val.last_name,
        email: val.email,
        secondary_email: val.secondary_email,
        dob: val.dob,
        gender: val.gender,
        mobile_no: val.mobile_no,
        alternate_mobile_no: val.alternate_mobile_no,
        address_line_1: val.address_line_1,
        address_line_2: val.address_line_2,
        city: val.city,
        state: val.state,
        current_address_line_1: val.current_address_line_1,
        current_address_line_2: val.current_address_line_2,
        current_city: val.current_city,
        current_state: val.current_state,
      };

      this.asAbove = this.checkAddress();

      this.initialPersonalData = JSON.parse(JSON.stringify(this.personalData));
    },

    "personalData.employment_status"() {
      this.$validator.reset();
    },
    asAbove(value) {
      if (value) {
        this.personalData.current_address_line_1 =
          this.personalData.address_line_1;
        this.personalData.current_address_line_2 =
          this.personalData.address_line_2;
        this.personalData.current_city = this.personalData.city;
        this.personalData.current_state = this.personalData.state;
        $("#currentStatePicker").selectpicker("val", this.personalData.state);
      }
      $("#currentStatePicker").prop("disabled", value);
      $("#currentStatePicker").selectpicker("refresh");
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    $("#statePicker").selectpicker("render");
    $("#currentStatePicker").selectpicker("render");

    $("#statePicker").on("hidden.bs.select", () => {
      this.isStateSelected = true;
    });

    $("#currentStatePicker").on("hidden.bs.select", () => {
      this.isCurrentStateSelected = true;
    });

    $("#statePicker").selectpicker("val", this.personalData.state);
    $("#currentStatePicker").selectpicker(
      "val",
      this.personalData.current_state
    );
  },
  created() {
    this.$store.dispatch("$_applicant_master/fetchApplicantProfile");
    this.$store.dispatch("$_applicant_master/getApplicantProfilePic");
  },
  methods: {
    // to verify the address on load (specifically for edit option) to check the checkbox
    checkAddress() {
      return (
        this.personalData.current_address_line_1 ===
          this.personalData.address_line_1 &&
        this.personalData.current_address_line_2 ===
          this.personalData.address_line_2 &&
        this.personalData.current_city === this.personalData.city &&
        this.personalData.current_state === this.personalData.state &&
        this.personalData.current_address_line_1 != null
      );
    },
    cancelEdit() {
      this.isEditMode = false;
      this.asAbove = false;
      this.personalData = JSON.parse(JSON.stringify(this.initialPersonalData));
      this.$validator.reset();
    },
    handleSubmit() {
      this.$validator.validateAll().then(() => {
        if (this.$validator.errors.items.length == 0) {
          const changedData = {};
          for (const key in this.personalData) {
            if (this.personalData[key] !== this.initialPersonalData[key]) {
              changedData[key] = this.personalData[key];
            }
          }

          if (Object.keys(changedData).length === 0) {
            this.isEditMode = false;
            return;
          }

          delete changedData.profile_pic;

          this.isEditMode = false;
          this.$store.dispatch(
            "$_applicant_master/updateApplicantProfile",
            changedData
          );
        } else {
          this.$store.dispatch("openSnackbar", {
            type: "danger",
            message: "Please enter valid values!",
          });
        }
      });
    },

    handleProfilePicUpload() {
      const profilePicUploadBody = {};
      profilePicUploadBody.name =
        this.personalData.first_name + " " + this.personalData.last_name;
      const fileInput = this.$refs.profilePicInput;
      if (
        fileInput &&
        fileInput.selectedFiles &&
        fileInput.selectedFiles.length > 0
      ) {
        profilePicUploadBody.profile_pic = fileInput.selectedFiles[0];
      }
      this.$store.dispatch(
        "$_applicant_master/uploadApplicantProfilePic",
        profilePicUploadBody
      );
    },

    profilePicChanged() {
      this.isProfilePicSelected = true;
      const selectedFiles = this.$refs.profilePicInput.selectedFiles;
      if (selectedFiles && selectedFiles.length > 0) {
        this.isProfilePicSelected = true;
        const reader = new FileReader();
        const selectedFile = selectedFiles[0];
        const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

        const allowedExtensions = ["jpg", "jpeg", "png"];
        if (allowedExtensions.includes(fileExtension)) {
          reader.onload = (event) => {
            this.imagePreview = event.target.result;
          };
          reader.readAsDataURL(selectedFile);
        } else {
          this.isProfilePicSelected = false;
          this.imagePreview = null;
        }
      } else {
        this.isProfilePicSelected = false;
        this.imagePreview = null;
      }
    },
    deleteImage() {
      this.imagePreview = null;
      this.isProfilePicSelected = false;
      this.$store.dispatch("$_applicant_master/deleteApplicantProfilePic");
    },
  },
};
</script>
