var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loggedInUser)?_c('nav',{staticClass:"navbar navbar-expand-md navbar-light",attrs:{"aria-label":"loggedInUserNav"}},[_c('div',{staticClass:"logo"},[_c('router-link',{attrs:{"to":{ name: 'homepage' }}},[_c('img',{attrs:{"src":require("../../../assets/images/newLogo.png"),"alt":""}})])],1),_vm._m(0),_c('div',{staticClass:"header_btn d-flex align-items-center"},[(_vm.loggedInUser)?_c('div',{staticClass:"profile_icon",style:({
          backgroundImage: 'url(' + _vm.loggedInUser.profile_pic + ')',
        }),attrs:{"title":_vm.loggedInUser.name}}):_vm._e(),(_vm.loggedInUser)?_c('button',{staticClass:"btn btn-basic sign-out-btn p-1",attrs:{"title":"Log out"},on:{"click":function($event){return _vm.logout()}}},[_c('i',{staticClass:"fa fa-2x fa-sign-out",attrs:{"aria-hidden":"true"}})]):_vm._e()])]):(_vm.recruiter_token)?_c('nav',{staticClass:"navbar navbar-expand-md navbar-light",attrs:{"aria-label":"navbar toggler RecruiterNavbar"}},[_c('div',{staticClass:"logo"},[_c('router-link',{attrs:{"to":{ name: 'RecruiterApplicantData' }}},[_c('img',{attrs:{"src":require("../../../assets/images/newLogo.png"),"alt":""}})])],1),_vm._m(1),_c('div',{staticClass:"header_btn d-flex align-items-center",attrs:{"id":"header_btn"}},[_c('div',{staticClass:"profile_icon2",style:({
          backgroundImage: `url(${_vm.avatar})`,
        })}),_c('button',{staticClass:"sign-out-btn float-right btn btn-basic p-1",attrs:{"title":"Log out"},on:{"click":function($event){return _vm.recruiterLogout()}}},[_c('i',{staticClass:"fa fa-2x fa-sign-out",attrs:{"aria-hidden":"true"}})])])]):(_vm.applicant_token)?_c('nav',{staticClass:"navbar navbar-expand-md navbar-light",attrs:{"aria-label":"navbar toggler ApplicantNavbar"}},[_c('div',{staticClass:"logo"},[_c('router-link',{attrs:{"to":{ name: 'applicantApplications' }}},[_c('img',{attrs:{"src":require("../../../assets/images/newLogo.png"),"alt":""}})])],1),_vm._m(2),_c('div',{staticClass:"header_btn d-flex align-items-center",attrs:{"id":"header_btn"}},[_c('div',{staticClass:"profile_icon2",style:({
          backgroundImage: `url(${_vm.avatar})`,
        })}),_c('button',{staticClass:"sign-out-btn float-right btn btn-basic p-1",attrs:{"title":"Log out"},on:{"click":function($event){return _vm.applicantLogout()}}},[_c('i',{staticClass:"fa fa-2x fa-sign-out",attrs:{"aria-hidden":"true"}})])])]):_c('nav',{staticClass:"navbar navbar-expand-md navbar-light",attrs:{"aria-label":"otherNavbar"}},[_c('div',{staticClass:"dropdown d-flex"},[_vm._m(3),_c('div',{staticClass:"dropdown-menu dropdown-menu-adv",attrs:{"aria-labelledby":"dropdownMenuButton"}},[(_vm.fresherOpeningEnabled)?_c('router-link',{attrs:{"id":"routerLink1","to":{ name: 'applicationForm', query: { isFresher: 'true' } }}},[_c('button',{staticClass:"btn btn-cp ml-2 p-2 nav-item menu-item"},[_vm._v(" Freshers ")])]):_vm._e(),(_vm.professionalOpeningEnabled)?_c('router-link',{attrs:{"to":{ name: 'jobOpenings' },"id":"routerLink2"}},[_c('button',{staticClass:"btn btn-cp ml-2 p-2 nav-item menu-item"},[_vm._v(" Experienced Professionals ")])]):_vm._e(),_c('button',{staticClass:"btn btn-cp ml-2 p-2 nav-item",attrs:{"data-toggle":"modal","data-target":"#applicationStatusModal","data-backdrop":"static","data-keyboard":"false"}},[_vm._v(" Application Status ")])],1),_c('div',{staticClass:"logo"},[_c('router-link',{attrs:{"to":{ name: 'home' }}},[_c('img',{attrs:{"src":require("../../../assets/images/newLogo.png"),"alt":""}})])],1)]),_vm._m(4),_c('div',{staticClass:"collapse navbar-collapse bg-white",attrs:{"id":"navbarToggler"}},[_c('ul',{staticClass:"navbar-nav center-align mt-lg-0"},[_c('div',{staticClass:"d-flex dropdown hide-in-menu"},[_c('button',{staticClass:"nav-item btn dropdown-toggle btn-cp ml-2 p-2",attrs:{"type":"button","data-toggle":"dropdown","id":"dropdownMenuButton","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v(" Join Us ")]),_c('ul',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"dropdownMenuButton"}},[(_vm.fresherOpeningEnabled)?_c('li',{staticClass:"dropdown-item"},[_c('router-link',{attrs:{"id":"routerLink1","to":{
                  name: 'applicationForm',
                  query: { isFresher: 'true' },
                }}},[_vm._v(" Freshers ")])],1):_vm._e(),(_vm.professionalOpeningEnabled)?_c('li',{staticClass:"dropdown-item"},[_c('router-link',{attrs:{"to":{ name: 'jobOpenings' },"id":"routerLink2"}},[_vm._v(" Experienced Professionals ")])],1):_vm._e()]),_c('button',{staticClass:"btn btn-cp ml-2 p-2 nav-item",attrs:{"data-toggle":"modal","data-target":"#applicationStatusModal","data-backdrop":"static","data-keyboard":"false"}},[_vm._v(" Application Status ")])])])]),_c('button',{staticClass:"sign-in-btn btn btn-basic float-right p-1",attrs:{"title":"Employee Login"},on:{"click":function($event){return _vm.goToLogin()}}},[_c('i',{staticClass:"fa fa-2x fa-sign-in",attrs:{"aria-hidden":"true"}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gpw-logo"},[_c('img',{attrs:{"src":"https://www.argusoft.com/images/Certification%20Badge.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gpw-logo"},[_c('img',{attrs:{"src":"https://www.argusoft.com/images/Certification%20Badge.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gpw-logo"},[_c('img',{attrs:{"src":"https://www.argusoft.com/images/Certification%20Badge.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn btn-basic d-block d-md-none mr-2",attrs:{"type":"button","id":"dropdownMenuButton","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('img',{attrs:{"alt":"argusoft-menu","src":require("../../../assets/images/argus-menu.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gpw-logo"},[_c('img',{attrs:{"src":"https://www.argusoft.com/images/Certification%20Badge.png","alt":""}})])
}]

export { render, staticRenderFns }