export const SCHEDULE_APPLICANTS_DATA_COL_DEFS = [
  {
    headerName: "Personal Info",
    children: [
      {
        cellRenderer: "profile-link",
        width: 35,
        minWidth: 35,
        maxWidth: 35,
        suppressFilter: true,
        filter: false,
        valueGetter: "node.id",
        headerTooltip: "Profile Info",
        tooltipValueGetter: function (params) {
          if (params.data != undefined) {
            return "Profile of " + params.data.first_name;
          }
          return "";
        },
        pinned: "left",
        display_only: true,
        cellStyle: { textAlign: "center", padding: 0 },
      },
      //   {
      //     cellRenderer: 'resolve-link',
      //     width: 45,
      //     minWidth: 45,
      //     maxWidth: 45,
      //     suppressFilter: true,
      //     filter: false,
      //     headerTooltip: 'Profile Info',
      //     valueGetter: 'node.id',
      //     pinned: 'left',
      //     display_only: true
      //   },
      {
        headerName: "Name",
        field: "first_name",
        valueGetter: function mergeLastNameFirstName(params) {
          if (params.data != undefined) {
            return params.data.first_name + " " + params.data.last_name;
          }
        },
        sortable: true,
        width: 200,
        minWidth: 200,
        checkboxSelection: true,
        pinned: "left",
        suppressHeaderMenuButton: true,
        filterParams: {
          newRowsAction: "keep",
        },
      },
      {
        headerName: "Email",
        field: "email",
        sortable: true,
        width: 200,
        minWidth: 200,
        suppressHeaderMenuButton: true,
        pinned: "left",
      },
      {
        headerName: "Mobile",
        field: "mobile_no",
        sortable: true,
        width: 100,
        minWidth: 100,
        suppressHeaderMenuButton: true,
        pinned: "left",
      },
    ],
  },
  {
    headerName: "Applicant Status",
    children: [
      {
        headerName: "Application Status",
        field: "application_status",
        sortable: true,
        filter: true,
        width: 200,
        minWidth: 200,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: "Status",
        field: "status",
        sortable: true,
        filter: true,
        width: 120,
        minWidth: 120,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: "Application Date",
        field: "createdAt",
        width: 150,
        minWidth: 150,
        sortable: true,
        valueGetter: function dateFormat(params) {
          if (params.data != undefined) {
            let dt = new Date(params.data.createdAt);
            return dt.toLocaleDateString().toString();
          }
        },
        filter: "agDateColumnFilter",
        suppressHeaderMenuButton: true,
      },
    ],
  },
  {
    headerName: "College Info",
    align: "center",
    children: [
      {
        headerName: "College Name",
        width: 150,
        minWidth: 150,
        field: "college_name",
        sortable: true,
      },
      {
        headerName: "Degree",
        width: 100,
        minWidth: 100,
        valueGetter: function getValue(params) {
          if (params.data && params.data.education_details) {
            return params.data.education_details.degree;
          }
          return "NA";
        },
      },
      {
        headerName: "Stream",
        width: 100,
        minWidth: 100,
        valueGetter: function getValue(params) {
          if (params.data && params.data.education_details) {
            return params.data.education_details.stream;
          }
          return "NA";
        },
      },
    ],
  },
  {
    headerName: "Grade Info",
    align: "center",
    children: [
      {
        headerName: "Agg. Pr.",
        width: 100,
        minWidth: 100,
        valueGetter: function getValue(params) {
          if (params.data && params.data.education_details) {
            return params.data.education_details.aggregate_percentage;
          }
          return "NA";
        },
      },
      {
        headerName: "HSC Pr.",
        width: 100,
        minWidth: 100,
        valueGetter: function getValue(params) {
          if (params.data && params.data.education_details) {
            return params.data.education_details.hsc_percentage;
          }
          return "NA";
        },
      },
      {
        headerName: "SSC Pr.",
        width: 100,
        minWidth: 100,
        valueGetter: function getValue(params) {
          if (params.data && params.data.education_details) {
            return params.data.education_details.ssc_percentage;
          }
          return "NA";
        },
      },
      {
        headerName: "Grad. Pr.",
        width: 100,
        minWidth: 100,
        valueGetter: function getValue(params) {
          if (params.data && params.data.education_details) {
            return params.data.education_details.grad_percentage;
          }
          return "NA";
        },
      },
    ],
  },
];
