<template>
  <div id="tracker">
    <div class="row mx-0">
      <div class="w-100 d-flex justify-content-between">
        <div class="filter-box form-inline">
          <select
            class="form-control my-1"
            v-model="filterOptions.employmentStatus"
            @change="onChangeEmploymentStatus"
          >
            <option selected value="P">Professionals</option>
            <option selected value="G">Freshers</option>
          </select>
        </div>
        <div class="summary-box form-inline">
          <div
            :key="index"
            v-for="(monthWidget, index) in monthWidgets"
            class="summary-box-item"
            :style="generateRandomBackgroundColor()"
          >
            <div class="summary-box-title">{{ monthWidget.month }}</div>
            <div class="summary-box-body">
              {{ monthWidget.count | formatNumber("00") }}
            </div>
          </div>
        </div>
      </div>
      <div style="width: 100%">
        <ag-grid-vue
          class="ag-theme-alpine ag-grid-style ag-grid-footer-style"
          :enableBrowserTooltips="true"
          :gridOptions="gridOptions"
          :rowData="rowData"
        >
        </ag-grid-vue>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { AgGridVue } from "ag-grid-vue";
import config from "../../../config";
import moment from "moment";
import { getFilterColumns } from "../../Common/_plugin/validation";

export default {
  data() {
    return {
      config,
      gridOptions: null,
      columnDefs: null,
      filterOptions: {
        employmentStatus: "P",
      },
    };
  },
  components: {
    "ag-grid-vue": AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    "profile-link": {
      template: `<router-link v-if="params.data" target='_blank' :to="{name:'applicantprofile', params:{slug:params.data.slug}}"><i class="fa fa-user-circle-o" aria-hidden="true" style="color:#832e5b"></i></router-link>`,
    },
  },
  methods: {
    generateRandomBackgroundColor() {
      const minBrightness = 100;
      const r = Math.floor(
        minBrightness + Math.random() * (255 - minBrightness)
      );
      const g = Math.floor(
        minBrightness + Math.random() * (255 - minBrightness)
      );
      const b = Math.floor(
        minBrightness + Math.random() * (255 - minBrightness)
      );
      return `background-color: rgba(${r},${g},${b},.6)`;

      //random color will be freshly served
    },
    createColumnDefs() {
      this.columnDefs = [
        {
          cellRenderer: "profile-link",
          width: 35,
          minWidth: 35,
          maxWidth: 35,
          suppressFilter: true,
          filter: false,
          valueGetter: "node.id",
          headerTooltip: "Profile Info",
          tooltipValueGetter: function (params) {
            if (params.data != undefined) {
              return "Profile of " + params.data.first_name;
            }
            return "";
          },
          pinned: "left",
          display_only: true,
        },
        {
          headerName: "Name",
          field: "first_name",
          valueGetter: function mergeLastNameFirstName(params) {
            if (params.data != undefined) {
              return params.data.first_name + " " + params.data.last_name;
            }
          },
          sortable: true,
          width: 200,
          minWidth: 200,
          pinned: "left",
          suppressHeaderMenuButton: true,
          filterParams: {
            newRowsAction: "keep",
          },
        },
        {
          headerName: "Expected Joining",
          field: "expected_joining",
          sortable: true,
          width: 150,
          minWidth: 150,
          pinned: "left",
          suppressHeaderMenuButton: true,
          cellRenderer: (params) => {
            if (params.data.expected_joining)
              return moment(params.data.expected_joining).format("DD/MM/YYYY");
            else return "NA";
          },
          filter: "agDateColumnFilter",
        },
        {
          headerName: "Position",
          field: "position",
          sortable: true,
          width: 140,
          minWidth: 140,
          suppressHeaderMenuButton: true,
          pinned: "left",
        },
        {
          headerName: "Designation",
          field: "designation_offered",
          sortable: true,
          width: 140,
          minWidth: 140,
          suppressHeaderMenuButton: true,
        },
        {
          headerName: "Email",
          field: "email",
          sortable: true,
          width: 200,
          minWidth: 200,
          suppressHeaderMenuButton: true,
        },
        {
          headerName: "Mobile",
          field: "mobile_no",
          sortable: true,
          width: 100,
          minWidth: 100,
          suppressHeaderMenuButton: true,
        },
      ];
    },
    onChangeEmploymentStatus() {
      this.$store.dispatch(
        "$_applicants_data/fetchShortlistedApplicants",
        this.filterOptions
      );
    },
  },
  beforeMount() {
    this.createColumnDefs();
    this.gridOptions = {
      columnDefs: getFilterColumns(this.columnDefs, 1),
      suppressPropertyNamesCheck: true,
      enableServerSideFilter: true,
      enableCellTextSelection: true,
      enableColResize: true,
      defaultColDef: {
        floatingFilter: true,
        resizable: true,
        filterParams: {
          maxNumConditions: 1,
          buttons: ["reset"],
        },
        // set filtering on for all columns
        filter: true,
      },
      pagination: true,
      paginationPageSize: 10,
      paginationPageSizeSelector: false,
      context: {
        componentParent: this,
      },
    };
  },
  computed: {
    ...mapGetters({
      rowData: "$_applicants_data/fetchShortlistedApplicantsData",
    }),
    monthWidgets() {
      let currMonthName = moment().format("MMM");
      let nextMonthName = moment().add(1, "month").format("MMM");
      let next2MonthName = moment().add(2, "month").format("MMM");
      let currMonthNameCount = 0;
      let nextMonthNameCount = 0;
      let next2MonthNameCount = 0;
      this.rowData.forEach((record) => {
        let monthName = moment(record.expected_joining).format("MMM");
        switch (monthName) {
          case currMonthName:
            currMonthNameCount++;
            break;
          case nextMonthName:
            nextMonthNameCount++;
            break;
          case next2MonthName:
            next2MonthNameCount++;
            break;
        }
      });
      return [
        {
          month: currMonthName,
          count: currMonthNameCount,
        },
        {
          month: nextMonthName,
          count: nextMonthNameCount,
        },
        {
          month: next2MonthName,
          count: next2MonthNameCount,
        },
      ];
    },
  },
  mounted() {
    this.$store.dispatch(
      "$_applicants_data/fetchShortlistedApplicants",
      this.filterOptions
    );
  },
};
</script>
<style scoped>
.summary-box {
  display: flex;
  flex-flow: row wrap;
  flex-basis: 10in;
  justify-content: right;
}

@media screen and (max-width: 991px) {
  .summary-box {
    margin-bottom: 10px;
  }
}

.summary-box > * {
  flex: 0 0 10%;
}

.summary-box-item {
  color: black;
  display: inline-block;
  justify-content: center;
  text-align: center;
}

.summary-box-title {
  background-color: #e9cfcf99;
  font-size: 14px;
  /* font-weight: bold; */
  line-height: 1.1;
  text-transform: uppercase;
  padding: 1px;
}

.summary-box-body {
  vertical-align: middle;
  font-size: 18px;
  /* font-weight: bold; */
  height: 20px;
  line-height: 20px;
  justify-content: center;
}
</style>
<style scoped>
.ag-grid-style {
  min-height: 500px;
  height: calc(100vh - 235px);
}
</style>
