<template>
  <div class="mt-2">
    <div v-if="applicantRoundsData">
      <div class="well section-head mb-1 w-auto">
        <div class="row mx-0">
          <div class="col-sm-6 col-12 px-0">
            <h4>
              <strong>Applicant's Rounds</strong>
            </h4>
          </div>
          <div class="col-sm-6 col-12 px-0" v-if="applicantRoundsData">
            <button
              v-if="hasAccess({ permissions: [BASE_PERMISSION.ROUND.CREATE] })"
              class="btn btn-cp float-right"
              role="button"
              data-toggle="modal"
              data-target="#addRound"
              data-backdrop="static"
              data-keyboard="false"
              :disabled="
                !(
                  applicantRoundsData?.length === 0 ||
                  (applicantRoundsData[applicantRoundsData.length - 1]
                    .round_result !== 'Pending' &&
                    applicantRoundsData.length > 0)
                ) || applicantDataForConflict?.hasDuplicate?.length > 0
              "
            >
              <i class="fa fa-plus fa-lg" aria-hidden="true"></i>
              <strong>Generate Round</strong>
            </button>
          </div>
        </div>
      </div>
      <div class="p-0" id="rounds">
        <div class="row mx-0">
          <div
            v-for="(round, index) in applicantRoundsData"
            :key="index"
            class="col-md-6 col-12"
          >
            <div
              :class="[
                round.round_result !== 'Cancelled' ? 'well' : 'cancelled',
                'container',
              ]"
            >
              <h4>
                <strong>{{ round.round_name }}</strong
                >&nbsp;
                <template
                  v-if="
                    round.round_result === 'Passed' ||
                    round.round_result === 'Processed'
                  "
                >
                  <em class="fa fa-thumbs-up fa-lg"></em>
                </template>
                <template
                  v-if="
                    round.round_result === 'Failed' ||
                    round.round_result === 'Differed'
                  "
                >
                  <em class="fa fa-thumbs-down fa-lg"></em>
                </template>
                <template v-if="round.round_result === 'Cancelled'">
                  <em class="fa fa-ban"></em>
                </template>
                <template v-if="round.round_result === 'Pending'">
                  <em class="fa fa-hourglass-half fa-md"></em>
                </template>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <div style="float: right; padding-bottom: 1.5%">
                  <button
                    v-if="
                      hasAccess({
                        permissions: [
                          BASE_PERMISSION.ROUND.UPDATE,
                          PERMISSIONS.MAIL.CREATE,
                        ],
                      })
                    "
                    class="btn btn-cp mr-1"
                    :disabled="
                      round.isMailed ||
                      index !== applicantRoundsData.length - 1 ||
                      (index == applicantRoundsData.length - 1 &&
                        round.round_result !== 'Pending' &&
                        index == applicantRoundsData.length - 1 &&
                        round.round_result !== 'Cancelled')
                    "
                    @click="sendMail(index)"
                  >
                    <em class="fa fa-envelope"></em>&nbsp;
                    <template v-if="!round.isMailed"> Send Mail </template>
                    <template v-if="round.isMailed"> Mail Sent </template>
                  </button>
                  <template
                    v-if="
                      round.round_result === 'Pending' &&
                      round.round_name != 'Review'
                    "
                  >
                    <div class="btn-group btn-group-xs">
                      <template
                        v-if="
                          round.eventSlot &&
                          round.eventSlot.interviewerId.includes(
                            parseInt(loggedInUser.user_id)
                          )
                        "
                      >
                        <button
                          v-if="
                            round.round_name == 'Aptitude' ||
                            round.round_name == 'Written Test'
                          "
                          class="btn btn-secondary"
                          @click="fetchMoodleScore(round.id)"
                        >
                          <em class="fa fa-refresh" aria-hidden="true"></em
                          >&nbsp;Sync
                        </button>
                      </template>
                      <button
                        v-if="
                          hasAccess({
                            permissions: [BASE_PERMISSION.ROUND.UPDATE],
                          }) ||
                          (round.interviewers &&
                            round.interviewers.some(
                              (interview) =>
                                interview.interviewer_id == loggedInUser.user_id
                            )) ||
                          ((!round.interviewers ||
                            !round.interviewers.length) &&
                            round.eventSlot &&
                            round.eventSlot.interviewerId.includes(
                              parseInt(loggedInUser.user_id)
                            ))
                        "
                        class="btn btn-success"
                        @click="
                          interviewResult(
                            index,
                            round.round_name,
                            round.interviewers,
                            'Passed',
                            round.id
                          )
                        "
                      >
                        <em class="fa fa-check"></em> Qualify
                      </button>
                      <button
                        v-if="
                          hasAccess({
                            permissions: [BASE_PERMISSION.ROUND.UPDATE],
                          }) ||
                          (round.interviewers &&
                            round.interviewers.some(
                              (interview) =>
                                interview.interviewer_id == loggedInUser.user_id
                            )) ||
                          ((!round.interviewers ||
                            !round.interviewers.length) &&
                            round.eventSlot &&
                            round.eventSlot.interviewerId.includes(
                              parseInt(loggedInUser.user_id)
                            ))
                        "
                        class="btn btn-danger"
                        @click="
                          interviewResult(
                            index,
                            round.round_name,
                            round.interviewers,
                            'Failed',
                            round.id
                          )
                        "
                      >
                        <em class="fa fa-times"></em> Differ
                      </button>
                    </div>
                    &nbsp;
                    <template
                      v-if="
                        hasAccess({
                          permissions: [BASE_PERMISSION.ROUND.UPDATE],
                        })
                      "
                    >
                      <a
                        role="button"
                        data-toggle="modal"
                        data-target="#editRound"
                        data-backdrop="static"
                        data-keyboard="false"
                        @click="setModalData(round)"
                      >
                        <em class="fa fa-edit fa-lg" aria-hidden="true"></em>
                      </a>
                    </template>
                  </template>

                  <template
                    v-if="
                      round.round_result === 'Pending' &&
                      round.round_name == 'Review'
                    "
                  >
                    <div class="btn-group btn-group-xs">
                      <button
                        v-if="
                          hasAccess({
                            permissions: [BASE_PERMISSION.ROUND.UPDATE],
                          }) ||
                          round.reviewers.some(
                            (review) =>
                              review.reviewer_id == loggedInUser.user_id
                          )
                        "
                        class="btn btn-success"
                        @click="
                          reviewResult(
                            index,
                            round.round_name,
                            round.reviewers,
                            'Processed',
                            round.id
                          )
                        "
                      >
                        <em class="fa fa-check"></em> Processed
                      </button>
                      <button
                        v-if="
                          hasAccess({
                            permissions: [BASE_PERMISSION.ROUND.UPDATE],
                          }) ||
                          round.reviewers.some(
                            (review) =>
                              review.reviewer_id == loggedInUser.user_id
                          )
                        "
                        class="btn btn-danger"
                        @click="
                          reviewResult(
                            index,
                            round.round_name,
                            round.reviewers,
                            'Differed',
                            round.id
                          )
                        "
                      >
                        <em class="fa fa-times"></em> Differed
                      </button>
                    </div>
                    &nbsp;
                    <template
                      v-if="
                        hasAccess({
                          permissions: [BASE_PERMISSION.ROUND.UPDATE],
                        })
                      "
                    >
                      <a
                        role="button"
                        data-toggle="modal"
                        data-target="#editRound"
                        data-backdrop="static"
                        data-keyboard="false"
                        @click="setModalData(round)"
                      >
                        <em class="fa fa-edit fa-lg" aria-hidden="true"></em>
                      </a>
                    </template>
                  </template>
                </div>
                <template
                  v-if="
                    round.round_result !== 'Pending' &&
                    index == applicantRoundsData.length - 1 &&
                    hasAccess({ permissions: [BASE_PERMISSION.ROUND.UPDATE] })
                  "
                >
                  <div style="float: right; padding-bottom: 1.5%">
                    <button
                      type="button"
                      class="btn btn-cp"
                      @click="revertResult(index)"
                    >
                      <em class="fa fa-undo"></em> Revert
                    </button>
                    &nbsp;
                  </div>
                </template>
              </h4>
              <div class="table-responsive">
                <table
                  aria-describedby="roundInfo"
                  class="table table-condensed"
                >
                  <tbody>
                    <tr>
                      <th id="Scheduled On" class="col-6 col-md-4">
                        <strong>Scheduled On</strong>
                      </th>
                      <td>{{ round.round_schedule | dateTimeFormat }}</td>
                    </tr>
                    <tr
                      v-if="
                        round.hasMoodle && round.hasMoodle.moodle_user_master
                      "
                    >
                      <td class="col-6 col-md-4">
                        <strong>Moodle Username</strong>
                      </td>
                      <td>
                        {{ round.hasMoodle.moodle_user_master.moodle_username }}
                      </td>
                    </tr>
                    <tr
                      v-if="
                        round.hasMoodle && round.hasMoodle.moodle_user_master
                      "
                    >
                      <td class="col-6 col-md-4">
                        <strong>Moodle Password</strong>
                      </td>
                      <td>
                        {{ round.hasMoodle.moodle_user_master.moodle_password }}
                      </td>
                    </tr>
                    <tr>
                      <td class="col-6 col-md-4">
                        <strong>Created By</strong>
                      </td>
                      <td>{{ round.created_by }}</td>
                    </tr>
                    <tr v-if="round.score">
                      <td class="col-6 col-md-4">
                        <strong>Score</strong>
                      </td>
                      <td>{{ round.score }}/{{ round.outOf }}</td>
                    </tr>
                    <tr v-if="round.grade">
                      <td class="col-6 col-md-4">
                        <strong>Grade</strong>
                      </td>
                      <td>{{ round.grade }}</td>
                    </tr>
                    <tr v-if="round.comments">
                      <td class="col-6 col-md-4">
                        <strong>Comments</strong>
                      </td>
                      <safe-content
                        :content="round.comments"
                        tag="td"
                      ></safe-content>
                    </tr>
                    <tr>
                      <td class="col-6 col-md-4">
                        <strong>Created On</strong>
                      </td>
                      <td>{{ round.createdAt | dateTimeFormat }}</td>
                    </tr>
                    <tr>
                      <td class="col-6 col-md-4">
                        <strong>Last Updated By</strong>
                      </td>
                      <td>{{ round.updated_by }}</td>
                    </tr>
                    <tr>
                      <td class="col-6 col-md-4">
                        <strong>Last Updated On</strong>
                      </td>
                      <td>{{ round.updatedAt | dateTimeFormat }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <template
                v-if="round.interviewers && round.interviewers.length !== 0"
              >
                <h5>
                  <strong>Reviews</strong>
                </h5>
                <div class="table-responsive">
                  <table
                    aria-describedby="interviewerReview"
                    class="table table-condensed"
                  >
                    <thead>
                      <th id="Interviewer">Interviewer</th>
                      <th id="Review">Review</th>
                    </thead>
                    <tbody
                      v-for="(interviewer, index) in round.interviewers"
                      :key="index"
                    >
                      <tr>
                        <td class="col-4">
                          {{ interviewer.interviewer_name }}
                          <br />
                          <button
                            :disabled="round.round_result !== 'Pending'"
                            class="btn btn-default btn-sm"
                            style="margin-top: 6px"
                            v-if="
                              loggedInUser.user_id ==
                                interviewer.interviewer_id ||
                              hasAccess({
                                permissions: [BASE_PERMISSION.ROUND.UPDATE],
                              })
                            "
                            @click="
                              addReview(
                                interviewer.id,
                                interviewer.interviewer_remarks,
                                round.id,
                                interviewer.interviewer_id,
                                round.round_name,
                                interviewer.formMasterId,
                                interviewer.formVersionId
                              )
                            "
                          >
                            <i class="fa fa-edit fa-lg" aria-hidden="true"></i
                            >Review
                          </button>
                        </td>
                        <td
                          class="col-8"
                          v-if="
                            interviewer.formMasterId &&
                            interviewer.interviewer_remarks
                          "
                        >
                          <table
                            class="w-100"
                            aria-describedby="interviewerRemarks"
                          >
                            <tbody>
                              <template
                                v-if="
                                  JSON.parse(interviewer.interviewer_remarks)[
                                    'model'
                                  ]
                                "
                              >
                                <tr
                                  v-for="(value, property) in JSON.parse(
                                    interviewer.interviewer_remarks
                                  )['label']"
                                  :key="property"
                                >
                                  <th class="border-0 p-1 m-0">
                                    {{ property }}
                                  </th>
                                  <td class="border-0 p-1 m-0">{{ value }}</td>
                                </tr>
                              </template>
                              <template v-else>
                                <tr
                                  v-for="(value, property) in JSON.parse(
                                    interviewer.interviewer_remarks
                                  )"
                                  :key="property"
                                >
                                  <th
                                    class="border-0 p-1 m-0"
                                    v-if="forms[interviewer.formVersionId]"
                                  >
                                    {{
                                      forms[interviewer.formVersionId]
                                        ? forms[interviewer.formVersionId][
                                            property
                                          ]
                                        : property
                                    }}
                                  </th>
                                  <td class="border-0 p-1 m-0">{{ value }}</td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </td>
                        <safe-content
                          v-else
                          className="col-md-9"
                          :content="interviewer.interviewer_remarks"
                          tag="td"
                        ></safe-content>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </template>
              <template v-if="round.reviewers && round.reviewers.length !== 0">
                <h5>
                  <strong>Reviews</strong>
                </h5>
                <div class="table-responsive">
                  <table
                    aria-describedby="reviewerReview"
                    class="table table-condensed"
                  >
                    <thead>
                      <th id="Reviewer">Reviewer</th>
                      <th id="Review">Review</th>
                    </thead>
                    <tbody
                      v-for="(reviewer, index) in round.reviewers"
                      :key="index"
                    >
                      <tr>
                        <td class="col-md-4">
                          {{ reviewer.reviewer_name }}
                          <br />
                          <button
                            :disabled="round.round_result !== 'Pending'"
                            class="btn btn-default btn-sm"
                            style="margin-top: 6px"
                            v-if="
                              loggedInUser.user_id == reviewer.reviewer_id ||
                              hasAccess({
                                permissions: [BASE_PERMISSION.ROUND.UPDATE],
                              })
                            "
                            @click="
                              addReviews(
                                reviewer.id,
                                reviewer.reviewer_remarks,
                                round.id,
                                reviewer.reviewer_id,
                                round.round_name,
                                reviewer.formMasterId,
                                reviewer.formVersionId
                              )
                            "
                          >
                            <i class="fa fa-edit fa-lg" aria-hidden="true"></i>
                            Review
                          </button>
                        </td>
                        <td
                          class="col-md-9"
                          v-if="
                            reviewer.formMasterId && reviewer.reviewer_remarks
                          "
                        >
                          <table
                            class="w-100"
                            aria-describedby="reviewerRemarks"
                          >
                            <tbody>
                              <template
                                v-if="
                                  JSON.parse(reviewer.reviewer_remarks)['model']
                                "
                              >
                                <tr
                                  v-for="(value, property) in JSON.parse(
                                    reviewer.reviewer_remarks
                                  )['label']"
                                  :key="property"
                                >
                                  <th class="border-0 p-1 m-0">
                                    {{ property }}
                                  </th>
                                  <td class="border-0 p-1 m-0">{{ value }}</td>
                                </tr>
                              </template>
                              <template v-else>
                                <tr
                                  v-for="(value, property) in JSON.parse(
                                    reviewer.reviewer_remarks
                                  )"
                                  :key="property"
                                >
                                  <th class="border-0 p-1 m-0">
                                    {{
                                      forms[reviewer.formVersionId]
                                        ? forms[reviewer.formVersionId][
                                            property
                                          ]
                                        : property
                                    }}
                                  </th>
                                  <td class="border-0 p-1 m-0">{{ value }}</td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </td>
                        <safe-content
                          v-else
                          className="col-md-9"
                          :content="reviewer.reviewer_remarks"
                          tag="td"
                        ></safe-content>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="row mx-3">
          <div class="well my-3" v-if="applicantRoundsData.length == 0">
            <p class="col-md-12 pt-3 text-center">No rounds created yet.</p>
          </div>
        </div>
      </div>
      <add-score-modal :roundData="roundData"></add-score-modal>
      <to-be-mailed
        modal-id="ToBeMailedRoundInfo"
        :flag="flagToBePassed"
        :applicantData="applicantDataForConflict"
        :roundData="rounDataToBeMailed"
      ></to-be-mailed>
      <add-interview-review-model :interviewData="interviewData">
      </add-interview-review-model>
      <form-render-modal :interviewData="interviewData"></form-render-modal>
      <common-modal
        :id="commonModalData.id"
        :modal-title="commonModalData.title"
        :modal-body="commonModalData.body"
        :show-cancel="commonModalData.showCancel"
        :modal-color="commonModalData.color"
        :modal-size-class="commonModalData.sizeClass"
        @actionPerformed="emitOnCurrent"
      />
    </div>
    <div v-else></div>
    <add-round-modal
      v-if="hasAccess({ permissions: [BASE_PERMISSION.ROUND.CREATE] })"
      :applicantData="applicantDataForConflict"
    ></add-round-modal>
    <edit-round-modal
      v-if="hasAccess({ permissions: [BASE_PERMISSION.ROUND.UPDATE] })"
      :applicantData="applicantDataForConflict"
    ></edit-round-modal>
  </div>
</template>
<script>
import AddRoundModal from "./add-round-modal.vue";
import EditRoundModal from "./edit-round-modal.vue";
import ToBeMailed from "./to-be-mailed.vue";
import AddScoreModal from "./add-score-modal.vue";
import AddInterviewReviewModel from "./add-interview-review-model.vue";
import FormRenderModal from "./form-render-modal.vue";
import CommonModal from "../../Common/_components/modal";
import { mapGetters } from "vuex";
import api from "../../FormTemplate/api";
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";
import { REVIEWERS_COUNT } from "../../../../config/configurations";
/**
 * @memberOf module:ApplicantProfile
 * @namespace components.round_info
 * @description Round info component
 */
export default {
  data() {
    return {
      modalData: null,
      disableButton: false,
      Pending: "Pending",
      Cancelled: "Cancelled",
      date: new Date(),
      roundData: null,
      rounDataToBeMailed: null,
      flagToBePassed: null,
      interviewData: null,
    };
  },
  components: {
    AddRoundModal,
    EditRoundModal,
    ToBeMailed,
    AddScoreModal,
    CommonModal,
    AddInterviewReviewModel,
    FormRenderModal,
  },
  methods: {
    fetchRounds(value) {
      this.$store.dispatch("$_applicant_profile/fetchRoundDetails", value);
    },
    getMoodleInstances() {
      if (
        !this.hasAccess({
          permissions: [this.PERMISSIONS["CAMPUS-DRIVE"]["MOODLE"].MENU],
        })
      )
        return;
      this.$store.dispatch("$_applicant_profile/getMoodleInstanceList");
    },
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    /**
     * @memberOf module:ApplicantProfile.components.round_info
     * @param {Object} roundData
     * @description fetch moodle score for a round
     */
    fetchMoodleScore(round_id) {
      this.$store.dispatch(
        "$_applicant_profile/fetchApplicantRoundScore",
        round_id
      );
    },

    /**
     * @memberOf module:ApplicantProfile.components.round_info
     * @param {Object} roundData
     * @description Set modal data values in store
     */
    setModalData(roundData) {
      this.$store.dispatch("$_applicant_profile/setModalRoundData", roundData);
    },

    /**
     * @memberOf module:ApplicantProfile.components.round_info
     * @param {number} index
     * @description Pass and fail  round of applicant
     */
    interviewResult(index, round_name, interviewerData, result, roundId) {
      const remark = this.isRoleReviewAvailable(interviewerData, "interviewer");
      if (!remark && round_name === "Interview") {
        this.$store.dispatch(
          "openSnackbar",
          {
            type: "success",
            message: `Atleast ${this.reviewCountDisplay(
              interviewerData
            )} review is required`,
          },
          { root: true }
        );
        this.autoFillReview(
          interviewerData,
          round_name,
          roundId,
          "interviewer"
        );
        return;
      }
      let tempData = JSON.stringify(this.applicantRoundsData[index]);
      tempData = JSON.parse(tempData);
      tempData.round_result = result;
      this.roundData = tempData;
      this.disableButton = false;
      $("#addScore").modal("show");
    },
    /**
     * @memberOf module:ApplicantProfile.components.round_info
     * @param {number} index
     * @description Tells about the review count and eligibility to pass and fail
     */
    isRoleReviewAvailable(dataArray, role) {
      let count = 0;
      let actualReviewCount = 0;
      const reviewProperty =
        role === "interviewer" ? "interviewer_remarks" : "reviewer_remarks";
      dataArray.forEach((data) => {
        if (data[reviewProperty]) {
          actualReviewCount++;
        }
        count++;
      });

      return (
        actualReviewCount >= this.requiredReviewCount ||
        actualReviewCount >= count
      );
    },

    /**
     * @memberOf module:ApplicantProfile.components.round_info
     * @param {number} index
     * @description displays the required review count in alert box
     */
    reviewCountDisplay(data) {
      const count = data.length;
      if (count < this.requiredReviewCount) return count;
      return this.requiredReviewCount;
    },
    /**
     * @memberOf module:ApplicantProfile.components.round_info
     * @param {number} index
     * @description Processed review of applicant
     */
    reviewResult(index, round_name, reviewerData, result, roundId) {
      const remark = this.isRoleReviewAvailable(reviewerData, "reviewer");
      if (!remark && round_name === "Review") {
        this.$store.dispatch(
          "openSnackbar",
          {
            type: "success",
            message: `Atleast ${this.reviewCountDisplay(
              reviewerData
            )} review is required`,
          },
          { root: true }
        );
        this.autoFillReview(reviewerData, round_name, roundId, "reviewer");
        return;
      }
      let tempData = JSON.stringify(this.applicantRoundsData[index]);
      tempData = JSON.parse(tempData);
      tempData.round_result = result;
      this.roundData = tempData;
      this.disableButton = false;
      $("#addScore").modal("show");
    },
    autoFillReview(dataArray, round_name, roundId, role) {
      const roleId = role === "interviewer" ? "interviewer_id" : "reviewer_id";
      const roleRemarks =
        role === "interviewer" ? "interviewer_remarks" : "reviewer_remarks";
      const self = dataArray.find(
        (element) => element[roleId] == this.loggedInUser.user_id
      );
      if (self && !self[roleRemarks]) {
        this.$store.dispatch(
          "openSnackbar",
          { type: "success", message: ` You are Filling your own review` },
          { root: true }
        );
        this.addReview(
          self.id,
          self[roleRemarks],
          roundId,
          self[roleId],
          round_name,
          self.formMasterId,
          self.formVersionId
        );
      } else {
        if (!hasAccess({ permissions: [this.BASE_PERMISSION.ROUND.UPDATE] })) {
          return;
        }
        dataArray.forEach((data) => {
          let remarks =
            role === "interviewer"
              ? data.interviewer_remarks
              : data.reviewer_remarks;
          let name =
            role === "interviewer" ? data.interviewer_name : data.reviewer_name;
          if (!remarks) {
            this.$store.dispatch(
              "openSnackbar",
              { type: "success", message: ` You are Filling ${name} review` },
              { root: true }
            );
            this.addReview(
              data.id,
              remarks,
              roundId,
              role === "interviewer" ? data.interviewer_id : data.reviewer_id,
              round_name,
              data.formMasterId,
              data.formVersionId
            );
          }
        });
      }
    },

    /**
     * @memberOf module:ApplicantProfile.components.round_info
     * @param {number} index
     * @description Revert round of applicant
     */
    revertResult(index) {
      this.openDialog(
        "commonModal",
        "Confirmation",
        "Are you sure you want to revert the result of this round?",
        true,
        "warn",
        "modal-sm",
        true
      )
        .then(() => {
          let tempData = {
            id: this.applicantRoundsData[index].id,
            round_result: "Pending",
            round_name: this.applicantRoundsData[index].round_name,
            applicantIterationMasterId:
              this.applicantRoundsData[index].applicantIterationMasterId,
          };
          this.$store.dispatch("$_applicant_profile/revertRound", tempData);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    /**
     * @memberOf module:ApplicantProfile.components.round_info
     * @param {number} index
     * @description Send mail of current round status
     */
    sendMail(index) {
      this.openDialog(
        "commonModal",
        "Confirmation",
        "Are you sure you want to send the mail?",
        true,
        "warn",
        "modal-sm",
        true
      )
        .then(() => {
          if (this.applicantDataForConflict.belongsToCampusRecruitement) {
            this.rounDataToBeMailed = this.applicantRoundsData[index];
            this.flagToBePassed = "SendMail";
            $("#ToBeMailedRoundInfo").modal();
          } else {
            this.$store.dispatch("$_applicant_profile/sendRoundMail", {
              data: this.applicantRoundsData[index],
              redirect: this.$route.fullPath,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    /**
     * @memberOf module:ApplicantProfile.components.round_info
     * @param {number} id
     * @param {String} remarks
     * @param {number} round_id
     * @param {number} interviewer_id
     * @description Add review after round has been passed or failed
     */
    addReview(
      id,
      remarks,
      round_id,
      interviewer_id,
      round_name,
      formMasterId,
      formVersionId
    ) {
      this.interviewData = {
        id: id,
        interviewer_remarks: remarks,
        round_id: round_id,
        interviewer_id: interviewer_id,
        round_name: round_name,
        formMasterId: formMasterId,
        formVersionId: formVersionId,
      };
      if (formMasterId) {
        $("#formRender").modal("show");
      } else {
        $("#addInterviewReview").modal("show");
      }
    },

    /**
     * @memberOf module:ApplicantProfile.components.round_info
     * @param {number} id
     * @param {String} remarks
     * @param {number} round_id
     * @param {number} interviewer_id
     * @description Add review after review has been processed or rejected
     */
    addReviews(
      id,
      remarks,
      round_id,
      interviewer_id,
      round_name,
      formMasterId,
      formVersionId
    ) {
      this.interviewData = {
        id: id,
        interviewer_remarks: remarks,
        round_id: round_id,
        interviewer_id: interviewer_id,
        round_name: round_name,
        formMasterId,
        formVersionId,
      };
      if (formMasterId) {
        $("#formRender").modal("show");
      } else {
        $("#addInterviewReview").modal("show");
      }
    },

    // Convers form into key: label pair object
    extractKeyLabel(form) {
      const keyLabel = {};
      form.sections.forEach((section) => {
        section.body.forEach((question) => {
          keyLabel[question.key] = question.label;
        });
      });
      return keyLabel;
    },
  },
  watch: {
    id: {
      handler: function (value) {
        if (value) {
          this.fetchRounds(value);
        }
      },
      immediate: true,
    },
  },
  created() {
    this.getMoodleInstances();
  },
  updated() {
    const rounds = this.applicantRoundsData;
    if (!rounds) return;
    const formIdSet = new Set();
    for (let round of rounds) {
      const interviews = round.interviewers;
      const reviews = round.reviewers;
      if (interviews) {
        for (let interview of interviews) {
          if (interview.formVersionId) {
            formIdSet.add(interview.formVersionId);
          }
        }
      }
      if (reviews) {
        for (let review of reviews) {
          if (review.formVersionId) {
            formIdSet.add(review.formVersionId);
          }
        }
      }
    }

    formIdSet.forEach((formVersionId) => {
      if (this.forms[formVersionId]) return;
      api
        .fetchFormVersionById(formVersionId)
        .then((result) => {
          this.$store.commit("$_applicant_profile/ADD_FORM", {
            id: formVersionId,
            form: this.extractKeyLabel(result.data.form_json),
          });
        })
        .catch((err) => console.error(err));
    });
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.APPLICANT;
    },
    ...mapGetters({
      applicantRoundsData: "$_applicant_profile/fetchRoundDetails",
      applicantDataForConflict: "$_applicant_profile/fetchApplicantData",
      id: "$_applicant_profile/fetchID",
      forms: "$_applicant_profile/fetchForms",
      loggedInUser: "user/loggedInUser",
      systemConfigs: "system_config/systemConfigs",
    }),
    requiredReviewCount() {
      const reviewCountData = this.systemConfigs.find(
        (item) => item.key === REVIEWERS_COUNT.key
      );
      const requiredReviewCount = reviewCountData?.is_active
        ? parseInt(reviewCountData.value[0], 10)
        : parseInt(REVIEWERS_COUNT.default[0], 10);
      return requiredReviewCount;
    },
  },
};
</script>

<style scoped>
.cancelled {
  background-color: transparent;
  margin-top: 1%;
  box-shadow: 0 1px 2px 3px rgba(255, 0, 0, 0.3);
  transition: 0.3s;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 22px;
}

.fa-thumbs-up {
  color: #449d44;
}

.fa-thumbs-down {
  color: #d9534f;
}

.fa-hourglass-half {
  color: #f0ad4e;
}

.fa-plus {
  color: #ffffff;
}

.fa-ban {
  color: #d9534f;
}

.affix {
  top: 40px;
  width: 100%;
  z-index: 1;
}

.affix + #rounds {
  padding-top: 117px;
}

.gen-rnd-btn {
  margin-left: 30px;
  margin-top: 3px;
}

h4 {
  padding-top: 5px;
}

@media (max-width: 576px) {
  .well {
    border-radius: 0px;
  }
}
</style>
